import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

function DashboardVentas() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;


  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("2024-01-01");

  const [info, setInfo] = useState([]);
  const [graficasDepartamentosClientes, setGraficasDepartamentosClientes] = useState([]);
  const [graficasLineas, setGraficasLineas] = useState([]);
  const [graficasProveedores, setGraficasProveedores] = useState([]);
  const [graficasAreas, setGraficasAreas] = useState([]);
  const [graficasVendedores, setGraficasVendedores] = useState([]);
  const [graficasMes, setGraficasMes] = useState([]);
  const [graficasCodigo, setGraficasCodigo] = useState([]);
  const [graficasCodigoPiezas, setGraficasCodigoPiezas] = useState([]);

  const [valorVenta, setValorVenta] = useState(0);
  const [cantidadVenta, setCantidadVenta] = useState(0);

  const [departamentosClientes, setDepartamentosClientes] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [vendedores, setVendedores] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedDepartamentoCliente, setSelectedDepartamentoCliente] = useState("");
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [selectedArea, setSelectedArea] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}Ventas/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible

      setInfo(array)

      let unicosProv = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idProveedor === value.idProveedor)
      );
      setProveedores(unicosProv);

      let unicosClien = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idCliente === value.idCliente)
      );
      setClientes(unicosClien);

      let unicosVen = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idVendedor === value.idVendedor)
      );
      setVendedores(unicosVen);

      let unicosLinea = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idLinea === value.idLinea)
      );
      setLineas(unicosLinea);

      let unicosArea = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArea === value.idArea)
      );
      setAreas(unicosArea);

      let unicosDepto = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idDepartamentoCliente === value.idDepartamentoCliente)
      );
      setDepartamentosClientes(unicosDepto);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });

  
},[])

function BuscaInfo(){
  setInfo([])
  setSelectedArea("")
  setSelectedCliente("")
  setSelectedDepartamentoCliente("")
  setSelectedLinea("")
  setSelectedProveedor("")
  setSelectedVendedor("")
  setCargando(true)
  axios
  .get(`${URL_DASHBOARDS}Ventas/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;
    let array = allDisponible

      setInfo(array)

      let unicosProv = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idProveedor === value.idProveedor)
      );
      setProveedores(unicosProv);

      let unicosClien = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idCliente === value.idCliente)
      );
      setClientes(unicosClien);

      let unicosVen = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idVendedor === value.idVendedor)
      );
      setVendedores(unicosVen);

      let unicosLinea = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idLinea === value.idLinea)
      );
      setLineas(unicosLinea);

      let unicosArea = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idArea === value.idArea)
      );
      setAreas(unicosArea);

      let unicosDepto = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idDepartamentoCliente === value.idDepartamentoCliente)
      );
      setDepartamentosClientes(unicosDepto);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });
}

  useMemo(() => {
      let temp = info
          if (selectedCliente) {
            temp = temp.filter((e) =>
              e.idCliente.includes(selectedCliente)
            );
          }
          if (selectedVendedor) {
            temp = temp.filter((e) =>
              e.idVendedor.includes(selectedVendedor)
            );
          }
          if (selectedDepartamentoCliente) {
            temp = temp.filter((e) =>
              e.idDepartamentoCliente.includes(selectedDepartamentoCliente)
            );
          }

          if (selectedLinea) {
            temp = temp.filter((e) =>
              e.idLinea.includes(selectedLinea)
            );
          }

          if (selectedArea) {
            temp = temp.filter((e) =>
              e.idArea.includes(selectedArea)
            );
          }

          if (selectedProveedor) {
            temp = temp.filter((e) =>
              e.idProveedor.includes(selectedProveedor)
            );
          }

        let agrupadoDepartamentoCliente = temp.reduce(function (groups, item) {
          const val = item["idDepartamentoCliente"];
          groups[val] = groups[val] || {
            idDepartamentoCliente: item.idDepartamentoCliente,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].DepartamentoCliente = item.DepartamentoCliente;
          groups[val].idDepartamentoCliente = item.idDepartamentoCliente;
          return groups;
        }, []);

        let otraDepartamentoCliente = Object.values(agrupadoDepartamentoCliente).sort((a, b) =>
          a.Importe < b.Importe ? 1 : -1
        );

        setGraficasDepartamentosClientes(otraDepartamentoCliente);

        let agrupadoLinea = temp.reduce(function (groups, item) {
          const val = item["idLinea"];
          groups[val] = groups[val] || {
            idLinea: item.idLinea,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Linea = item.Linea;
          groups[val].idLinea = item.idLinea;
          return groups;
        }, []);

        let otraLinea = Object.values(agrupadoLinea).sort((a, b) =>
          a.Importe < b.Importe ? 1 : -1
        );

        setGraficasLineas(otraLinea);


        let agrupadoArea = temp.reduce(function (groups, item) {
          const val = item["idArea"];
          groups[val] = groups[val] || {
            idArea: item.idArea,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Area = item.Area;
          groups[val].idArea = item.idArea;
          return groups;
        }, []);

        let otraArea = Object.values(agrupadoArea).sort((a, b) =>
          a.Importe < b.Importe ? 1 : -1
        );

        setGraficasAreas(otraArea);

        let agrupadoVendedor = temp.reduce(function (groups, item) {
          const val = item["idVendedor"];
          groups[val] = groups[val] || {
            idVendedor: item.idVendedor,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Vendedor = item.Vendedor;
          groups[val].idVendedor = item.idVendedor;
          return groups;
        }, []);

        let otraVendedor = Object.values(agrupadoVendedor).sort((a, b) =>
          a.Importe < b.Importe ? 1 : -1
        );

        setGraficasVendedores(otraVendedor);

        let agrupadoMes = temp.reduce(function (groups, item) {
          const val = item["Mes"];
          groups[val] = groups[val] || {
            Mes: item.Mes,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Mes = item.Mes;
          return groups;
        }, []);

        let otraMes = Object.values(agrupadoMes)
        // .sort((a, b) =>
        //   a.Importe < b.Importe ? 1 : -1
        // );

        setGraficasMes(otraMes);

        let agrupadoCodigo = temp.reduce(function (groups, item) {
          const val = item["Codigo"];
          groups[val] = groups[val] || {
            Codigo: item.Codigo,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Codigo = item.Codigo;
          return groups;
        }, []);

        let otraCodigo = Object.values(agrupadoCodigo)
        .sort((a, b) =>
          a.Importe < b.Importe ? 1 : -1
        )
        
        let otraCodigo2 = otraCodigo.slice(0, 10)

        setGraficasCodigo(otraCodigo2);


        let agrupadoCodigoPiezas = temp.reduce(function (groups, item) {
          const val = item["Codigo"];
          groups[val] = groups[val] || {
            Codigo: item.Codigo,
            Cantidad: 0,
          };
          groups[val].Cantidad += item.Cantidad;
          groups[val].Codigo = item.Codigo;
          return groups;
        }, []);

        let otraCodigoPiezas = Object.values(agrupadoCodigoPiezas)
        .sort((a, b) =>
          a.Cantidad < b.Cantidad ? 1 : -1
        )

        let otraCodigoPiezas2 = otraCodigoPiezas.slice(0, 10)

        setGraficasCodigoPiezas(otraCodigoPiezas2);


        let agrupadoProveedor = temp.reduce(function (groups, item) {
          const val = item["idProveedor"];
          groups[val] = groups[val] || {
            idProveedor: item.idProveedor,
            Importe: 0,
          };
          groups[val].Importe += item.Importe;
          groups[val].Proveedor = item.Proveedor;
          groups[val].idProveedor = item.idProveedor;
          return groups;
        }, []);

        let otraProveedor = Object.values(agrupadoProveedor).sort((a, b) =>
          a.Importe < b.Importe ? 1 : -1
        );

        setGraficasProveedores(otraProveedor);

        let TVal = temp.map((c) => parseFloat(c.Importe));
        let TV = TVal.reduce((t, total, index) => t + total, 0);
        setValorVenta(TV);

        let TCan = temp.map((c) => parseFloat(c.Cantidad));
        let TC = TCan.reduce((t, total, index) => t + total, 0);
        setCantidadVenta(TC);

  }, [info, selectedCliente, selectedDepartamentoCliente,  selectedLinea, selectedProveedor, selectedVendedor, selectedArea]);


  useLayoutEffect(() => {
    
    // Grafica DepartamentosClientes
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{DepartamentoCliente} ${value}",
        }),
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "Importe",
        categoryField: "DepartamentoCliente",
        endAngle: 270,
      })
    );

    series.states.create("hidden", {
      endAngle: -90,
    });

    chart.children.unshift(
      am5.Label.new(root, {
        text: "Departamentos",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    series.labels.template.set("forceHidden", true);
    series.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    series.data.setAll(graficasDepartamentosClientes);
    series.appear(1000, 100);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "DepartamentoCliente",
      })
    );

    legend.data.setAll(series.dataItems);

    // Grafica Lineas
    let rootLineas = am5.Root.new("chartdivLineas");

    rootLineas.setThemes([am5themes_Animated.new(rootLineas)]);

    let chartLineas = rootLineas.container.children.push(
      am5percent.PieChart.new(rootLineas, {
        endAngle: 270,
        layout: rootLineas.verticalLayout,
        tooltip: am5.Tooltip.new(rootLineas, {
          labelText: "{Linea} ${value}",
        }),
      })
    );

    let seriesLineas = chartLineas.series.push(
      am5percent.PieSeries.new(rootLineas, {
        valueField: "Importe",
        categoryField: "Linea",
        endAngle: 270,
      })
    );

    seriesLineas.states.create("hidden", {
      endAngle: -90,
    });

    chartLineas.children.unshift(
      am5.Label.new(rootLineas, {
        text: "Lineas",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesLineas.labels.template.set("forceHidden", true);
    seriesLineas.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesLineas.data.setAll(graficasLineas);
    seriesLineas.appear(1000, 100);

    let legendLineas = chartLineas.children.push(
      am5.Legend.new(rootLineas, {
        nameField: "Linea",
      })
    );

    legendLineas.data.setAll(seriesLineas.dataItems);

   
     // Grafica Proveedores
    let rootProveedores = am5.Root.new("chartdivProveedores");

    rootProveedores.setThemes([am5themes_Animated.new(rootProveedores)]);

    let chartProveedores = rootProveedores.container.children.push(
      am5percent.PieChart.new(rootProveedores, {
        endAngle: 270,
        layout: rootProveedores.verticalLayout,
        tooltip: am5.Tooltip.new(rootProveedores, {
          labelText: "{Proveedor} ${value}",
        }),
      })
    );

    let seriesProveedores = chartProveedores.series.push(
      am5percent.PieSeries.new(rootProveedores, {
        valueField: "Importe",
        categoryField: "Proveedor",
        endAngle: 270,
      })
    );

    seriesProveedores.states.create("hidden", {
      endAngle: -90,
    });

    chartProveedores.children.unshift(
      am5.Label.new(rootProveedores, {
        text: "Proveedores",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    seriesProveedores.labels.template.set("forceHidden", true);
    seriesProveedores.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    seriesProveedores.data.setAll(graficasProveedores);
    seriesProveedores.appear(1000, 100);

    let legendProveedores = chartProveedores.children.push(
      am5.Legend.new(rootProveedores, {
        nameField: "Proveedor",
      })
    );

    legendProveedores.data.setAll(seriesProveedores.dataItems);


    // Grafica Areas

    let rootAreas = am5.Root.new("chartdivAreas");

    rootAreas.setThemes([am5themes_Animated.new(rootAreas)]);

    let chartAreas = rootAreas.container.children.push(
      am5xy.XYChart.new(rootAreas, {
        panY: false,
        layout: rootAreas.verticalLayout,
      })
    );

    let dataAreas = graficasAreas;

    // Create Y-axis
    let yAxis = chartAreas.yAxes.push(
      am5xy.ValueAxis.new(rootAreas, {
        min:0,
        renderer: am5xy.AxisRendererY.new(rootAreas, {}),
      })
    );

    // Create X-Axis
    let xAxis = chartAreas.xAxes.push(
      am5xy.CategoryAxis.new(rootAreas, {
        renderer: am5xy.AxisRendererX.new(rootAreas, {}),
        categoryField: "Area",
      })
    );
    xAxis.data.setAll(dataAreas);

    // Create series
    let seriesAreas = chartAreas.series.push(
      am5xy.ColumnSeries.new(rootAreas, {
        name: "Area",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "Importe",
        categoryXField: "Area",
        tooltip: am5.Tooltip.new(rootAreas, {
          labelText: "{Area} ${valueY}",
        }),
      })
    );
    seriesAreas.data.setAll(dataAreas);

    // Add cursor
    chartAreas.set("cursor", am5xy.XYCursor.new(rootAreas, {}));

    let exporting = am5plugins_exporting.Exporting.new(rootAreas, {
      menu: am5plugins_exporting.ExportingMenu.new(rootAreas, {}),
    });

    chartAreas.children.unshift(
      am5.Label.new(rootAreas, {
        text: "Almacenes",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    // Venta Codigo

    let rootCodigo = am5.Root.new("chartdivCodigo");

    rootCodigo.setThemes([am5themes_Animated.new(rootCodigo)]);

    let chartCodigo = rootCodigo.container.children.push(
      am5xy.XYChart.new(rootCodigo, {
        panY: false,
        layout: rootCodigo.verticalLayout,
      })
    );

    let dataCodigo = graficasCodigo;

    // Create Y-axis
    let yAxisCodigo = chartCodigo.yAxes.push(
      am5xy.ValueAxis.new(rootCodigo, {
        min:0,
        renderer: am5xy.AxisRendererY.new(rootCodigo, {}),
      })
    );

    // Create X-Axis
    let xAxisCodigo = chartCodigo.xAxes.push(
      am5xy.CategoryAxis.new(rootCodigo, {
        renderer: am5xy.AxisRendererX.new(rootCodigo, {}),
        categoryField: "Codigo",
      })
    );
    xAxisCodigo.data.setAll(dataCodigo);

    // xAxisCodigo.get("renderer").labels.template.setAll({
    //   oversizedBehavior: "wrap",
    //   maxWidth: 50,
    //   rotation:-45
    // });

    // Create series
    let seriesCodigo = chartCodigo.series.push(
      am5xy.ColumnSeries.new(rootCodigo, {
        name: "Codigo",
        xAxis: xAxisCodigo,
        yAxis: yAxisCodigo,
        valueYField: "Importe",
        categoryXField: "Codigo",
        tooltip: am5.Tooltip.new(rootCodigo, {
          labelText: "{Codigo} ${valueY}",
        }),
      })
    );
    seriesCodigo.data.setAll(dataCodigo);

    // Add cursor
    chartCodigo.set("cursor", am5xy.XYCursor.new(rootCodigo, {}));

    let exportingCodigo = am5plugins_exporting.Exporting.new(rootCodigo, {
      menu: am5plugins_exporting.ExportingMenu.new(rootCodigo, {}),
    });

    chartCodigo.children.unshift(
      am5.Label.new(rootCodigo, {
        text: "Top 10 Familias / Venta $",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

      // Venta Codigo Piezas

      let rootCodigoPiezas = am5.Root.new("chartdivCodigoPiezas");

      rootCodigoPiezas.setThemes([am5themes_Animated.new(rootCodigoPiezas)]);
  
      let chartCodigoPiezas = rootCodigoPiezas.container.children.push(
        am5xy.XYChart.new(rootCodigoPiezas, {
          panY: false,
          layout: rootCodigoPiezas.verticalLayout,
        })
      );
  
      let dataCodigoPiezas = graficasCodigoPiezas;
  
      // Create Y-axis
      let yAxisCodigoPiezas = chartCodigoPiezas.yAxes.push(
        am5xy.ValueAxis.new(rootCodigoPiezas, {
          min:0,
          renderer: am5xy.AxisRendererY.new(rootCodigoPiezas, {}),
        })
      );
  
      // Create X-Axis
      let xAxisCodigoPiezas = chartCodigoPiezas.xAxes.push(
        am5xy.CategoryAxis.new(rootCodigoPiezas, {
          renderer: am5xy.AxisRendererX.new(rootCodigoPiezas, {}),
          categoryField: "Codigo",
        })
      );
      xAxisCodigoPiezas.data.setAll(dataCodigoPiezas);
  
      // Create series
      let seriesCodigoPiezas = chartCodigoPiezas.series.push(
        am5xy.ColumnSeries.new(rootCodigoPiezas, {
          name: "Codigo",
          xAxis: xAxisCodigoPiezas,
          yAxis: yAxisCodigoPiezas,
          valueYField: "Cantidad",
          categoryXField: "Codigo",
          tooltip: am5.Tooltip.new(rootCodigoPiezas, {
            labelText: "{Codigo} {valueY}",
          }),
        })
      );
      seriesCodigoPiezas.data.setAll(dataCodigoPiezas);
  
      // Add cursor
      chartCodigoPiezas.set("cursor", am5xy.XYCursor.new(rootCodigoPiezas, {}));
  
      let exportingCodigoPiezas = am5plugins_exporting.Exporting.new(rootCodigoPiezas, {
        menu: am5plugins_exporting.ExportingMenu.new(rootCodigoPiezas, {}),
      });
  
      chartCodigoPiezas.children.unshift(
        am5.Label.new(rootCodigoPiezas, {
          text: "Top 10 Familias / Piezas",
          fontSize: 25,
          fontWeight: "500",
          textAlign: "center",
          x: am5.percent(50),
          centerX: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0,
        })
      );

     // Venta Mensual

     let rootMes = am5.Root.new("chartdivMes");

     rootMes.setThemes([am5themes_Animated.new(rootMes)]);
 
     let chartMes = rootMes.container.children.push(
       am5xy.XYChart.new(rootMes, {
         panY: false,
         layout: rootMes.verticalLayout,
       })
     );
 
     let dataMes = graficasMes;
 
     // Create Y-axis
     let yAxisMes = chartMes.yAxes.push(
       am5xy.ValueAxis.new(rootMes, {
        min:0,
         renderer: am5xy.AxisRendererY.new(rootMes, {}),
       })
     );
 
     // Create X-Axis
     let xAxisMes = chartMes.xAxes.push(
       am5xy.CategoryAxis.new(rootMes, {
         renderer: am5xy.AxisRendererX.new(rootMes, {}),
         categoryField: "Mes",
       })
     );
     xAxisMes.data.setAll(dataMes);
 
     // Create series
     let seriesMes = chartMes.series.push(
       am5xy.ColumnSeries.new(rootMes, {
         name: "Mes",
         xAxis: xAxisMes,
         yAxis: yAxisMes,
         valueYField: "Importe",
         categoryXField: "Mes",
         tooltip: am5.Tooltip.new(rootMes, {
           labelText: "{Mes} ${valueY}",
         }),
       })
     );
     seriesMes.data.setAll(dataMes);
 
     // Add cursor
     chartMes.set("cursor", am5xy.XYCursor.new(rootMes, {}));
 
     let exportingMes = am5plugins_exporting.Exporting.new(rootMes, {
       menu: am5plugins_exporting.ExportingMenu.new(rootMes, {}),
     });
 
     chartMes.children.unshift(
       am5.Label.new(rootMes, {
         text: "Mensual",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );

        // // Grafica Vendedores

        // let rootVendedores = am5.Root.new("chartdivVendedores");

        // rootVendedores.setThemes([am5themes_Animated.new(rootVendedores)]);
    
        // let chartVendedores = rootVendedores.container.children.push(
        //   am5xy.XYChart.new(rootVendedores, {
        //     panY: false,
        //     layout: rootVendedores.verticalLayout,
        //   })
        // );
    
        // let dataVendedores = graficasVendedores;
    
        // // Create Y-axis
        // let yAxisVen = chartVendedores.yAxes.push(
        //   am5xy.ValueAxis.new(rootVendedores, {
        //   min:0,
        //     renderer: am5xy.AxisRendererY.new(rootVendedores, {}),
        //   })
        // );
    
        // // Create X-Axis
        // let xAxisVen = chartVendedores.xAxes.push(
        //   am5xy.CategoryAxis.new(rootVendedores, {
        //     renderer: am5xy.AxisRendererX.new(rootVendedores, {}),
        //     categoryField: "Vendedor",
        //   })
        // );
        // xAxisVen.data.setAll(dataVendedores);
        // xAxisVen.get("renderer").labels.template.setAll({
        //   oversizedBehavior: "wrap",
        //   maxWidth: 100,
        //   rotation:-45
        // });
    
        // // Create series
        // let seriesVendedores = chartVendedores.series.push(
        //   am5xy.ColumnSeries.new(rootVendedores, {
        //     name: "Vendedor",
        //     xAxis: xAxisVen,
        //     yAxis: yAxisVen,
        //     valueYField: "Importe",
        //     categoryXField: "Vendedor",
        //     tooltip: am5.Tooltip.new(rootVendedores, {
        //       labelText: "{Vendedor} ${valueY}",
        //     }),
        //   })
        // );
        // seriesVendedores.data.setAll(dataVendedores);
    
        // // Add cursor
        // chartVendedores.set("cursor", am5xy.XYCursor.new(rootVendedores, {}));
    
        // let exportingVendedor = am5plugins_exporting.Exporting.new(rootVendedores, {
        //   menu: am5plugins_exporting.ExportingMenu.new(rootVendedores, {}),
        // });
    
        // chartVendedores.children.unshift(
        //   am5.Label.new(rootVendedores, {
        //     text: "Vendedores",
        //     fontSize: 25,
        //     fontWeight: "500",
        //     textAlign: "center",
        //     x: am5.percent(50),
        //     centerX: am5.percent(50),
        //     paddingTop: 0,
        //     paddingBottom: 0,
        //   })
        // );

     let rootVendedores = am5.Root.new("chartdivVendedores");

     rootVendedores.setThemes([am5themes_Animated.new(rootVendedores)]);
 
     let chartVendedores = rootVendedores.container.children.push(
       am5percent.PieChart.new(rootVendedores, {
         endAngle: 270,
         layout: rootVendedores.verticalLayout,
         tooltip: am5.Tooltip.new(rootVendedores, {
           labelText: "{Vendedor} ${value}",
         }),
       })
     );
 
     let seriesVendedores = chartVendedores.series.push(
       am5percent.PieSeries.new(rootVendedores, {
         valueField: "Importe",
         categoryField: "Vendedor",
         endAngle: 270,
       })
     );
 
     seriesVendedores.states.create("hidden", {
       endAngle: -90,
     });
 
     chartVendedores.children.unshift(
       am5.Label.new(rootVendedores, {
         text: "Vendedores",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );
 
     seriesVendedores.labels.template.set("forceHidden", true);
     seriesVendedores.ticks.template.setAll({
       visible: false,
       stroke: am5.color("rgb(252, 252, 252)"),
     });
     seriesVendedores.data.setAll(graficasVendedores);
     seriesVendedores.appear(1000, 100);
 
    //  let legendVendedores = chartVendedores.children.push(
    //    am5.Legend.new(rootVendedores, {
    //      nameField: "Vendedor",
    //    })
    //  );
 
    //  legendVendedores.data.setAll(seriesVendedores.dataItems);


    return () => {
      root.dispose();
      rootLineas.dispose();
      rootProveedores.dispose();
      rootAreas.dispose();
      rootVendedores.dispose();
      rootMes.dispose();
      rootCodigo.dispose();
      rootCodigoPiezas.dispose();
    };
  }, [graficasDepartamentosClientes, graficasLineas, graficasAreas, graficasProveedores, graficasMes, graficasVendedores, graficasCodigo, graficasCodigoPiezas]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/Dashboards"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Dashboard Ventas</h3>
        <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
          <Row>
                <Col md={2}>
              <Label className="mr-sm-2">Departamentos</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedDepartamentoCliente}
                required
                onChange={(e) => {
                  setSelectedDepartamentoCliente(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {departamentosClientes
                  .sort((a, b) => (a.DepartamentoCliente > b.DepartamentoCliente ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idDepartamentoCliente}>{a.DepartamentoCliente}</option>;
                  })}
              </Input>
            </Col>

          

            <Col md={2}>
              <Label className="mr-sm-2">Lineas</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedLinea}
                required
                onChange={(e) => {
                  setSelectedLinea(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {lineas
                  .sort((a, b) => (a.Linea > b.Linea ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idLinea}>{a.Linea}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Proveedores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                required
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.Proveedor > b.Proveedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idProveedor}>{a.Proveedor}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Clientes</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.Cliente > b.Cliente ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idCliente}>{a.Cliente}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Vendedores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedVendedor}
                required
                onChange={(e) => {
                  setSelectedVendedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {vendedores
                  .sort((a, b) => (a.Vendedor > b.Vendedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idVendedor}>{a.Vendedor}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Almacen</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedArea}
                required
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {areas
                  .sort((a, b) => (a.Area > b.Area ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArea}>{a.Area}</option>;
                  })}
              </Input>
            </Col>

        </Row> 

        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
        a
      >
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Piezas 
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {}).format(cantidadVenta)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              Venta $
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(valorVenta)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>

      
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdiv" style={{ width: "40%", height: "500px" }}></div>
          <div
            id="chartdivLineas"
            style={{ width: "40%", height: "500px" }}
          ></div>
        </div>


        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            id="chartdivProveedores"
            style={{ width: "40%", height: "500px" }}
          ></div>

          <div
            id="chartdivVendedores"
            style={{ width: "40%", height: "500px" }}
          ></div>

        </div>

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >

          <div
            id="chartdivAreas"
            style={{ width: "90%", height: "500px" }}
          ></div>
        </div>

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >

          <div
            id="chartdivMes"
            style={{ width: "90%", height: "500px" }}
          ></div>
        </div>

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >

          <div
            id="chartdivCodigo"
            style={{ width: "40%", height: "500px" }}
          ></div>

<div
            id="chartdivCodigoPiezas"
            style={{ width: "40%", height: "500px" }}
          ></div>

        </div>

        {/* <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >

        
        </div> */}


      </div>


      {/* ) : undefined}  */}
    </>
  );
}
export default DashboardVentas;
