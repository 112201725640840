import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Redirect } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faFacebook, fas } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faHome,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import Password from "../Login/Password";
import axios from "axios";

library.add(
  fab,
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faFacebook,
  faHome,
  faEnvelope,
  faPhoneAlt
);

const Header = (props) => {
  // const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const AREAS_SALO = process.env.REACT_APP_AREAS_SALO;
  const AREAS_BENY = process.env.REACT_APP_AREAS_BENY;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleLogout = () => setDropdownOpen((prevState) => !prevState);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { isAuth, user } = useContext(AuthContext);
  const [modalPassword, setModalPassword] = useState(false);
  const togglePassword = () => setModalPassword(!modalPassword);

  const [status, setStatus] = useState("Esperando Estado...");

  // useEffect(() => {
  //   axios
  //   .get(`${URL_WHATSAPP}Status`, {
  //     headers: {
  //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //     },
  //   })
  //   .then((response) => {
  //     let allStatus = response.data.state;
  //     setStatus(allStatus);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // })

  return (
    <>
      {isAuth ? (
        <header className="sticky-top">
          {!user.menu_evidencias ? (
            <>
              <Navbar color="dark" dark expand="lg">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="mr-auto" navbar>
                    {user.areas != AREAS_SALO &&
                    user.areas != AREAS_BENY ? (
                      <>
                        {user.menu_articulos || user.fichaTecnica ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuArticulos"
                            >
                              MODELOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            MODELOS
                          </NavLink>
                        )}

                        {user.menu_compras ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuCompras">
                              COMPRAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            COMPRAS
                          </NavLink>
                        )}

                        {user.menu_ventas ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuVentas">
                              VENTAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            VENTAS
                          </NavLink>
                        )}

                        {user.menuTiendas ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuTiendas">
                              TIENDAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            TIENDAS
                          </NavLink>
                        )}

                        {user.menu_inventarios ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuInventarios"
                            >
                              INVENTARIOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            INVENTARIOS
                          </NavLink>
                        )}
                        {user.menu_admin ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuGastos">
                              GASTOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            GASTOS
                          </NavLink>
                        )}
                        {user.menu_empleados ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuNominas">
                              EMPLEADOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            EMPLEADOS
                          </NavLink>
                        )}
                        {user.configAdmin ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuAdmin">
                              ADMIN
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            ADMIN
                          </NavLink>
                        )}

                        {user.pagoProveedores ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuCXP">
                              CXP
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            CXP
                          </NavLink>
                        )}

                        {user.configuracion ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuConfiguraciones"
                            >
                              CONFIG
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            CONFIG
                          </NavLink>
                        )}
                  {user.menu_admin  ? (
                    <NavItem>
                      <NavLink className="titulosNav" href="/WhatsApp">
                        WHATSAPP
                      </NavLink>
                    </NavItem>

                  ) : (
                    <NavLink className="titulosNav"  disabled>
                      WHATSAPP
                    </NavLink>
                  )}

                        {user.menu_reportes ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuReportes"
                            >
                              REPORTES
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            REPORTES
                          </NavLink>
                        )}

                        {user.menu_usuarios ? (
                          <NavItem>
                            <NavLink
                              className="titulosNav"
                              href="/MenuUsuarios"
                            >
                              USUARIOS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            USUARIOS
                          </NavLink>
                        )}

                        {user.menu_logs ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuLogs">
                              LOGS
                            </NavLink>
                          </NavItem>
                        ) : undefined}
                      </>
                    ) : (
                      <>
                        {user.menuTiendas ? (
                          <NavItem>
                            <NavLink className="titulosNav" href="/MenuTiendas">
                              TIENDAS
                            </NavLink>
                          </NavItem>
                        ) : (
                          <NavLink className="titulosNav" color="link" disabled>
                            TIENDAS
                          </NavLink>
                        )}
                      </>
                    )}
                  </Nav>
                  <NavbarText>
                    <NavLink className="titulosNav">
                      <Dropdown
                        direction="left"
                        isOpen={dropdownOpen}
                        toggle={toggleLogout}
                      >
                        <DropdownToggle color="link">
                          <i class="fas fa-user-cog fa-2x"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem href="/logout">Logout</DropdownItem>
                          <DropdownItem onClick={togglePassword}>
                            Password
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </NavLink>
                  </NavbarText>
                  <Modal
                    size="sm"
                    isOpen={modalPassword}
                    toggle={togglePassword}
                  >
                    <ModalHeader toggle={togglePassword}>
                      <h4>Cambio de contraseña</h4>
                    </ModalHeader>
                    <ModalBody>
                      <Password />
                    </ModalBody>
                  </Modal>
                </Collapse>
              </Navbar>
            </>
          ) : (
            <Navbar color="dark" dark expand="md">
              <a href="/Inicio">
                <img
                  src={process.env.PUBLIC_URL + "/LogoSistemify2RS.png"}
                  height="50 px"
                  alt="logo"
                />
              </a>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto" navbar>
              <NavItem>
                  <NavLink className="titulosNav" href="/ListadoVentasNoEvidencia">
                    EVIDENCIAS VENTAS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="titulosNav" href="/ListadoTraspasosSalidaNoEvidencia">
                    EVIDENCIAS TRASPASOS
                  </NavLink>
                </NavItem>
                </Nav>
                <NavbarText>
                    <NavLink className="titulosNav">
                      <Dropdown
                        direction="left"
                        isOpen={dropdownOpen}
                        toggle={toggleLogout}
                      >
                        <DropdownToggle color="link">
                          <i class="fas fa-user-cog fa-2x"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem href="/logout">Logout</DropdownItem>
                          <DropdownItem onClick={togglePassword}>
                            Password
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </NavLink>
                  </NavbarText>
                  <Modal
                    size="sm"
                    isOpen={modalPassword}
                    toggle={togglePassword}
                  >
                    <ModalHeader toggle={togglePassword}>
                      <h4>Cambio de contraseña</h4>
                    </ModalHeader>
                    <ModalBody>
                      <Password />
                    </ModalBody>
                  </Modal>
              </Collapse>
            </Navbar>
          )}
        </header>
      ) : undefined}
    </>
  );
};

export default Header;
