import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import TablaGrupos from "./TablaGrupos";
import GruposCreate from "./GruposCreate";
import axios from "axios";
import { Table } from "reactstrap";
import Header from "../../../layout/Header/Header";

function ListadoGrupos() {
  const { user } = useContext(AuthContext);
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const [grupos, setGrupos] = useState([]);

  useEffect(() => {
    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.grupos  ?(
      <div className="container">
        <div className="row">
          {
            <div className="col-6">
              <div className="card">
                <div className="card-body">
                  <h3 align="center">Grupos</h3>
                  <Table size="sm" striped borderless className="table-responsive-xl">
                    <thead>
                      <tr>
                        <th className="tituloTabla">Nombre</th>
                        <th className="tituloTabla">Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {grupos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return (
                          <tr>
                            <TablaGrupos name={a.name} _id={a._id} is_active={a.is_active} />
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          }
          <div className="col-6">
            <div className="card">
              <div className="card-body">
                <GruposCreate />
              </div>
            </div>
          </div>
        </div>
      </div>
      ): undefined } 
      
    </>
  );
}

export default ListadoGrupos;
