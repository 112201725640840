import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function EmbarquesDirectosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_EMBARQUES = process.env.REACT_APP_URL_EMBARQUES;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_GASTOS_EMBARQUES = process.env.REACT_APP_URL_GASTOS_EMBARQUES;
  const URL_CONCEPTOS_GASTOS_EMBARQUES =
    process.env.REACT_APP_URL_CONCEPTOS_GASTOS_EMBARQUES;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [fechaEmbarque, setFechaEmbarque] = useState(hoy);
  const [fechaEstimada, setFechaEstimada] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [tipoCambioUSDMXN, setTipoCambioUSDMXN] = useState(1);
  const [tipoCambioCNYUSD, setTipoCambioCNYUSD] = useState(1);


  const [articulos, setArticulos] = useState([]);
  const [contenedor, setContenedor] = useState("");
  const [proveedores, setProveedores] = useState([]);
  const [agrupado, setAgrupado] = useState([]);
  const [bultos, setBultos] = useState(0);
  const [noFactura, setNoFactura] = useState("0");

  const [conceptosGastos, setConceptosGastos] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      piezasPorCaja: 0,
      cajas: 0,
      costo: 0,
      costoUSD: 0,
      costoTotal: 0,
      proveedorOrden: "",
      moneda:"",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
  
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_CONCEPTOS_GASTOS_EMBARQUES}Fijos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allConceptosGastos = res.data;
        setConceptosGastos(allConceptosGastos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
    .get(`${URL_PROVEEDORES}`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
    

  }, []);

  const saveEmbarqueNuevo = async (event) => {
    event.preventDefault();
    Swal.fire({
      title: "Estas seguro?",
      text: "Se guardará el Embarque!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, guardar!",
    }).then(async (result) => {
    if (
      result.isConfirmed &&
      fechaEmbarque != "" &&
      fechaEstimada != "" &&
      contenedor != "" &&
      observaciones != ""
    ) {
      setValidaBoton(false);
      toggleProgreso();
     
      let totalArticulos = inputFields.length + conceptosGastos.length;
      let TCosto = inputFields.map((c) => parseFloat(c.costoTotal));
      let TTC = TCosto.reduce((t, total, index) => t + total, 0);
      try {
        await axios
          .post(
            URL_EMBARQUES,
            {
              fechaEmbarque,
              fechaEstimada,
              observaciones,
              contenedor,
              total_cantidad,
              recibido: "No",
              total_costo: parseFloat(TTC).toFixed(2),
              saldo: parseFloat(TTC).toFixed(2),
              creado: user.id,
              total_gasto: 0,
              tipoCambioUSDMXN,
              tipoCambioCNYUSD,
              bultos,
              noFactura
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            agrupado.map((b) => {
              axios
                .post(
                  `${URL_NOTAS_CXP}`,
                  {
                    proveedores: b.proveedorOrden,
                    embarques: data.data._id,
                    total_cantidad: b.cantidad,
                    total_costo: parseFloat(b.costoTotal).toFixed(2),
                    saldo: parseFloat(b.costoTotal).toFixed(2),
                    calidad:"No",
                    fecha:fechaEmbarque
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then((data2) => {
                  inputFields.map((a) => {
                    if (
                      a.articulos != "" &&
                      a.cantidad != 0 &&
                      a.proveedorOrden == b.proveedorOrden
                    ) {
                      axios
                        .post(
                          `${URL_ARTICULOS_EMBARQUES}`,
                          {
                            articulos: a.articulos,
                            cantidad: a.cantidad,
                            recibido: 0,
                            pendiente_recibir: a.cantidad,
                            embarques: data.data._id,
                            notasCxP: data2.data._id,
                            costo: a.costo,
                            total_costo: a.costoTotal,
                            proveedores: a.proveedorOrden,
                            faltante: 0,
                            danado: 0,
                            impuestos: 0,
                            revisarFaltante: 10,
                            revisarDanado: 10,
                            pedirFaltante: 0,
                            pedirDanado: 10,
                            faltanteTotal: 0,
                            danadoTotal: 0,
                            descuentoFaltante: 0,
                            descuentoDanado: 0,
                            descuentoTotal: 0,
                            familias: "NA",
                            sup: "NA",
                            observaciones:"NA",
                            calidad:"No",
                            costoUSD: a.costoUSD,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          totalArticulos = totalArticulos - 1;
                          if (totalArticulos == 0) {
                            axios
                              .post(
                                URL_LOGS,
                                {
                                  tipo: "Crear Embarque",
                                  detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                                  user: user.id,
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer: ${localStorage.getItem(
                                      "app_token"
                                    )}`,
                                  },
                                }
                              )
                              .then(() => {
                                Swal.fire(
                                  "Good job!",
                                  "Creado con exito",
                                  "success"
                                );
                                setTimeout(() => {
                                  window.location.reload();
                                }, 1000);
                              })
                              .catch((error) => {
                                Swal.fire({
                                  icon: "error",
                                  title: "Oops...",
                                  text: "Something went wrong!",
                                  footer: `${error.response.data}`,
                                });
                                console.log(error);
                                setValidaBoton(true);
                              });
                          }
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    } else {
                      totalArticulos = totalArticulos - 1;
                      if (totalArticulos == 0) {
                        axios
                          .post(
                            URL_LOGS,
                            {
                              tipo: "Crear Embarque",
                              detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                              user: user.id,
                            },
                            {
                              headers: {
                                Authorization: `Bearer: ${localStorage.getItem(
                                  "app_token"
                                )}`,
                              },
                            }
                          )
                          .then(() => {
                            Swal.fire(
                              "Good job!",
                              "Creado con exito",
                              "success"
                            );
                            setTimeout(() => {
                              window.location.reload();
                            }, 1000);
                          })
                          .catch((error) => {
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: "Something went wrong!",
                              footer: `${error.response.data}`,
                            });
                            console.log(error);
                            setValidaBoton(true);
                          });
                      }
                    }
                  });
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });

              // Acaba el primer map
            });


            conceptosGastos.map((w) => {
              axios
                .post(
                  URL_GASTOS_EMBARQUES,
                  {
                    conceptosGastosEmbarques: w._id,
                    estimado: w.estimado,
                    real: w.estimado,
                    embarques: data.data._id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalArticulos = totalArticulos - 1;
                  if (totalArticulos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Crear Embarque",
                          detalle: `Fecha Embarque ${fechaEmbarque} / Contenedor ${contenedor}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                        setValidaBoton(true);
                      });
                  }
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            });
          })
          // Catch primer then
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data.message}`,
            });
            console.log(error);
            setValidaBoton(true);
            setModalProgreso(false)
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          // footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
      setValidaBoton(true);
    }
  })
  };

  
  const handleAddFields = () => {

    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        piezasPorCaja: 0,
        cajas: 0,
        costo: 0,
        costoUSD: 0,
        costoTotal: 0,
        proveedorOrden: "",
        moneda:"",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let agrupado = values.reduce(function (groups, item) {
      const val = item["proveedorOrden"];
      groups[val] = groups[val] || {
        proveedorOrden: item.proveedorOrden,
        costoTotal: 0,
        cantidad: 0,
      };
      groups[val].proveedorOrden = item.proveedorOrden;
      groups[val].costoTotal += item.costoTotal;
      groups[val].cantidad += item.cantidad;
      return groups;
    }, []);
    let temp = Object.values(agrupado)
 setAgrupado(temp)
  };



  function BuscaProveedor(id, event) {

    let m 

    proveedores.map((a)=>{
      if(a._id === event.target.value){
        m = a.moneda
      }
    })
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.proveedorOrden = event.target.value;
        i.moneda = m
      }
      return i;
    });
    setInputFields(newInputFields);
  }


  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["proveedorOrden"];
      groups[val] = groups[val] || {
        proveedorOrden: item.proveedorOrden,
        costoTotal: 0,
        cantidad: 0,
      };
      groups[val].proveedorOrden = item.proveedorOrden;
      groups[val].costoTotal += item.costoTotal;
      groups[val].cantidad += item.cantidad;
      return groups;
    }, []);
    let temp = Object.values(agrupado)
 setAgrupado(temp)
  }

  // Verificar cantidad

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        i.cajas = parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
        i.costoTotal = parseFloat(event.target.value) * parseFloat(i.costoUSD);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCosto = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
        if(i.moneda == "USD"){
          i.costoUSD = parseFloat(event.target.value)
          i.costoTotal = parseFloat(event.target.value) * parseFloat(i.cantidad);
        } else {
          i.costoUSD = parseFloat(event.target.value) / tipoCambioCNYUSD
          i.costoTotal =(parseFloat(event.target.value) / tipoCambioCNYUSD) * parseFloat(i.cantidad)
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
       
        i[event.target.name] = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        i.costoTotal =
          parseFloat(event.target.value) *
          parseFloat(i.piezasPorCaja) *
          parseFloat(i.costoUSD);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function handleChangeInputArt(id, event) {

let m

inputFields.map((a)=>{
  if(a.id == id){
    m = a.moneda
  }
})
    articulos.map((a) => {
      if (a._id == event.target.value) {
        if(m == "USD"){
          let costo = a.costoUSD
          let costoUSD = a.costoUSD
          let articulo = a._id
          let piezasPorCaja = a.piezasPorCaja
          handleChangeInputOC(id, costo, costoUSD, articulo, piezasPorCaja);
        }else{
          let costo = a.costoUSD
          let costoUSD = a.costoUSD / tipoCambioCNYUSD
          let articulo = a._id
          let piezasPorCaja = a.piezasPorCaja
          handleChangeInputOC(id, costo, costoUSD, articulo, piezasPorCaja);
        }
        
      }
    });
  }

  const handleChangeInputOC = (id, costo, costoUSD, articulo, piezasPorCaja) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.costo = costo;
        i.costoUSD = costoUSD;
        i.costoTotal = parseFloat(costoUSD) * parseFloat(i.cantidad);
        i.articulos = articulo;
        i.piezasPorCaja = piezasPorCaja;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.embarques ? (
        <div className="card container col-10">
          <h3 align="center">Nuevo Embarque Directo</h3>
          <Form onSubmit={saveEmbarqueNuevo}>
            <Row>
              <Col md={2}>
                <Label>Fecha Embarque</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEmbarque}
                  required
                  onChange={(e) => {
                    setFechaEmbarque(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label># Contenedor</Label>
                <Input
                  type="text"
                  placeholder="contenedor"
                  value={contenedor}
                  required
                  onChange={(e) => {
                    setContenedor(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>ETA de Arribo</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fechaEstimada}
                  required
                  onChange={(e) => {
                    setFechaEstimada(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Bultos
                </Label>
                <Input
                  type="number"
                  value={bultos}
                  required
                  onChange={(e) => {
                    setBultos(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  No. Factura
                </Label>
                <Input
                  type="text"
                  placeholder="NoFactura"
                  value={noFactura}
                  required
                  onChange={(e) => {
                    setNoFactura(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  TIpo Cambio CNY USD
                </Label>
                <Input
                  type="number"
                  placeholder="tipoCambioCNYUSD"
                  value={tipoCambioCNYUSD}
                  required
                  onChange={(e) => {
                    setTipoCambioCNYUSD(e.target.value);
                    const newInputFields = inputFields.map((i) => {
                      if(i.moneda == 'CNY'){
                        i.costo = i.costo
                        i.costoUSD = i.costo / e.target.value
                        i.costoTotal = parseFloat(i.costo / e.target.value) * parseFloat(i.cantidad);
                        }
                      return i;
                    });
                    setInputFields(newInputFields);
                    ActualizaTotales()
                  }}
                />
              </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  TIpo Cambio USD MXN
                </Label>
                <Input
                  type="number"
                  placeholder="tipoCambioUSDMXN"
                  value={tipoCambioUSDMXN}
                  required
                  onChange={(e) => {
                    setTipoCambioUSDMXN(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas.
                </h4>
              </Col>
            </Row>

            {/* Tabla Articulos */}

            <Row>
            <Col md={1}>
                <Label className="mr-sm-2">Proveedor</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Costo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Moneda</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Costo USD</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Pzas por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="proveedor"
                      type="select"
                      value={inputField.proveedorOrden}
                      required
                      onChange={(event) => {
                        BuscaProveedor(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona</option>
                      {proveedores
                      .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                      })}
                    </Input>
                  </Col>
                
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="orden"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        handleChangeInputArt(inputField.id, event)
                      }}
                    >
                      <option value="">Selecciona</option>
                      {articulos
                      .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                      .map((a) => {
                        if(inputField.proveedorOrden == a.proveedores[0]._id){
                          return (
                            <option value={a._id}>
                              {a.codigo} {a.nombre} 
                            </option>
                          );}
                      })}
                    </Input>
                  </Col>
                 
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="costo"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.costo}
                      onChange={(event) =>
                        handleChangeInputCosto(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="moneda"
                      type="text"
                      min="0"
                      step="any"
                      value={inputField.moneda}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="costoUSD"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.costoUSD}
                      disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCantidadCajas(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="string"
                      value={inputField.piezasPorCaja}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="costoTotal"
                      type="string"
                      value={inputField.costoTotal}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={12}>
                <h4 id="logoutBoton">
                  TOTALES{" "}
                  {new Intl.NumberFormat("en-US").format(total_cantidad)} pzas.
                </h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoEmbarques"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default EmbarquesDirectosCreate;
