import React, { useState, useEffect, useContext, useRef, useLayoutEffect, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
} from "reactstrap";
import moment from 'moment';
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";



function GraficaVentasVendedor() {
  const { user } = useContext(AuthContext);
  const URL_GRAFICAS = process.env.REACT_APP_URL_GRAFICAS;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-90);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [graficas, setGraficas] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: 'center',
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
  
  useMemo(()=>{

    axios
    .get(`${URL_GRAFICAS}/ventaVendedor/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allGraficas = response.data;
      setGraficas(allGraficas);
      setCargando(false)
      Toast.close()
    })
    .catch((err) => {
      console.log(err);
    });

    if(cargando){
      Toast.fire({
        icon: 'success',
        title: 'Danos unos segundos....'
      })
    }

  },[selectedFechaInicio, selectedFechaFin, cargando])

  useLayoutEffect(() => {
    
    // let root = am5.Root.new("chartdiv");

    // root.setThemes([
    //   am5themes_Animated.new(root)
    // ]);

    // let chart = root.container.children.push( 
    //   am5xy.XYChart.new(root, {
    //     panY: false,
    //     layout: root.verticalLayout
    //   }) 
    // );

    // let data = graficas

    // // Create Y-axis
    // let yAxis = chart.yAxes.push(
    //   am5xy.ValueAxis.new(root, {
    //     renderer: am5xy.AxisRendererY.new(root, {}),
    //   })
    // );


    // // Create X-Axis
    // let xAxis = chart.xAxes.push(
    //   am5xy.CategoryAxis.new(root, {
    // renderer: am5xy.AxisRendererX.new(root, {}),
    // tooltip: am5.Tooltip.new(root, {}),
    //     categoryField: "Vendedor"
    //   })
    // );
    // xAxis.data.setAll(data);

    // xAxis.get("renderer").labels.template.setAll({
    //   oversizedBehavior: "wrap",
    //   maxWidth: 100,
    //   rotation:-45
    // });

    // // Create series
    // let series1 = chart.series.push(
    //   am5xy.ColumnSeries.new(root, {
    //     name: "Venta",
    //     xAxis: xAxis,
    //     yAxis: yAxis,
    //     valueYField: "Importe",
    //     categoryXField: "Vendedor",
    //     tooltip: am5.Tooltip.new(root, {
    //       labelText: "${valueY}"
    //     })
    //   })
    // );
    // series1.data.setAll(data);
    

    // chart.set("scrollbarX", am5.Scrollbar.new(root, {
    //   orientation: "horizontal"
    // }));

    // // let series2 = chart.series.push(
    // //   am5xy.ColumnSeries.new(root, {
    // //     name: "Series",
    // //     xAxis: xAxis,
    // //     yAxis: yAxis,
    // //     valueYField: "value2",
    // //     categoryXField: "category"
    // //   })
    // // );
    // // series2.data.setAll(data);

    // // Add legend
    // // let legend = chart.children.push(am5.Legend.new(root, {}));
    // // legend.data.setAll(chart.series.values);

    // // Add cursor
    // chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // let exporting = am5plugins_exporting.Exporting.new(root, {
    //   menu: am5plugins_exporting.ExportingMenu.new(root, {})
    // });

    // Grafica PIE
    let root = am5.Root.new("chartdiv");

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "Importe",
        categoryField: "Vendedor",
        endAngle: 270,
      })
    );

    series.states.create("hidden", {
      endAngle: -90
    });


    series.data.setAll(graficas)
    series.appear(1000, 100)

    return () => {
      root.dispose();
    };
  }, [graficas]);

  return (
    <>
    <Header />
    <br />
    <br />
    {/* {user.menu_disponible ? ( */}
      <div className="card container col-12">
      <Row>
        <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              
            </Col>
        </Row>
        <h3 align="center">Ventas por Vendedor</h3>
    <div className="container col-12">
    <Row>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
                setCargando(true)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
                setCargando(true)
              }}
            />
          </Col>
        </Row>
        </div>
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
            </div>
      {/* ) : undefined}  */}
  </>
  );
}
export default GraficaVentasVendedor;
