import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as XLSX from "xlsx";

import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function TraspasosSalidaCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_TRASPASOS_SALIDA = process.env.REACT_APP_URL_TRASPASOS_SALIDA;
  const URL_ARTICULOS_TS = process.env.REACT_APP_URL_ARTICULOS_TS;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const AREAS_CAMARONES = process.env.REACT_APP_AREAS_CAMARONES;
  const AREAS_PICAL44 = process.env.REACT_APP_AREAS_PICAL44;
  const AREAS_PICAL57 = process.env.REACT_APP_AREAS_PICAL57;
  const AREAS_PICAL_REFRI = process.env.REACT_APP_AREAS_PICAL_REFRI;
  const AREAS_SOTANO = process.env.REACT_APP_AREAS_SOTANO;
  const AREAS_PLAZA = process.env.REACT_APP_AREAS_PLAZA;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;

  // const URL_TRASPASOS_SOLICITUD = process.env.REACT_APP_URL_TRASPASOS_SOLICITUD;
  // const URL_ARTICULOS_TSOL = process.env.REACT_APP_URL_ARTICULOS_TSOL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [inventarios, setInventarios] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [articulos, setArticulos] = useState([]);
  const [fecha, setFecha] = useState(hoy);
  const [areas, setAreas] = useState([]);
  const [areaSalida, setAreaSalida] = useState("");
  const [areaDestino, setAreaDestino] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total, setTotal] = useState(0);
  const [articulosUnicos, setArticulosUnicos] = useState([]);

  const [traspasosSolicitud, setTraspasosSolicitud] = useState([]);
  const [selectedTraspasoSolicitud, setSelectedTraspasoSolicitud] =
    useState("");

  const [disponible, setDisponible] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      cantidad: 0,
      cantInv: 0,
      idInv: "",
      cajas: 0,
      piezasPorCaja: 0,
      disponible: 0,
      cantSolicitud: 0,
      costoUnitario: 0,
      articulosNombre: "",
      articulosCodigo: "",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveTraspasosSalida = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();

    let totalArticulos = inputFields.length;
    try {
      await axios
        .post(
          URL_TRASPASOS_SALIDA,
          {
            fecha,
            areaSalida,
            areaDestino,
            observaciones,
            user: user.id,
            total,
            fotoEvidencia: "No",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            axios
              .post(
                URL_ARTICULOS_TS,
                {
                  traspasosSalida: data.data._id,
                  articulos: a.articulos,
                  cantidad: parseFloat(a.cantidad),
                  surtido: 0,
                  inventarios: a.idInv,
                  areas: areaSalida,
                  costoUnitario: a.costoUnitario,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalArticulos = totalArticulos - 1;
                if (totalArticulos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Traspaso Salida",
                        detalle: `${areaSalida} ${total}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
                console.log(error);
                setValidaBoton(true);
              });
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
      console.log(error);
    }
  };
  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        cajas: 0,
        piezasPorCaja: 0,
        disponible: 0,
        cantSolicitud: 0,
        costoUnitario: 0,
        articulosNombre: "",
        articulosCodigo: "",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let cant = values.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        // if(AREAS_CAMARONES == areaSalida){
        //   if(i.cantInv >= event.target.value
        //     //  && i.disponible >= event.target.value
        //      ){
        //     i[event.target.name] = event.target.value;
        //   }else {
        //     Swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "La cantidad excede tu inventario o Disponible!",
        //       showConfirmButton: false,
        //     });
        //     i.cajas = 0;
        //     i.cantidad = 0;
        //   }
        // } else if (i.cantInv >= event.target.value) {
        i[event.target.name] = event.target.value;
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "La cantidad excede tu inventario o Disponible!",
        //     showConfirmButton: false,
        //   });
        //   i.cajas = 0;
        //   i.cantidad = 0;
        // }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidadCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        // let cantTemp = parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);

        // if(AREAS_CAMARONES == areaSalida){
        //   if (i.cantInv >= cantTemp && i.disponible >= cantTemp) {
        //     i[event.target.name] = event.target.value;
        //     i.cantidad = parseFloat(i.cajas) * parseFloat(i.piezasPorCaja);
        //   } else {
        //     Swal.fire({
        //       icon: "error",
        //       title: "Oops...",
        //       text: "La cantidad excede tu inventario o Disponible!",
        //       showConfirmButton: false,
        //     });
        //     i.cajas = 0;
        //     i.cantidad = 0;
        //   }
        // } else if (i.cantInv >= cantTemp) {
        i.cajas = event.target.value;
        i.cantidad = parseFloat(i.cajas) * parseFloat(i.piezasPorCaja);
        // } else {
        //   Swal.fire({
        //     icon: "error",
        //     title: "Oops...",
        //     text: "La cantidad excede tu inventario o Disponible!",
        //     showConfirmButton: false,
        //   });
        //   i.cajas = 0;
        //   i.cantidad = 0;
        // }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    if (
      inventarios.filter((e) => e.idArticulo == selectedArticulo.idArticulo)
        .length == 1
    ) {
      inventarios.map((a) => {
        if (a.idArticulo == selectedArticulo.idArticulo) {
          let idArticulo = a.idArticulo;
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario;
          let articulosNombre = a.codigo + " " + a.nombre;
          handleChangeInputArticuloUnico(
            id,
            idArticulo,
            piezasPorCaja,
            cantInv,
            idInv,
            costoUnitario,
            articulosNombre
          );
        }
      });

      disponible.map((a) => {
        if (a.idArticulo == selectedArticulo.idArticulo) {
          let disponible = a.disponible;
          handleChangeInputDispobinle(id, disponible);
        }
      });
    } else {
      inventarios.map((a) => {
        if (a.idArticulo == selectedArticulo.idArticulo) {
          let idArticulo = a.idArticulo;
          // let cantInv = parseFloat(a.cantidad)
          // let idInv = a._id
          let piezasPorCaja = a.piezasPorCaja;
          let costoUnitario = a.costoUnitario;
          let articulosNombre = a.codigo + " " + a.nombre;
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            costoUnitario,
            articulosNombre
          );
        }
      });

      disponible.map((a) => {
        if (a.idArticulo == selectedArticulo.idArticulo) {
          let disponible = a.disponible;
          handleChangeInputDispobinle(id, disponible);
        }
      });
    }
  }
  const handleChangeInputArticuloUnico = (
    id,
    idArticulo,
    piezasPorCaja,
    cantInv,
    idInv,
    costoUnitario,
    articulosNombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.costoUnitario = parseFloat(costoUnitario);
        i.articulosNombre = articulosNombre;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    costoUnitario,
    articulosNombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.cantInv = 0;
        i.idInv = "";
        i.piezasPorCaja = parseFloat(piezasPorCaja);
        i.costoUnitario = parseFloat(costoUnitario);
        i.articulosNombre = articulosNombre;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputDispobinle = (id, disponible) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.disponible = parseFloat(disponible);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaPack(id, idInventario) {
    if (inputFields.filter((e) => e.idInv == idInventario).length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo en esa Pack ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.cantInv = 0;
          i.idInv = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      inventarios.map((a) => {
        if (a._id == idInventario) {
          let cantInv = parseFloat(a.cantidad);
          let idInv = a._id;
          let costoUnitario = parseFloat(a.costoUnitario);
          handleChangeInputArticuloPack(id, cantInv, idInv, costoUnitario);
        }
      });
    }
  }

  const handleChangeInputArticuloPack = (id, cantInv, idInv, costoUnitario) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantInv = parseFloat(cantInv);
        i.idInv = idInv;
        i.costoUnitario = parseFloat(costoUnitario);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.cantidad));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  }

  function jalaAlmacenSalida(e) {
    setAreaSalida(e);
    setSelectedTraspasoSolicitud("");
    toggleProgreso();

    axios
      .get(`${URL_INVENTARIOS}/traspasos/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allInventarios = res.data;
        setInventarios(allInventarios);

        let result = allInventarios.map((a) => a.idArticulo);
        setIdsArticulos(result);

        let unicos = allInventarios.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArticulo === value.idArticulo)
        );
        setArticulosUnicos(unicos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}DisponibleTraspaso/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setDisponible(allDisponible);
      })
      .catch((err) => {
        console.log(err);
      });

    setTimeout(() => {
      setModalProgreso(false);
    }, 3000);

    setInputFields([
      {
        id: uuidv4(),
        articulos: "",
        cantidad: 0,
        cantInv: 0,
        idInv: "",
        cajas: 0,
        piezasPorCaja: 0,
        disponible: 0,
        cantSolicitud: 0,
        costoUnitario: 0,
      },
    ]);
  }

  function importarArchivo(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let hojas = [];

    if (name === "file") {
      let reader = new FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = (e) => {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });

        workbook.SheetNames.forEach(function (sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          hojas.push({
            data: XL_row_object,
            sheetName,
          });
        });

        let A1 = hojas[0].data;

        // Crear un objeto que mapee los idArticulo de los artículos a sus objetos correspondientes

        // Si queremos comprar con nombre
        const articulosMap = {};
        inventarios.forEach((articulo) => {
          articulosMap[articulo.codigo] = articulo;
        });
        const articulosNoEncontrados = [];
        for (const a of A1) {
          const articulo = articulosMap[a.codigo];

          // Si queremos comprar con id Articulo
          // const articulosMap = {};
          // inventarios.forEach((articulo) => {
          //   articulosMap[articulo.idArticulo] = articulo;
          // });

          // const articulosNoEncontrados = [];

          // // Iterar sobre A1 una sola vez
          // for (const a of A1) {
          //   const articulo = articulosMap[a.idArticulo];

          if (articulo) {
            inputFields.push({
              id: uuidv4(),
              articulos: articulo.idArticulo,
              piezasPorCaja: articulo.piezasPorCaja,
              cajas: a.cantidad / articulo.piezasPorCaja,
              cantidad: a.cantidad,
              articulosNombre: articulo.codigo + " " + articulo.nombre,
              articulosCodigo: articulo.codigo,
              idInv: articulo._id,
              costoUnitario: articulo.costoUnitario,
              disponible: 0,
              cantInv: articulo.cantidad,
              cantSolicitud: 0,
            });
          } else {
            // // Si no se encuentra el artículo, agregarlo a la lista de no encontrados por idArticulo
            //   articulosNoEncontrados.push(a.idArticulo);

            // Si no se encuentra el artículo, agregarlo a la lista de no encontrados por nombre
            articulosNoEncontrados.push(a.codigo);
          }
        }

        // Verificar si se encontraron artículos que no estaban en el mapa
        if (articulosNoEncontrados.length > 0) {
          const mensaje = `Los siguientes articulos no se encontraron: ${articulosNoEncontrados.join(
            ", "
          )}`;
          Swal.fire({
            title: "Artículos no encontrados",
            text: mensaje,
            icon: "warning",
            button: "Entendido",
          });
        }

        ActualizaTotales();
      };
    }
  }
  console.log("hola2", inputFields);

  const options = inventarios.map((option) => {
    const junta = option.codigo + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.traspasos_salida_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Traspaso Salida</h3>
          <Form onSubmit={saveTraspasosSalida}>
            <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Salida</Label>
                {user.areas == AREAS_GENERAL ? (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (a._id != AREAS_GENERAL) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                ) : (
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={areaSalida}
                    required
                    onChange={(e) => {
                      jalaAlmacenSalida(e.target.value);
                    }}
                  >
                    <option value="0">Almacen Salida</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.areas == a._id) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                )}
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Almacen Destino</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={areaDestino}
                  required
                  onChange={(e) => {
                    setAreaDestino(e.target.value);
                  }}
                >
                  <option value="0">Almacen Destino</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      if (a._id != AREAS_GENERAL) {
                        return <option value={a._id}>{a.name}</option>;
                      }
                    })}
                </Input>
              </Col>

              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>
            <br />
            <Row>
              {inventarios.length == 0 ? (
                <Col>
                  <Input type="file" name="file" id="file" disabled />
                </Col>
              ) : (
                <Col>
                  <Input
                    type="file"
                    name="file"
                    id="file"
                    onChange={(event) => importarArchivo(event)}
                  />
                </Col>
              )}
            </Row>
            {/* Tabla Articulos */}

            <Row>
              {/* <Col md={2}>
                <Label className="mr-sm-2">Codigo QR</Label>
              </Col> */}
              <Col md={3}>
                <Label className="mr-sm-2">Buscar</Label>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Modelo</Label>
              </Col>
              {/* {areaSalida == AREAS_CAMARONES || areaSalida == AREAS_PICAL44 || areaSalida == AREAS_PICAL57 || areaSalida == AREAS_PICAL_REFRI || areaSalida == AREAS_SOTANO ? (
                <Col md={1}>
                  <Label className="mr-sm-2">Pack</Label>
                </Col>
              ) : undefined} */}

              <Col md={1}>
                <Label className="mr-sm-2">Inventario</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas Por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    {/* <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosUnicos
                        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a.idArticulo}>
                             {a.codigo} {a.nombre} 
                            </option>
                          );
                        })}
                    </Input> */}
                    <Autocomplete
                      size="small"
                      value={inputField.articulos}
                      onChange={(event, selectedArticulo) => {
                        // setCodigo(selectedArticulo.codigo);
                        // setArticuloNombre(selectedArticulo.nombre);
                        BuscaArticulo(inputField.id, selectedArticulo);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
                  </Col>

                  {/* {areaSalida == AREAS_CAMARONES || areaSalida == AREAS_PICAL44 || areaSalida == AREAS_PICAL57 || areaSalida == AREAS_PICAL_REFRI || areaSalida == AREAS_SOTANO ? (                 
                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="pack"
                        type="select"
                        value={inputField.idInv}
                        required
                        onChange={(event) => {
                          BuscaPack(inputField.id, event.target.value);
                        }}
                      >
                        <option value="">Selecciona</option>
                        {inventarios
                          .sort((a, b) => (a.pack > b.pack ? 1 : -1))
                          .map((a) => {
                            if (a.idArticulo == inputField.articulos) {
                              return (
                                <option value={a._id}>{a.pack}</option>
                              );
                            }
                          })}
                      </Input>
                    </Col>
                  ) : undefined} */}
                  <Col md={3}>
                    <Input
                      name="articulo"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cantInv"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantInv}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cajas"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cajas"
                      value={inputField.cajas}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidadCajas(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Piezas por Caja"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      // bsSize="sm"
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <Row>
              <Col md={10}>
                <h4 id="logoutBoton">TOTALES {total} pzas.</h4>
              </Col>
            </Row>
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoTraspasosSalida"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosSalidaCreate;
