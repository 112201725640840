import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Get from "../../Get";

function ArticulosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PROPORCION = process.env.REACT_APP_URL_ARTICULOS_PROPORCION;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_COMPOSICIONES = process.env.REACT_APP_URL_COMPOSICIONES;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const AREAS_CEDIS = process.env.REACT_APP_AREAS_CEDIS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;

  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [pesoCaja, setPesoCaja] = useState(0);
  const [tamanoCaja, setTamanoCaja] = useState("NA");
  const [piezasPorCaja, setPiezasPorCaja] = useState(0);
  const [costoUSD, setCostoUSD] = useState(0);
  const [costoMX, setCostoMX] = useState(0);
  const [venta, setVenta] = useState(0);
  const [observaciones, setObservaciones] = useState("NA");
  const [lineas, setLineas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [composiciones, setComposiciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [ano, setAno] = useState("");
  const [EAN, setEAN] = useState("NA");
  const [vendedores, setVendedores] = useState("Si");
  const [selectedLinea, setSelectedLinea] = useState("");
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [selectedComposicion, setSelectedComposicion] = useState("62744b8e0b64170016800401");
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [colores, setColores] = useState([]);
  const [tallas, setTallas] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);

  const [modalTallas, setModalTallas] = useState(false);
  const toggleTallas = () => setModalTallas(!modalTallas);
  const [arrayTallas, setArrayTallas] = useState([]);

  const [amounts, setAmounts] = useState([]);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(12), (val, index) => index + year);

  useEffect(() => {
    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COMPOSICIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allComposiciones = res.data;
        setComposiciones(allComposiciones);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTallas = res.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function coloresSeleccionados(checked, color) {
    if (checked) {
      setArrayColores([...arrayColores, {id: color._id, name: color.name}]);
    } else {
      const values = [...arrayColores];
      const indexToRemove = values.findIndex(t => t.id === color._id);

      if (indexToRemove !== -1) {
        values.splice(indexToRemove, 1);
        setArrayColores(values);
      }
    }
  }

  function tallasSeleccionados(checked, talla) {
    if (checked) {
      setArrayTallas([...arrayTallas, {id: talla._id, name: talla.name}]);
    } else {
      const values = [...arrayTallas];
      const indexToRemove = values.findIndex(t => t.id === talla._id);

      if (indexToRemove !== -1) {
        values.splice(indexToRemove, 1);
        setArrayTallas(values);
      }
    }
  }
  

  const saveArticulos = async (event) => {
    event.preventDefault();
    setValidaBoton(false);

    const arrayTallasOfIds = arrayTallas.map(talla => talla.id)
    const arrayColoresOfIds = arrayColores.map(c => c.id)

let totalProporcion = amounts.length
    try {
      await axios
        .post(
          URL_ARTICULOS,
          {
            codigo,
            nombre,
            descripcion,
            pesoCaja,
            tamanoCaja,
            piezasPorCaja,
            costoUSD,
            costoMX,
            venta,
            observaciones,
            lineas: selectedLinea,
            marcas: selectedMarca,
            familias: selectedFamilia,
            colecciones: selectedColeccion,
            composiciones: selectedComposicion,
            proveedores: selectedProveedor,
            ano,
            EAN,
            vendedores,
            colores: arrayColoresOfIds,
            tallas: arrayTallasOfIds,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          axios
            .post(
              `${URL_INVENTARIOS}Alta`,
              {
                articulos: data.data._id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(() => {
              if(amounts.length > 0){
              amounts.map((a)=>{
                axios.post(
                  URL_ARTICULOS_PROPORCION,
                  {
                    articulos: data.data._id,
                    cantidad: a.amount,
                    colores: a.colorId,
                    tallas: a.sizeId
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                ).then(()=>{
                  totalProporcion = totalProporcion - 1
                  if(totalProporcion == 0){
                    axios.post(
                      URL_LOGS,
                      {
                        tipo: "Crear Modelo",
                        detalle: `${nombre} ${codigo}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  }
                  
                }).catch((error)=>{
                  console.log(error)
                  setValidaBoton(true)
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                })
              })
            } else{
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Modelo",
                  detalle: `${nombre} ${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
              setValidaBoton(true);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };


  const handleInputChange = (sizeId, colorId, value) => {
    const updatedAmounts = [...amounts];
    const existingEntryIndex = updatedAmounts.findIndex(entry => entry.sizeId === sizeId && entry.colorId === colorId);
  
    if (existingEntryIndex !== -1) {
      updatedAmounts[existingEntryIndex].amount = value;
    } else {
      updatedAmounts.push({ sizeId, colorId, amount: value });
    }
  
    setAmounts(updatedAmounts);
  };


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card container">
          <h3 align="center">Nuevo Modelo</h3>
          {/* <Get/> */}
          <Form onSubmit={saveArticulos}>
            <Row>
              <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Nombre</Label>
                <Input
                  type="text"
                  placeholder="Nombre"
                  value={nombre}
                  required
                  onChange={(e) => {
                    setNombre(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Descripción</Label>
                <Input
                  type="text"
                  placeholder="Descripción"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Piezas Por Caja</Label>
                <Input
                  type="number"
                  min="0"
                  step="any"
                  placeholder="Piezas Por Caja"
                  value={piezasPorCaja}
                  required
                  onChange={(e) => {
                    setPiezasPorCaja(e.target.value);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <Label>EAN</Label>
                <Input
                  type="text"
                  placeholder="EAN"
                  value={EAN}
                  required
                  onChange={(e) => {
                    setEAN(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>FOB USD</Label>
                <Input
                  type="text"
                  placeholder="FOB USD"
                  value={costoUSD}
                  required
                  onChange={(e) => {
                    setCostoUSD(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Costo MX</Label>
                <Input
                  type="text"
                  placeholder="Costo MX"
                  value={costoMX}
                  required
                  onChange={(e) => {
                    setCostoMX(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Venta</Label>
                <Input
                  type="text"
                  placeholder="Venta"
                  value={venta}
                  required
                  onChange={(e) => {
                    setVenta(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Observaciones</Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Linea</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedLinea}
                  required
                  onChange={(e) => {
                    setSelectedLinea(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Linea</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Marca</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedMarca}
                  required
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Marca</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Familia</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedFamilia}
                  required
                  onChange={(e) => {
                    setSelectedFamilia(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Familia</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Coleccion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColeccion}
                  required
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Coleccion</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label>Año</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={ano}
                  required
                  onChange={(e) => {
                    setAno(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Año</option>
                  {years.map((a) => {
                    return <option value={a}>{a}</option>;
                  })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Composicion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedComposicion}
                  required
                  onChange={(e) => {
                    setSelectedComposicion(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Composicion</option>
                  {composiciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              <Col md={3}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedor}
                  required
                  onChange={(e) => {
                    setSelectedProveedor(e.target.value);
                  }}
                >
                  <option value="0">Selecciona una Proveedor</option>
                  {proveedores
                    .sort((a, b) =>
                      a.nombre_comercial > b.nombre_comercial ? 1 : -1
                    )
                    .map((a) => {
                      return (
                        <option value={a._id}>{a.nombre_comercial}</option>
                      );
                    })}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Tamaño Caja</Label>
                <Input
                  type="text"
                  placeholder="Tamaño Caja"
                  value={tamanoCaja}
                  required
                  onChange={(e) => {
                    setTamanoCaja(e.target.value);
                  }}
                />
              </Col>

            <Col md={3}>
                <Label>Peso Caja</Label>
                <Input
                  type="number"
                  placeholder="Peso Caja"
                  value={pesoCaja}
                  required
                  onChange={(e) => {
                    setPesoCaja(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                <Label>Visible Vendedores</Label>
                <Input
                  type="select"
                  value={vendedores}
                  required
                  onChange={(e) => {
                    setVendedores(e.target.value);
                  }}
                >
                  <option value="No">No</option>
                  <option value="Si">Si</option>
                </Input>
              </Col>
            </Row>
            <br />
            <Row>
            <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Agregar Colores
                    </Label>
                    <br />
                    <Button
                      color="success"
                      id="Colores"
                      onClick={(e) => toggleColores()}
                    >
                      <i class="fas fa-palette fa-2x "></i>
                    </Button>
                  </Col>

                  <Col md={2}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Agregar Tallas
                    </Label>
                    <br />
                    <Button
                      color="success"
                      id="Tallas"
                      onClick={(e) => toggleTallas()}
                    >
                      <i class="fas fa-ruler-horizontal fa-2x "></i>
                    </Button>
                  </Col>
            </Row>
            <br />

            <Row>
            <div>
            <Label>Proporcion</Label>
            <Table>
            <thead>
                <tr>
                  <th></th>
                  {arrayColores.map(color => (
                    <th key={color.id}>{color.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {arrayTallas.map(size => (
                  <tr key={size.id}>
                    <td>{size.name}</td>
                    {arrayColores.map(color => {
                      const cellAmount = amounts.find(entry => entry.sizeId === size.id && entry.colorId === color.id)?.amount || 0;
                      return (
                        <td key={color.id}>
                          <input
                            type="number"
                            value={cellAmount}
                            onChange={(e) => handleInputChange(size.id, color.id, e.target.value)}
                            min="0"
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
            </Row>
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoArticulos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />

          <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
            <ModalHeader toggle={toggleColores}>
              <h4>Colores del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {colores
                .sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((a, index) => {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              coloresSeleccionados(
                                e.currentTarget.checked,
                                a
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                })}
            </ModalBody>
          </Modal>

          <Modal size="lg" isOpen={modalTallas} toggle={toggleTallas}>
            <ModalHeader toggle={toggleTallas}>
              <h4>Tallas del Modelo {nombre}</h4>
            </ModalHeader>
            <ModalBody>
              {tallas
                .sort((a, b) => (a.idTalla > b.idTalla ? 1 : -1))
                .map((a, index) => {
                    return (
                      <table>
                        <td>
                          <Input
                            type="checkbox"
                            key={index}
                            onChange={(e) => {
                              tallasSeleccionados(
                                e.currentTarget.checked,
                                a
                              );
                            }}
                            className="subMenu"
                          />
                          <h5 className="subMenuTitulo">{a.name}</h5>
                        </td>
                      </table>
                    );
                })}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosCreate;
