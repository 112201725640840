import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import axios from 'axios'

function ColaboradoresInfo(props) {
    const URL_COLABORADORES_INFO = `${process.env.REACT_APP_URL_COLABORADORES}/${props.idColaborador}`;
    const [colaborador, setColaborador] = useState([]);
  
    useEffect(() => {
      axios
        .get(URL_COLABORADORES_INFO, { 
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allColaborador = response.data
          setColaborador(allColaborador);
        })
        .catch((err) => {
          console.log(err);
        });
    },[]);

    return (
        <>
         <div className="content">
        <div className="card container">
           <Table size="sm" borderless>
             <tbody>
             <tr><td>Nombre</td><td>{colaborador.nombre} {colaborador.apellido}</td></tr>
             <tr><td>Telefono</td><td>{colaborador.telefono}</td></tr>
             <tr><td>Contacto Emergencia</td><td>{colaborador.contacto_emergencia}</td></tr>
                <tr><td>Telefono Emergencia</td><td>{colaborador.telefono_emergencia} </td></tr>
                <tr><td>Sueldo Actual</td><td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(colaborador.sueldo_actual)}</td></tr>
                <tr><td>Ultimo Aumento</td><td>{colaborador.ultimo_aumento}</td></tr>
                <tr><td>Sueldo Anterior</td><td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(colaborador.sueldo_anterior)}</td></tr>
                <tr><td>Banco</td><td>{colaborador.banco}</td></tr>
                <tr><td>Clabe</td><td>{colaborador.clabe}</td></tr>
                <tr><td>Direccion</td><td>{colaborador.direccion}</td></tr>

                </tbody> 
                </Table>
            </div>
            </div>
        </>
    )
}

export default ColaboradoresInfo
