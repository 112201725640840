import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import Header from "../../layout/Header/Header";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function DisenoAI() {
  const { user } = useContext(AuthContext);
  const URL_CHAT_GPT = process.env.REACT_APP_URL_CHAT_GPT;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;

  let hoy = new Date();
  const year = hoy.getFullYear();
  const years = Array.from(new Array(12), (val, index) => index + year);

  const [familias, setFamilias] = useState([]);
  const [selectedFamilia, setSelectedFamilia] = useState("");
  const [lineas, setLineas] = useState([]);
  const [selectedLinea, setSelectedLinea] = useState("");
  const [colecciones, setColecciones] = useState([]);
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [selectedAno, setSelectedAno] = useState(year);
  const [tendenciasPaginas, setTendenciasPaginas] = useState(true);
  const [tendenciasColores, setTendenciasColores] = useState(true);
  const [tendenciasMarcas, setTendenciasMarcas] = useState(true);
  const [pantones, setPantones] = useState("");
  const [instrucciones, setInstrucciones] = useState("");


  const [text, setText] = useState("");
  const [response, setResponse] = useState();

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useEffect(() => {
    axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allFamilias = res.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allLineas = res.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allMarcas = res.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function getResponse() {


    if(selectedFamilia == ""  || selectedLinea == "" || selectedColeccion == ""){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debes seleccionar una familia, una linea y una colección',
      })
      return;
    } 

    // Moncler, Banana Republic, Zara, Gucci, Ralph Lauren, Versace, YSL, Louis Vuitton, Uniqlo, H&M, Mango, GAP, Springfield, Cortefiel, Tommy Hilfiger.
    // ${tendenciasPaginas ? "las tendencias de moda en Vogue, WGSN, o Fashion Trend Setter " : ""}
    // ${tendenciasColores ? "Analiza las tendencias en colores " : ""}

    let busqueda = `
    1. Analiza ${tendenciasMarcas ? "las marcas Moncler, Banana Republic, Zara, Gucci, Ralph Lauren, Versace, YSL, Louis Vuitton, Uniqlo, H&M, Mango, GAP, Springfield, Cortefiel, Tommy Hilfiger para obtener" : ""}
    datos sobre las tendencias de moda para ${selectedLinea} 
    de la temporada ${selectedColeccion} ${selectedAno}, para ${selectedFamilia}. 
    2. Obten un analisis de las tendencias de moda.
    3. Una vez que tengas el análisis y utilizando esa información crea un prompt en inglés, para pedirle a Dalle-E 3 que genere un diseño para fabricar.
    ${pantones !== "" ? `Quiero que tambien ulilice los colores de los pantones ${pantones} y/o colores parecidos y algún otro para combinar.` : ""}
    ${instrucciones !== "" ? `Instrucciones Adicionales: ${instrucciones}` : ""}

    El prompr debe espeficiar que quiero obtener el diseno y las variantes de colores. No quiero nada futurista, tiene q ser 100% inspirado en prendas reales que utilizarian las personas, quiero que sean modelos que se puedan vender muy bien. 
    
    Responde exclusivamente con el prompt. Recuerda que Dall-E no puede analizar las tendencias de moda. Hay que proporcionarle toda la información necesaria para que genere las imágenes ideales.

    `;

     if (busqueda == text) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: "La busqueda no puede ser la misma que la anterior",
      });
      return;
    }

    setText(busqueda);

    setModalProgreso(true);
    setResponse();

    axios
      .post(
        `${URL_CHAT_GPT}image`,
        {
          text: busqueda,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((response) => {
        let aa = response.data.image.data

        let resp = response.data.image.data[0].b64_json;
        setResponse(resp);
        setModalProgreso(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            {/* <Col md={2}>
              <Label>Marca</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedMarca}
                required
                onChange={(e) => {
                  setSelectedMarca(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {marcas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a.name}>{a.name}</option>;
                  })}
              </Input>
            </Col> */}
            
            <Col md={2}>
              <Label className="mr-sm-2">Familia</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedFamilia}
                required
                onChange={(e) => {
                  setSelectedFamilia(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {familias
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a.name}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Linea</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedLinea}
                required
                onChange={(e) => {
                  setSelectedLinea(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {lineas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a.name}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Coleccion</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColeccion}
                required
                onChange={(e) => {
                  setSelectedColeccion(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                {colecciones
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    return <option value={a.name}>{a.name}</option>;
                  })}
              </Input>
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Año</Label>
              <Input
                type="select"
                value={selectedAno}
                onChange={(e) => {
                  setSelectedAno(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {years.map((a) => {
                  return <option value={a}>{a}</option>;
                })}
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
          <Col md={2}>
          <Label>Pantones</Label>
          <br />
          <Input 
          type="text"
          value={pantones}
          onChange={(e) => setPantones(e.target.value)}
          />
          </Col>

          <Col md={6}>
          <Label>Instrucciones Adicionales</Label>
          <Input
            type="text"
            value={instrucciones}
            onChange={(e) => setInstrucciones(e.target.value)}            
            />
          </Col>

          </Row>
          <br />
          <Row>
            {/* <Col md={2}>
            <Label>Tendencias en Moda</Label>
            <br />
          <Button color={tendenciasPaginas ? "success" : "danger"} onClick={() => setTendenciasPaginas(!tendenciasPaginas)} >
            {tendenciasPaginas ? "Sí" : "No"}
          </Button>
          </Col> */}

          <Col md={2}>
            <Label>Tendencias en Marcas</Label>
            <br />
          <Button color={tendenciasMarcas ? "success" : "danger"} onClick={() => setTendenciasMarcas(!tendenciasMarcas)} >
            {tendenciasMarcas ? "Sí" : "No"}
          </Button>
          </Col>

          {/* <Col md={2}>
            <Label>Tendencias en Colores</Label>
            <br />
          <Button color={tendenciasColores ? "success" : "danger"} onClick={() => setTendenciasColores(!tendenciasColores)} >
            {tendenciasColores ? "Sí" : "No"}
          </Button>
          </Col> */}

        
          </Row>
          <br />
          <Row>
            <Col md={2}>
              <Button
                className="btn"
                color="success"
                onClick={(e) => {
                  getResponse();
                }}
              >
                SistemifAI
              </Button>
            </Col>
          </Row>
          <Col md={12}>
            {/* <p> */}
            {response && (
              <img src={`data:image/png;base64,${response}`} alt="Generated" />
            )}
            {/* </p> */}
          </Col>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default DisenoAI;
