import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";


function AbonosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_ABONOS = process.env.REACT_APP_URL_ABONOS;
  const URL_SURTIDOS = process.env.REACT_APP_URL_SURTIDOS;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_NOTAS_CARGO = process.env.REACT_APP_URL_NOTAS_CARGO;
  const URL_SALDO_INICIAL = process.env.REACT_APP_URL_SALDO_INICIAL;
  const URL_MOVIMIENTOS_BANCOS = process.env.REACT_APP_URL_MOVIMIENTOS_BANCOS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_ESTADO_CUENTA = process.env.REACT_APP_URL_ESTADO_CUENTA;
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [text, setText] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteNombre, setSelectedClienteNombre] = useState("");
  const [selectedClienteCodigo, setSelectedClienteCodigo] = useState("");
  const [selectedClienteTelefono, setSelectedClienteTelefono] = useState("0");
  const [selectedClienteTelefono2, setSelectedClienteTelefono2] = useState("0");
  const [selectedClienteTelefono3, setSelectedClienteTelefono3] = useState("0");

  const [fecha, setFecha] = useState(hoy);
  const [alertasEdoCtaCliente, setAlertasEdoCtaCliente] = useState("No");

  const [selectedBanco, setSelectedBanco] = useState("");
  const [bancos, setBancos] = useState([]);
  const [formaPago, setFormaPago] = useState("Transfer");

  const [observaciones, setObservaciones] = useState("NA");

  const [value, setValue] = useState("");
  const [validaBoton, setValidaBoton] = useState(true);
  const [concepto, setConcepto] = useState("Abono");
  const [total, setTotal] = useState(0);
  const [importeAplicar, setImporteAplicar] = useState(0);
  const [saldoAplicar, setSaldoAplicar] = useState(0);
  const [codigoAgrupado, setCodigoAgrupado] = useState(uuidv4());
  const [moneda, setMoneda] = useState("");

  const [inputFields, setInputFields] = useState([]);
  const [inputFieldsNC, setInputFieldsNC] = useState([]);
  const [inputFieldsSI, setInputFieldsSI] = useState([]);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  useMemo(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allClientes = response.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });

  if(selectedCliente != ""){
        
    axios
      .get(`${URL_SURTIDOS}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargos = response.data;
        let arrayTabla = allCargos
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.total_general,
              saldo: a.saldo,
              nota: a.salidaBodega,
              importe: a.saldo,
              importeOriginal: a.saldo,
              clientes: a.clientes[0]._id,
              colaboradores: a.colaboradores[0]._id,
              observaciones: "NA",
              movimiento: "Nota",
              select:false,
              bonificacion:0,
              abonos: a.total_general - a.saldo,
              factura: a.noFactura,
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinal = Object.values(arrayTabla);
        setInputFields(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_NOTAS_CARGO}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosNC = response.data;
        let arrayTablaNC = allCargosNC
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.importe,
              saldo: a.saldo,
              nota: a.idNotaCargo,
              importe: a.saldo,
              importeOriginal: a.saldo,
              clientes: a.clientes[0]._id,
              colaboradores: a.clientes[0].vendedor[0]._id,
              observaciones: "NA",
              movimiento: "Nota de Cargo",
              select:false,
              bonificacion:0,
              abonos: a.importe - a.saldo,
              factura:"NA"
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinalNC = Object.values(arrayTablaNC);
        setInputFieldsNC(dataFinalNC);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_SALDO_INICIAL}Cliente/${selectedCliente}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCargosSI = response.data;
        let arrayTablaSI = allCargosSI
          .map((a) => {
            return {
              id: a._id,
              fecha: a.fecha,
              total_general: a.importe,
              saldo: a.saldo,
              nota: a.idSaldoInicial,
              importe: a.saldo,
              importeOriginal: a.saldo,
              clientes: a.clientes[0]._id,
              colaboradores: a.clientes[0].vendedor[0]._id,
              observaciones: "NA",
              movimiento: "Salldo Inicial",
              select:false,
              bonificacion:0,
              abonos: a.importe - a.saldo,
              factura:"NA"
            };
          })
          .filter(function (el) {
            return el != null;
          });
        let dataFinalSI = Object.values(arrayTablaSI);
        setInputFieldsSI(dataFinalSI);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [selectedCliente]);

  const handleChangeInputImporte = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo) {
          i.importe = value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo o no puede quedar vacio",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputBonificacion = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo) {
          i.bonificacion = value;
          i.importe = i.importeOriginal - value
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo o no puede quedar vacio",
          });
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputBonificacionNC = (value, name, id) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo) {
          i.bonificacion = value;
          i.importe = i.importeOriginal - value
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo o no puede quedar vacio",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  const handleChangeInputImporteNC = (value, name, id) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo) {
          i.importe = value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo o no puede quedar vacio",
          });
        }
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
    ActualizaTotales();
  };

  
  const handleChangeInputBonificacionSI = (value, name, id) => {
    const newInputFieldsSI = inputFieldsSI.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo) {
          i.bonificacion = value;
          i.importe = i.importeOriginal - value
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo o no puede quedar vacio",
          });
        }
      }
      return i;
    });
    setInputFieldsSI(newInputFieldsSI);
    ActualizaTotales();
  };

  const handleChangeInputImporteSI = (value, name, id) => {
    const newInputFieldsSI = inputFieldsSI.map((i) => {
      if (id === i.id) {
        if (value <= i.saldo) {
          i.importe = value;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El Pago excede el Saldo o no puede quedar vacio",
          });
        }
      }
      return i;
    });
    setInputFieldsSI(newInputFieldsSI);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let activos = inputFields.filter((e) => e.select == true);
    let TEfe = activos.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    let activosNC = inputFieldsNC.filter((e) => e.select == true);
    let TEfeNC = activosNC.map((c) => parseFloat(c.importe));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);

    let activosSI = inputFieldsSI.filter((e) => e.select == true);
    let TEfeSI = activosSI.map((c) => parseFloat(c.importe));
    let TESI = TEfeSI.reduce((t, total, index) => t + total, 0);

    let TF = parseFloat(TE).toFixed(2)
    let TFNC = parseFloat(TENC).toFixed(2)
    let TFSI = parseFloat(TESI).toFixed(2)

    let sum = (parseFloat(TF) + parseFloat(TFNC) + parseFloat(TFSI)).toFixed(2)

    setTotal(sum);

    setSaldoAplicar((importeAplicar - sum).toFixed(2));
  }

  function savePago() {
    setValidaBoton(false);
    let red = parseFloat(importeAplicar).toFixed(2)
    if (
      value != null &&
      concepto != "" &&
      formaPago != "" &&
      selectedBanco != ""
    ) {
      if (
        // saldoAplicar == 0 && 
        red == total) {
        Swal.fire({
          title: "Estas seguro?",
          text: "Se registrará el abono",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Enviar!",
        }).then((result) => {
          if (result.isConfirmed) {
            toggleProgreso();

            let activos = inputFields.filter((e) => e.select == true);
            let activosNC = inputFieldsNC.filter((e) => e.select == true);
            let activosSI = inputFieldsSI.filter((e) => e.select == true);

            let totalMails = (activos.length + activosNC.length + activosSI.length)*2
            inputFields.map((a) => {
              if (a.select == true) {
                if( a.importe > 0){
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: parseFloat(a.importe),
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      surtidos: a.id,
                      formaPago,
                      concepto,
                      bancos: selectedBanco,
                      comisionPagada: "No",
                      // observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_SURTIDOS}/${a.id}`,
                      {
                        saldo: a.saldo - a.importe - a.bonificacion,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.importe,
                        tipo:"Ingreso",
                        bancos: selectedBanco,
                        abonos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      if(alertasEdoCtaCliente == "Si"){
                      PDFOC();
                      }
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
                } else {
                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      if(alertasEdoCtaCliente == "Si"){
                        PDFOC();
                        }
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  }
              // Termina el if importe >0

                 if (a.bonificacion > 0){
                  
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: parseFloat(a.bonificacion),
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      surtidos: a.id,
                      formaPago,
                      concepto:"Bonificacion",
                      bancos: "6255c1bb98f8a71d7414a110",
                      comisionPagada: "No",
                      // observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_SURTIDOS}/${a.id}`,
                      {
                        saldo: a.saldo - a.importe - a.bonificacion,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.bonificacion,
                        tipo:"Ingreso",
                        bancos: "6255c1bb98f8a71d7414a110",
                        abonos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      if(alertasEdoCtaCliente == "Si"){
                      PDFOC();
                      }
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
                } else {
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    if(alertasEdoCtaCliente == "Si"){
                      PDFOC();
                      }
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  }
                }
              } 
              // else {
              //   totalMails = totalMails - 1;
              //   if (totalMails == 0) {
              //     if(alertasEdoCtaCliente == "Si"){
              //       PDFOC();
              //       }
              //     Swal.fire("Good job!", "Creado con exito", "success");
              //     setTimeout(() => {
              //       window.location.reload();
              //     }, 3000);
              //   }
              // }
            });

            // Notas Cargo
            inputFieldsNC.map((a) => {
              if (a.select == true ) {
                if(a.importe > 0){
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: a.importe,
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      notasCargo: a.id,
                      formaPago,
                      concepto,
                      bancos: selectedBanco,
                      comisionPagada: "No",
                      // observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_NOTAS_CARGO}AplicarAbono/${a.id}`,
                      {
                        saldo: a.saldo - a.importe - a.bonificacion,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.importe,
                        tipo:"Ingreso",
                        bancos: selectedBanco,
                        abonos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      if(alertasEdoCtaCliente == "Si"){
                        PDFOC();
                        }
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
                } else {
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    if(alertasEdoCtaCliente == "Si"){
                      PDFOC();
                      }
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  }
                }
               if ( a.bonificacion > 0) {
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: a.bonificacion,
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      notasCargo: a.id,
                      formaPago,
                      concepto:"Bonificacion",
                      bancos: "6255c1bb98f8a71d7414a110",
                      comisionPagada: "No",
                      // observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_NOTAS_CARGO}AplicarAbono/${a.id}`,
                      {
                        saldo: a.saldo - a.importe - a.bonificacion,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.bonificacion,
                        tipo:"Ingreso",
                        bancos: "6255c1bb98f8a71d7414a110",
                        abonos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      if(alertasEdoCtaCliente == "Si"){
                        PDFOC();
                        }
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              } else {
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  if(alertasEdoCtaCliente == "Si"){
                    PDFOC();
                    }
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
              }
            }  
            // else {
            //     totalMails = totalMails - 1;
            //     if (totalMails == 0) {
            //       if(alertasEdoCtaCliente == "Si"){
            //         PDFOC();
            //         }
            //       Swal.fire("Good job!", "Creado con exito", "success");
            //       setTimeout(() => {
            //         window.location.reload();
            //       }, 3000);
            //     }
            //   }
            });

            // Saldo Inicial
            inputFieldsSI.map((a) => {
              if (a.select == true ) {
                if(a.importe > 0){
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: a.importe,
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      saldoInicial: a.id,
                      formaPago,
                      concepto,
                      bancos: selectedBanco,
                      comisionPagada: "No",
                      // observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_SALDO_INICIAL}AplicarAbono/${a.id}`,
                      {
                        saldo: a.saldo - a.importe - a.bonificacion,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.importe,
                        tipo:"Ingreso",
                        bancos: selectedBanco,
                        abonos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      if(alertasEdoCtaCliente == "Si"){
                        PDFOC();
                        }
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
                } else {
                  totalMails = totalMails - 1;
                  if (totalMails == 0) {
                    if(alertasEdoCtaCliente == "Si"){
                      PDFOC();
                      }
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  }
                }
               if ( a.bonificacion > 0) {
                axios
                  .post(
                    URL_ABONOS,
                    {
                      fecha,
                      importe: a.bonificacion,
                      clientes: selectedCliente,
                      colaboradores: a.colaboradores,
                      saldoInicial: a.id,
                      formaPago,
                      concepto:"Bonificacion",
                      bancos: "6255c1bb98f8a71d7414a110",
                      comisionPagada: "No",
                      // observaciones: a.observaciones,
                      codigoAgrupado,
                      editado:"No",
                      observaciones
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then((data) => {
                    axios.patch(
                      `${URL_SALDO_INICIAL}AplicarAbono/${a.id}`,
                      {
                        saldo: a.saldo - a.importe - a.bonificacion,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    );

                    axios
                    .post(
                      `${URL_MOVIMIENTOS_BANCOS}`,
                      {
                        fecha,
                        importe: a.bonificacion,
                        tipo:"Ingreso",
                        bancos: "6255c1bb98f8a71d7414a110",
                        abonos:data.data._id,
                        moneda,
                        tipoCambio:1
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )

                    totalMails = totalMails - 1;
                    if (totalMails == 0) {
                      if(alertasEdoCtaCliente == "Si"){
                        PDFOC();
                        }
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 3000);
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      // footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              } else {
                totalMails = totalMails - 1;
                if (totalMails == 0) {
                  if(alertasEdoCtaCliente == "Si"){
                    PDFOC();
                    }
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
              }
            }  
            // else {
            //     totalMails = totalMails - 1;
            //     if (totalMails == 0) {
            //       if(alertasEdoCtaCliente == "Si"){
            //         PDFOC();
            //         }
            //       Swal.fire("Good job!", "Creado con exito", "success");
            //       setTimeout(() => {
            //         window.location.reload();
            //       }, 3000);
            //     }
            //   }
            });
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Todavia te queda Saldo por aplicar",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Debes completar todo el formulario",
      });
    }
  }

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };

  const handleChangeInputNC = (id, event) => {
    const newInputFieldsNC = inputFieldsNC.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsNC(newInputFieldsNC);
  };

  const handleChangeInputSI = (id, event) => {
    const newInputFieldsSI = inputFieldsSI.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsSI(newInputFieldsSI);
  };

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function IA(e) {
    setImporteAplicar(e);
    let activos = inputFields.filter((e) => e.select == true);
    let TEfe = activos.map((c) => parseFloat(c.importe));
    let TE = TEfe.reduce((t, total, index) => t + total, 0);

    let activosNC = inputFieldsNC.filter((e) => e.select == true);
    let TEfeNC = activosNC.map((c) => parseFloat(c.importe));
    let TENC = TEfeNC.reduce((t, total, index) => t + total, 0);

    let activosSI = inputFieldsSI.filter((e) => e.select == true);
    let TEfeSI = activosSI.map((c) => parseFloat(c.importe));
    let TESI = TEfeSI.reduce((t, total, index) => t + total, 0);

    let TF = parseFloat(TE).toFixed(2)
    let TFNC = parseFloat(TENC).toFixed(2)
    let TFSI = parseFloat(TESI).toFixed(2)

    let sum = (parseFloat(TF) + parseFloat(TFNC) + parseFloat(TFSI)).toFixed(2)

    setTotal(sum);

    setSaldoAplicar((e - sum).toFixed(2));
  }

  function jalaBanco(e) {
    setSelectedBanco(e);
    bancos.map((a) => {
      if (a._id == e) {
        return setMoneda(a.moneda);
      }
    });
  }

 async function PDFOC() {
  let saldoParcial = 0;
  let totalCargosEdoCte = 0;
  let totalAbonosEdoCte = 0;
  let totalSaldoEdoCte = 0;
    await axios
    .get(`${URL_ESTADO_CUENTA}/${selectedCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then(async (response) => {
      let movimientos = response.data;

      await axios
      .get(`${URL_DASHBOARDS}AntiguedadSaldosVencidoEdoCta/${selectedCliente}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allAntSaldos = response.data;

      let totalTreinta = 0;
      let totalSesenta = 0;
      let totalNoventa = 0;
      let totalMasNoventa = 0;
      let totalEnTiempo = 0;

      const dataAS = allAntSaldos.map((a) => {
        totalTreinta += isFinite(a.treinta) ? a.treinta : 0;
        totalSesenta += isFinite(a.sesenta) ? a.sesenta : 0;
        totalNoventa += isFinite(a.noventa) ? a.noventa : 0;
        totalMasNoventa += isFinite(a.masnoventa) ? a.masnoventa : 0;
        totalEnTiempo += isFinite(a.enTiempo) ? a.enTiempo : 0;
        const fechaVencimientoDate = new Date(a.fechaVencimiento);

        const fechaVencimientoFormatted = fechaVencimientoDate.toLocaleDateString('en-GB');
      
        const treintaFormatted = isFinite(a.treinta) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.treinta) : '';
        const sesentaFormatted = isFinite(a.sesenta) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.sesenta) : '';
        const noventaFormatted = isFinite(a.noventa) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.noventa) : '';
        const masnoventaFormatted = isFinite(a.masnoventa) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.masnoventa) : '';
        const enTiempoFormatted = isFinite(a.enTiempo) ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(a.enTiempo) : '';
      
        return [
          a.folio,
          a.fecha,
          fechaVencimientoFormatted,
          treintaFormatted,
          sesentaFormatted,
          noventaFormatted,
          masnoventaFormatted,
          enTiempoFormatted
        ];
      });
      const dataPDFAS = Object.values(dataAS);
      const dataPDFLimpiaAS = dataPDFAS.filter(function (el) {
        return el != null;
      });

      const totalVencido = totalTreinta + totalSesenta + totalNoventa + totalMasNoventa;

    const data = movimientos.map((a) => {
        totalCargosEdoCte = totalCargosEdoCte + a.cargo;
        totalAbonosEdoCte = totalAbonosEdoCte + a.abono;
        totalSaldoEdoCte = totalCargosEdoCte - totalAbonosEdoCte;
        saldoParcial = saldoParcial + a.cargo - a.abono;
        const cargos = a.cargo > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.cargo): '';
        const abonos = a.abono > 0 ? new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abono): '';
        var saldoParcialFormato =
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial);
        return [
          a.numero,
          a.fecha,
          a.movimiento,
          cargos,
          abonos,
          saldoParcialFormato,
          a.observaciones
        ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const columnStyles = [
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'}, //Cargos - Negro
      { fontSize: 9,textColor: ["#f40903"] ,halign: 'right'}, // Abonos - Rojo
      { fontSize: 9,textColor: ["#030af4"] ,halign: 'right'}, // Saldos - Azul a favor
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
  ];

    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    var img2 = new Image();
    const backgroundColor = ["#d1d2d4"];
    const backgroundColorBoxBlack = ["#231f20"];
    const backgroundColorBoxPurple = ["#4d2363"];
    const margins = {  left: 5, right: 5 };

    img.src = process.env.PUBLIC_URL + "logo-crembo.jpg";
    doc.addImage(img, "jpg", 9, 12, 53, 12);

    img.src = process.env.PUBLIC_URL + "marcas.jpg";
    doc.addImage(img, "jpg", 9, 28, 192, 10);
    doc.setFontSize(10);
    
    doc.setFillColor(...backgroundColor);
    doc.rect(5, 40, 200, 6, 'F');
    doc.setTextColor("#000000");
    doc.text(`Tel: 55 2454 9626`,8, 44);

    doc.setTextColor("#000000");
    doc.text(`Cliente: ${selectedClienteNombre}`,8, 52)
    doc.text(`Clave: ${selectedClienteCodigo}`,176, 52)

    doc.setTextColor("#4d2363");
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(`crembo.mx`,175, 44);
    doc.setTextColor("#000000");

    doc.setFontSize(14);
    doc.setFillColor(...backgroundColorBoxBlack);
    doc.rect(100, 4, 105, 22, 'F');
    doc.setFillColor(...backgroundColorBoxPurple);
    doc.rect(74, 4, 25, 22, 'F');
    doc.setTextColor("#ffffff");
    doc.text(`Estado de Cuenta`,130, 15);
    doc.autoTable({
      head: [
        [
          "FOLIO",
          "FECHA",
          "MOVIMIENTO",
          "CARGOS",
          "ABONOS",
          "SALDO",
          "OBSERVACIONES"
        ],
      ],
      body: dataPDFLimpia,
      startY: 55,
      headStyles: {
        // fillColor: ["#723C85"],
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      footStyles: {
        // fillColor: ["#723C85"],
        fillColor: ["#4d2363"],
      },
      columnStyles: columnStyles,
      margin: margins,
      theme:"grid",
    });

    const columnStylesAntiguedad = [
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'center'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
      { fontSize: 9,textColor: ["#000000"] ,halign: 'right'},
  ];

    doc.addPage()
    doc.setFillColor(...backgroundColorBoxPurple);
    doc.rect(8, 8, 70, 10, 'F');
    doc.setFontSize(14);
    doc.setTextColor("#ffffff");
    doc.text(`ANTIGUEDAD DE SALDOS`,10, 15);
    doc.autoTable({
      head: [
        [
          "FOLIO",
          "FECHA NOTA",
          "FECHA DE VENCIMIENTO",
          "1 A 30 DIAS",
          "31 A 60 DIAS",
          "61 A 90 DIAS",
          "+ 90 DIAS",
          "EN TIEMPO",
        ],
      ],
      body: dataPDFLimpiaAS,
      startY: 20,
      headStyles: {
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      footStyles: {
        fillColor: ["#4d2363"],
      },
      columnStyles:columnStylesAntiguedad,
      foot: [
        [
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTreinta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalSesenta),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalNoventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalMasNoventa),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalEnTiempo),
        ]
      ],
      showFoot: "lastPage",
      margin: margins,
      theme:"grid",
    });

    
let totalVencidos = totalTreinta + totalSesenta + totalNoventa + totalMasNoventa;
let totalResumen = totalVencidos + totalEnTiempo;

// Asumimos que totalVencidos y totalEnTiempo ya están calculados correctamente como sumas de montos.
let porcentajeVencidos = totalResumen > 0 ? (totalVencidos / totalResumen) : 0;
let porcentajeAtiempo = totalResumen > 0 ? (totalEnTiempo / totalResumen) : 0;

const resumenData = [
  ["Vencidos",
    new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalVencidos),
    new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(porcentajeVencidos)],
  ["Al corriente",
    new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalEnTiempo),
    new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(porcentajeAtiempo)],
  ["Total",
    new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalResumen),
    new Intl.NumberFormat("en-US", {style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(1)]
];

    const columnStylesResumen = [
      { fontSize: 9,textColor: ["#FFFFFF"] ,fillColor: ["#4d2363"],halign: 'center'},
      { fontSize: 9,textColor: ["#000000"],halign: 'right'},
      { fontSize: 9,textColor: ["#000000"],halign: 'center'},
  ];

    doc.autoTable({
      head: [
        [
          "",
          "RESUMEN DE SALDOS",
          ""
        ]
      ],
      body: resumenData,
      startY: doc.autoTable.previous.finalY + 10,
      headStyles: {
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      columnStyles:columnStylesResumen,
      showFoot: "lastPage",
      margin:  {  left: 5, right: 120 },
      theme:"grid",
    });


    var attW = doc.output("arraybuffer");
    var base64File = encode(attW);

    if(selectedClienteTelefono !='' && selectedClienteTelefono != "0" && selectedClienteTelefono != 0 && selectedClienteTelefono.length >= 12){
    axios
    .post(
      `${URL_WHATSAPP}SendPlantillaAbono`,
      {
        number: selectedClienteTelefono,
        cliente: selectedClienteNombre,
        importe: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total),
        saldo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
        base64File,
        fileName: `EstadoCuenta-${selectedClienteNombre}.pdf`,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    }

    if(selectedClienteTelefono2 !='' && selectedClienteTelefono2 != "0" && selectedClienteTelefono2 != 0 && selectedClienteTelefono2.length >= 12){
      axios
      .post(
        `${URL_WHATSAPP}SendPlantillaAbono`,
        {
          number: selectedClienteTelefono2,
          cliente: selectedClienteNombre,
          importe: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total),
          saldo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
          base64File,
          fileName: `EstadoCuenta-${selectedClienteNombre}.pdf`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      }

      if(selectedClienteTelefono3 !='' && selectedClienteTelefono3 != "0" && selectedClienteTelefono3 != 0 && selectedClienteTelefono3.length >= 12){
        axios
        .post(
          `${URL_WHATSAPP}SendPlantillaAbono`,
          {
            number: selectedClienteTelefono3,
            cliente: selectedClienteNombre,
            importe: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total),
            saldo: new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(saldoParcial),
            base64File,
            fileName: `EstadoCuenta-${selectedClienteNombre}.pdf`,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        }

  })
  .catch((err) => {
    console.log(err);
  });

})
.catch((err) => {
  console.log(err);
});

  }

  function countOfEachColumn(arr) {
    const columnCounts = [];
  
    for (let j = 2; j < arr[0].length; j++) {
      let columnCount = 0;
  
      for (let i = 0; i < arr.length; i++) {
        const currentValue = arr[i][j];
        if (currentValue > '') {
        columnCount += 1;
        }
      }
  
      columnCounts.push(columnCount);
    }
  
    return columnCounts ;
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosCreate ? (
        <div className="card container col-sm-11">
          <h3 align="center">Captura un Pago</h3>
          <Form>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      if (selectedCliente) {
                        setValue(selectedCliente);
                        setSelectedCliente(selectedCliente._id);
                        setSelectedClienteNombre(selectedCliente.nombre_comercial);
                        setSelectedClienteCodigo(selectedCliente.codigo);
                        setSelectedClienteTelefono(selectedCliente.telefono);
                        setSelectedClienteTelefono2(selectedCliente.telefono2);
                        setSelectedClienteTelefono3(selectedCliente.telefono3);
                        setAlertasEdoCtaCliente(selectedCliente.alertasEdoCta);
                      }
                    }}
                    options={options.sort(
                      (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                    )}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecciona"
                        variant="outlined"
                      />
                    )}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Concepto</Label>
                  <Input
                    type="select"
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Concepto</option>
                    <option value="Abono">Abono</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Forma de Pago</Label>
                  <Input
                    type="select"
                    value={formaPago}
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="">Selecciona una Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transfer">Transfer</option>
                    <option value="TDC">TDC</option>
                    <option value="Bonificacion">Bonificacion</option>
                  </Input>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      jalaBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );
                      })}
                  </Input>
                </Col>

                <Col md={1}>
                  <h4 align="right">
                    Total Pago{"  "}
                    <Badge
                      id="Total"
                      color="danger"
                      className="BadgeSize badge-pill"
                    >
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                    </Badge>
                  </h4>
                </Col>
              </Row>
              <Row>
              <Col md={5}>
                  <Label className="mr-sm-2">Observaciones</Label>
                  <Input
                    type="text"
                    value={observaciones}
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  />
                </Col>

                </Row>
              <br />
              <Row>
                <Col md={2}>
                  <Label>Importe Por Aplicar</Label>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={importeAplicar}
                    className={`form-control`}
                    onValueChange={(value, name) => IA(value)}
                  />
                </Col>
                <Col md={2}>
                  <Label>Saldo Por Aplicar</Label>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={saldoAplicar}
                    className={`form-control`}
                    disabled
                  />
                </Col>
              </Row>
              <br />
              <Row>
                {/* Tabla Cargos */}
                <br />
                <br />
                <Table striped borderless>
                  <thead>
                    <tr>
                    <th></th>
                      <th>Fecha</th>
                      <th>Movimiento</th>
                      <th>Nota</th>
                      <th>Factura</th>
                      <th>Total</th>
                      <th>Abonos</th>
                      <th>Bonificacion</th>
                      <th>Saldo</th>
                      <th>Importe</th>
                      {/* <th>Observaciones</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {inputFields.map((a) => (
                      // <div key={a.id}>
                      <tr>
                         <th>
                        <Input
                              type="checkbox"
                              onChange={e => {
                                let checked = e.target.checked;
                                setInputFields(
                                  inputFields.map(data => {
                                    if(a.id == data.id){
                                    data.select = checked;
                                    } 
                                    return data;
                                  })
                                );
                                ActualizaTotales()
                              }}
                              checked={a.select}
                            />
                        </th>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>{a.factura}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos)}
                        </td>
                        <td>{a.select ?(
                        <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="bonificacion"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.bonificacion}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputBonificacion(value, name, a.id)
                            }
                          />
                          ):(
                            <CurrencyInput
                                intlConfig={{ locale: "en-US", currency: "USD" }}
                                prefix="$"
                                name="bonificacion"
                                defaultValue={0}
                                decimalsLimit={2}
                                value={a.bonificacion}
                                className={`form-control`}
                                disabled
                              />
                              )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        <td>{a.select ?(
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.importe}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputImporte(value, name, a.id)
                            }
                          />
                          ):(
                            <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              disabled
                            />
                            )}
                        </td>
                        {/* <td>
                          <Input
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInput(a.id, e);
                            }}
                          />
                        </td> */}
                      </tr>
                      // </div>
                    ))}

                    {inputFieldsNC.map((a) => (
                      // <div key={a.id}>
                      <tr>
                      <th>
                     <Input
                           type="checkbox"
                           onChange={e => {
                             let checked = e.target.checked;
                             setInputFieldsNC(
                               inputFieldsNC.map(data => {
                                 if(a.id == data.id){
                                 data.select = checked;
                                 } 
                                 return data;
                               })
                             );
                             ActualizaTotales()
                           }}
                           checked={a.select}
                         />
                     </th>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>{a.factura}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos)}
                        </td>
                        <td>
                          {a.select?(
                        <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="bonificacion"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.bonificacion}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputBonificacionNC(value, name, a.id)
                            }
                          />):(
                            <CurrencyInput
                                intlConfig={{ locale: "en-US", currency: "USD" }}
                                prefix="$"
                                name="bonificacion"
                                defaultValue={0}
                                decimalsLimit={2}
                                value={a.bonificacion}
                                className={`form-control`}
                                disabled
                              />)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        <td>
                          {a.select?(
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.importe}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputImporteNC(value, name, a.id)
                            }
                          />):(
                            <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              disabled
                            />)}
                        </td>
                        {/* <td>
                          <Input
                            bsSize="sm"
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputNC(a.id, e);
                            }}
                          />
                        </td> */}
                      </tr>
                      // </div>
                    ))}

                    {inputFieldsSI.map((a) => (
                      // <div key={a.id}>
                      <tr>
                      <th>
                     <Input
                           type="checkbox"
                           onChange={e => {
                             let checked = e.target.checked;
                             setInputFieldsSI(
                               inputFieldsSI.map(data => {
                                 if(a.id == data.id){
                                 data.select = checked;
                                 } 
                                 return data;
                               })
                             );
                             ActualizaTotales()
                           }}
                           checked={a.select}
                         />
                     </th>
                        <td>{a.fecha}</td>
                        <td>{a.movimiento}</td>
                        <td>{a.nota}</td>
                        <td>{a.factura}</td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(
                              a.total_general
                            )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.abonos)}
                        </td>
                        <td>
                          {a.select?(
                        <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="bonificacion"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.bonificacion}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputBonificacionSI(value, name, a.id)
                            }
                          />):(
                            <CurrencyInput
                                intlConfig={{ locale: "en-US", currency: "USD" }}
                                prefix="$"
                                name="bonificacion"
                                defaultValue={0}
                                decimalsLimit={2}
                                value={a.bonificacion}
                                className={`form-control`}
                                disabled
                              />)}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.saldo)}
                        </td>
                        <td>
                          {a.select?(
                          <CurrencyInput
                            intlConfig={{ locale: "en-US", currency: "USD" }}
                            prefix="$"
                            name="importe"
                            defaultValue={0}
                            decimalsLimit={2}
                            value={a.importe}
                            className={`form-control`}
                            onValueChange={(value, name) =>
                              handleChangeInputImporteSI(value, name, a.id)
                            }
                          />):(
                            <CurrencyInput
                              intlConfig={{ locale: "en-US", currency: "USD" }}
                              prefix="$"
                              name="importe"
                              defaultValue={0}
                              decimalsLimit={2}
                              value={a.importe}
                              className={`form-control`}
                              disabled
                            />)}
                        </td>
                        {/* <td>
                          <Input
                            bsSize="sm"
                            type="text"
                            name="observaciones"
                            value={a.observaciones}
                            key={a.id}
                            onChange={(e) => {
                              handleChangeInputSI(a.id, e);
                            }}
                          />
                        </td> */}
                      </tr>
                      // </div>
                    ))}

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="negrita" align="center">
                        Total
                      </td>
                      <td className="negrita">
                        {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                  <br />
                </Table>

                {/* Termina tabla cargos */}
              </Row>
            </FormGroup>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/ListadoAbonos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      
    </>
  );
}

export default AbonosCreate;
