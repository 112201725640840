import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";

function TraspasosDineroCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TRASPASOS_DINERO = process.env.REACT_APP_URL_TRASPASOS_DINERO;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [fecha, setFecha] = useState(hoy);
  const [importe, setImporte] = useState(0);
  const [entrada, setEntrada] = useState("No");

  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");

  useMemo(() => {
    setSelectedArea(user.areas)

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

  }, [user]);

  const saveTraspaso = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .all([
        axios.post(
          URL_TRASPASOS_DINERO,
          {
            fecha,
            importe,
            entrada,
            areas: selectedArea,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        ),
      ])
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Traspaso Dinero",
            detalle: `Fecha: ${fecha} / Tienda: ${selectedArea} / Importe: ${importe} `,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
        setValidaBoton(true);
      });
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.gastosTiendas ? (
        <div className="container">
          <div className="card container col-sm-6">
            <h3 align="center">Nuevo Traspaso de Dinero</h3>
            <Form onSubmit={saveTraspaso}>
              <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                <Row>

                <Col md={6}>
              <Label>Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (user.areas != AREAS_GENERAL) {
                          if(a._id == user.areas){
                          return <option value={a._id}>{a.name}</option>
                        }
                        }else{
                          if(a._id != AREAS_GENERAL){
                            return <option value={a._id}>{a.name}</option>
                          }
                        }
                      })}
              </Input>
            </Col>
          
                  <Col sm={6}>
                    <Label for="exampleEmail" className="mr-sm-2">
                      Importe
                    </Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Importe"
                      value={importe}
                      required
                      onChange={(e) => setImporte(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <br />
              <Col>
                <Row>
                  {validaBoton ? (
                    <Button type="submit" className="btn btn-success">
                      Guardar
                    </Button>
                  ) : (
                    <Button type="submit" className="btn btn-success" disabled>
                      Guardar
                    </Button>
                  )}
                  <div>
                    <SweetAlert
                      show={text}
                      title="Creado con Exito"
                      onConfirm={() => {
                        setText(false);
                      }}
                    />
                  </div>
                  <Button
                    href="/MenuTiendas"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Row>
              </Col>
            </Form>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default TraspasosDineroCreate;
