import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../Baja";
import { TableHeader, Pagination, Search } from "../../DataTable";
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { func } from "prop-types";

function ValorInventario() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_LINEAS = process.env.REACT_APP_URL_LINEAS;
  const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_FAMILIAS = process.env.REACT_APP_URL_FAMILIAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [lineas, setLineas] = useState([]);
  const [selectedLineaFiltro, setSelectedLineaFiltro] = useState("");
  const [marcas, setMarcas] = useState([]);
  const [selectedMarca, setSelectedMarca] = useState("");
  const [familias, setFamilias] = useState([]);
  const [selectedFamiliaFiltro, setSelectedFamiliaFiltro] = useState("");
  const [colecciones, setColecciones] = useState([]);
  const [selectedColeccion, setSelectedColeccion] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  
  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const [idEditArticulo, setIdEditArticulo] = useState("");
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [totalTotal, setTotalTotal] = useState(0);
  const [totalTotalValor, setTotalTotalValor] = useState(0);
  const [totalTotalCajas, setTotalTotalCajas] = useState(0);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(12), (val, index) => index + year);

  useMemo(() => {
    axios
      .get(`${URL_INVENTARIOS}AcumuladoAdmin`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInventarios = response.data;
        // Array para pagination
        let arrayTabla = allInventarios
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            if(a.cantidad >0){
            return {
              _id: a._id,
              activo: a.activo,
              cantidad: a.cantidad,
              codigo: a.codigo,
              nombre: a.nombre,
              idArticulo: a.idArticulo,
              costoUSD: a.costoUSD,
              costoMX: a.costoMX,
              venta: a.venta,
              observaciones: a.observaciones,
              idCompras: a.idCompras,
              piezasPorCaja: a.piezasPorCaja,
              linea: a.linea,
              idLinea: a.idLinea,
              marca: a.marca,
              idMarca: a.idMarca,
              familia: a.familia,
              idFamilia: a.idFamilia,
              coleccion: a.coleccion,
              idColeccion: a.idColeccion,
              proveedor: a.proveedor,
              idProveedor: a.idProveedor,
              cajas: (a.cantidad / a.piezasPorCaja).toFixed(2),
              valor: a.valor,
              area: a.area,
              idArea: a.idArea,
            }
           }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);


      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_LINEAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allLineas = response.data;
        setLineas(allLineas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_MARCAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allMarcas = response.data;
        setMarcas(allMarcas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_FAMILIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allFamilias = response.data;
        setFamilias(allFamilias);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColecciones = response.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);



  function PDFTabla() {
    let total_general = 0;
    let total_cajas = 0;
    let total_valor = 0;

    const data = comments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        comment.linea.toLowerCase().includes(search.toLowerCase()) ||
        comment.marca.toLowerCase().includes(search.toLowerCase()) ||
        comment.familia.toLowerCase().includes(search.toLowerCase()) ||
        comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
        comment.cajas.toString().includes(search)||
        comment.cantidad.toString().includes(search)||
        comment.piezasPorCaja.toString().includes(search)
    )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          (selectedLineaFiltro == 0 || selectedLineaFiltro == a.idLinea) &&
          (selectedMarca == 0 || selectedMarca == a.idMarca) &&
          (selectedFamiliaFiltro == 0 || selectedFamiliaFiltro == a.idFamilia) &&
          (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
          (activo == 0 || activo == a.is_active)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad)
          total_cajas = parseFloat(total_cajas) + parseFloat(a.cajas)
          total_valor = parseFloat(total_valor) + parseFloat(a.valor)
          return [
            a.codigo,
            a.nombre,
            a.linea,
            a.marca,
            a.familia,
            a.coleccion,
            a.piezasPorCaja,
            new Intl.NumberFormat("en-US").format(a.cajas),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.valor),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "PxC",
          "Cajas",
          "Cantidad",
          "Costo",
          "Valor",

        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(total_cajas),
          new Intl.NumberFormat("en-US").format(total_general),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_valor),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`ValorInventarios.pdf`);
  }

  function excel() {
   
    const data = comments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        comment.linea.toLowerCase().includes(search.toLowerCase()) ||
        comment.marca.toLowerCase().includes(search.toLowerCase()) ||
        comment.familia.toLowerCase().includes(search.toLowerCase()) ||
        comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
        comment.cajas.toString().includes(search)||
        comment.cantidad.toString().includes(search)||
        comment.piezasPorCaja.toString().includes(search)
    )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          (selectedLineaFiltro == 0 || selectedLineaFiltro == a.idLinea) &&
          (selectedMarca == 0 || selectedMarca == a.idMarca) &&
          (selectedFamiliaFiltro == 0 || selectedFamiliaFiltro == a.idFamilia) &&
          (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
          (activo == 0 || activo == a.is_active)
        ) {
        return {
          Codigo: a.codigo,
          Nombre: a.nombre,
          Linea: a.linea,
          Marca: a.marca,
          Familia: a.familia,
          Coleccion: a.coleccion,
          PiezasPorCaja: a.piezasPorCaja,
          Cajas: a.cajas,
          Cantidad: a.cantidad,
          Costo: a.costoMX,
          Valor: a.valor
        };
      }
    });
    const dataExportExcel = Object.values(data);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ValorInventario";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ValorInventario",
        sheetFilter: [
          "Codigo",
          "Nombre",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "PiezasPorCaja",
          "Cajas",
          "Cantidad",
          "Costo",
          "Valor",
        ],
        sheetHeader: [
          "Codigo",
          "Nombre",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "PiezasPorCaja",
          "Cajas",
          "Cantidad",
          "Costo",
          "Valor",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    let total_general = 0;
    let total_cajas = 0;
    let total_valor = 0;

    const data = comments
    .filter(
      (comment) =>
        comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
        comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
        comment.linea.toLowerCase().includes(search.toLowerCase()) ||
        comment.marca.toLowerCase().includes(search.toLowerCase()) ||
        comment.familia.toLowerCase().includes(search.toLowerCase()) ||
        comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
        comment.cajas.toString().includes(search)||
        comment.cantidad.toString().includes(search)||
        comment.piezasPorCaja.toString().includes(search)
    )
      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      .map((a) => {
        if (
          (selectedArticulo == 0 || selectedArticulo == a.idArticulo) &&
          (selectedLineaFiltro == 0 || selectedLineaFiltro == a.idLinea) &&
          (selectedMarca == 0 || selectedMarca == a.idMarca) &&
          (selectedFamiliaFiltro == 0 || selectedFamiliaFiltro == a.idFamilia) &&
          (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
          (activo == 0 || activo == a.is_active)
        ) {
          total_general = parseFloat(total_general) + parseFloat(a.cantidad)
          total_cajas = parseFloat(total_cajas) + parseFloat(a.cajas)
          total_valor = parseFloat(total_valor) + parseFloat(a.valor)
          return [
            a.codigo,
            a.nombre,
            a.linea,
            a.marca,
            a.familia,
            a.coleccion,
            a.piezasPorCaja,
            new Intl.NumberFormat("en-US").format(a.cajas),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.valor),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado Inventarios`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Nombre",
          "Linea",
          "Marca",
          "Familia",
          "Coleccion",
          "PxC",
          "Cajas",
          "Cantidad",
          "Costo",
          "Valor",

        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
      foot: [
        [
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "Totales",
          new Intl.NumberFormat("en-US").format(total_cajas),
          new Intl.NumberFormat("en-US").format(total_general),
          "",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_valor),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Valor Inventarios",
          email: mailTo,
          fileName: "ValorInventarios.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el valor del Inventario.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

 

  const headers = [
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Nombre", field: "nombre", sortable: true },
    { name: "Linea", field: "linea", sortable: true },
    { name: "Marca", field: "marca", sortable: true },
    { name: "Familia", field: "familia", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Almacen", field: "area", sortable: true },
    { name: "Pzas. Caja", field: "piezasPorCaja", sortable: true },
    { name: "Cajas", field: "cajas", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: true },
    { name: "Costo", field: "costoMX", sortable: true },
    { name: "Valor", field: "valor", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
          comment.linea.toLowerCase().includes(search.toLowerCase()) ||
          comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.familia.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase()) ||
          comment.area.toLowerCase().includes(search.toLowerCase()) ||
          comment.cajas.toString().includes(search)||
          comment.cantidad.toString().includes(search)||
          comment.valor.toString().includes(search)||
          comment.costoMX.toString().includes(search)||
          comment.piezasPorCaja.toString().includes(search)
      );
    }

    if (selectedLineaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idLinea.includes(selectedLineaFiltro)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }
    if (selectedMarca) {
      computedComments = computedComments.filter((e) =>
        e.idMarca.includes(selectedMarca)
      );
    }
    if (selectedArticulo) {
      computedComments = computedComments.filter((e) =>
        e.idArticulo.includes(selectedArticulo)
      );
    }
    if (selectedFamiliaFiltro) {
      computedComments = computedComments.filter((e) =>
        e.idFamilia.includes(selectedFamiliaFiltro)
      );
    }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (selectedArea) {
      computedComments = computedComments.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }


    setTotalItems(computedComments.length);


    let can = computedComments.map((c) => parseFloat(c.cantidad));
    let TCan = can.reduce((t, cantidad, index) => t + cantidad, 0);
    setTotalTotal(TCan);

    let caj = computedComments.map((c) => parseFloat(c.cajas));
    let Tcaj = caj.reduce((t, cajas, index) => t + cajas, 0);
    setTotalTotalCajas(Tcaj);

    let val = computedComments.map((c) => parseFloat(c.valor));
    let Tval = val.reduce((t, valor, index) => t + valor, 0);
    setTotalTotalValor(Tval);

    //Sorting comments
    if (sorting.field && sorting.field != "cantidad" && sorting.field != "piezasPorCaja" && sorting.field != "cajas" && sorting.field != "valor" && sorting.field != "costoMX") {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (sorting.field && sorting.order === "asc" && (sorting.field == "cantidad" || sorting.field == "piezasPorCaja" || sorting.field == "cajas" || sorting.field != "valor" || sorting.field != "costoMX")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (sorting.field && sorting.order === "desc" && (sorting.field == "cantidad" || sorting.field == "piezasPorCaja" || sorting.field == "cajas" || sorting.field != "valor" || sorting.field != "costoMX")) {
      computedComments = computedComments.sort(
        (a, b) => parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedArticulo,
    activo,
    selectedLineaFiltro,
    selectedMarca, 
    selectedFamiliaFiltro, 
    selectedColeccion,
    selectedArea
  ]);


  function limpiarSearch(){
    setSearch("")
    setSelectedArticulo("")
    setSelectedLineaFiltro("")
    setSelectedMarca("")
    setSelectedFamiliaFiltro("")
    setSelectedColeccion("")
  }

  
  function jalaFoto(
    idEdit
  ) {
    setPhoto("")
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        if (data.data.version) {
          setPhoto(data.data.version);
        }
      })
      .catch((err) => console.log(err));


    setIdEditArticulo(idEdit);
    toggleFoto();
  }


  let total = 0;
  let totalValor = 0;
  let total_cajas = 0;
  return (
    <>
      <Header />
      <br />
      <br />
      {user.inventarios ? (
        <div className="card col-12">
          <Row>
          <Col md={8}>
              <Button
                size="sm"
                href="/MenuReportesVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Inventarios</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
         
          <h3 align="center">Valor Inventario</h3>
          <div className="row">
            <div className="col-md-10">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div> <div className="col-md-1 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
             <div className="col-md-1 flex-row-reverse">
            <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={limpiarSearch}
                >
                  Limpiar
                </Button>
                </div>
           
          </div>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArticulo}
                  onChange={(e) => {
                    setSelectedArticulo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {articulos
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedLineaFiltro}
                  onChange={(e) => {
                    setSelectedLineaFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {lineas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>

              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedFamiliaFiltro}
                  onChange={(e) => {
                    setSelectedFamiliaFiltro(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {familias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedArea}
                  onChange={(e) => {
                    setSelectedArea(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>

              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}></td>
             
              <td></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                total = total + parseFloat(a.cantidad);
                totalValor = totalValor + parseFloat(a.valor);
                total_cajas = total_cajas + parseFloat(a.cajas);
                {
                  return (
                    <tr>
                      <td>{a.codigo}</td>
                      <td>{a.nombre}</td>
                      <td>{a.linea}</td>
                      <td>{a.marca}</td>
                      <td>{a.familia}</td>
                      <td>{a.coleccion}</td>
                      <td>{a.area}</td>
                      <td>{a.piezasPorCaja}</td>
                      <td>
                        { new Intl.NumberFormat("en-US").format(a.cajas)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.cantidad)}
                      </td>
                      <td>
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.costoMX)}
                      </td>
                      <td>
                      {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.valor)}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(total_cajas)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(total)}
                </td>
                <td></td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalValor)}
                </td>
              </tr>

              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita">TOTAL GENERAL</td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTotalCajas)}
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US").format(totalTotal)}
                </td>
                <td></td>
                <td className="negrita">
                {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(totalTotalValor)}
                </td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
 
     
      {loader}
    </>
  );
}

export default ValorInventario;
