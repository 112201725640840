import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Table
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function ArticulosFichaTecnica() {
  const { user } = useContext(AuthContext);
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PROPORCION = process.env.REACT_APP_URL_ARTICULOS_PROPORCION;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;

  const [id, setID] = useState("");
  const [value, setValue] = useState("");
  const [photo, setPhoto] = useState();
  const [inventario, setInventario] = useState(0);
  const [articulo, setArticulo] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [articulos, setArticulos] = useState([]);

  useEffect(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  useMemo(() => {
    
    if(id != "" && id.length == 24){
      setPhoto("")
      const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${id}`;
      axios
        .get(URL_GET_MEDIA, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((data) => {
          if (data.data.version) {
            setPhoto(data.data.version);
          }
        })
        .catch((err) => console.log(err));
  
        axios
        .get(`${URL_ARTICULOS}/${id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allArticulo = response.data;
          setArticulo(allArticulo);
          
        })
        .catch((err) => {
          console.log(err);
        });

        axios
        .get(`${URL_INVENTARIOS}Articulo/${id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allInventario = response.data;

          let total = allInventario.reduce((a, b) => a + b.cantidad, 0);
          setInventario(total);
          
        })
        .catch((err) => {
          console.log(err);
        });

        axios
        .get(`${URL_ARTICULOS_PROPORCION}Articulos/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allPorporcion = res.data.filter((porcion) => porcion.cantidad !== "0");
  
          const tallas = Array.from(
            new Set(allPorporcion.flatMap((porcion) => porcion.tallas.map((talla) => talla.name)))
          );
  
          const customSizeOrder = ["EECH", "ECH", "CH", "M", "G", "EG", "2EG", "3EG", "4EG", "5EG", "6EG", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "5/6", "7/8", "10/12", "14/16"]; // Add other sizes as needed
          const sortedTallas = tallas.sort((a, b) => {
            return customSizeOrder.indexOf(a) - customSizeOrder.indexOf(b);
          });
  
          // Define columns (tallas)
          const columns = ["Color", ...sortedTallas];
          setColumns(columns);
  
          // Group data by color
          const groupedData = {};
          allPorporcion.forEach((porcion) => {
            const colorName = porcion.colores[0].name;
            porcion.tallas.forEach((talla) => {
              if (!groupedData[colorName]) {
                groupedData[colorName] = {};
              }
              groupedData[colorName][talla.name] = porcion.cantidad || 0;
            });
          });
  
          // Define rows (colores, cantidad)
          const rows = Object.keys(groupedData).map((colorName) => {
            const rowData = [colorName];
            sortedTallas.forEach((talla) => {
              rowData.push(groupedData[colorName][talla] || 0);
            });
            return rowData;
          });
  
          setRows(rows);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const options = articulos.map((option) => {
    const junta = option.codigo + option.nombre;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  return (
    <>
      <Header />
      <br />
      <br />
      {user.fichaTecnica ? (
        <div className="card container">
          <h3 align="center">Ficha Tecnica</h3>
          <Row>
            <Col md={3}>
            <Label>Articulo</Label>
            <Autocomplete
                      size="small"
                      value={value}
                      onChange={(event, selectedArticulo) => {
                        setID(selectedArticulo._id);
                        setValue(selectedArticulo);
                        setArticulo([]);
                        setInventario(0);
                        setColumns([]);
                        setRows([]);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option.codigo} {option.nombre}
                        </React.Fragment>
                      )}
                    />
            </Col>
          </Row>
          <br />
          <Row>
          <Col md={3}>
              <Label>Codigo</Label>
              <Input
                type="text"
                placeholder="Codigo"
                value={id}
                required
                onChange={(e) => {
                  setID(e.target.value);
                  setArticulo([]);
                  setInventario(0);
                  setColumns([]);
                  setRows([]);
                }}
              />
            </Col>
            </Row>
            <br />
            <Row>
            <Col md={5}>
            <Label>Articulo</Label>
            <h4>{articulo.codigo} {articulo.nombre}</h4>
            </Col>
            <Col md={3}>
            <Label>Inventario Total</Label>
            <h4>{ new Intl.NumberFormat().format(inventario)}</h4>
            </Col>
          </Row>

            <br />
            <Row>
            <Table>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
            </Row>
           
          <br />
          <hr />
          <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  style={{ width: "100%" }}
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/cremboArticulos/${id}.jpg`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una Ficha Tecnica....</h4>}
          </Row>


        </div>
      ) : undefined}
    </>
  );
}

export default ArticulosFichaTecnica;
