import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Badge,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import Calidad from "../../Calidad";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";

function RegistroControlCalidad() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_NOTAS_CXP = process.env.REACT_APP_URL_NOTAS_CXP;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;

  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [compras, setCompras] = useState([]);
  const [articulosEmbarque, setArticulosEmbarque] = useState([]);
  const [articulos, setArticulos] = useState([]);

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");

  const [contenedor, setContenedor] = useState("");
  const [numero_embarque, setNumeroEmbarque] = useState("");

  // const [sup, setSup] = useState("");
  // const [familias, setFamilias] = useState("");

  const [descuentoTotalTotal, setDescuentoTotalTotal] = useState(0);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  const [validaBoton, setValidaBoton] = useState(true);

  const [editCalidad, setEditCalidad] = useState(false);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [calidad, setCalidad] = useState("");

  // const [file, setFile] = useState();
  // const [photo, setPhoto] = useState([]);
  const [idNotaCXP, setIdNotaCXP] = useState();
  // const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;

  // const [modalFoto, setModalFoto] = useState(false);
  // const toggleFoto = () => setModalFoto(!modalFoto);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_NOTAS_CXP, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        // Array para pagination
        let arrayTabla = allCompras
          .sort((a, b) => (a.idNotasCxP < b.idNotasCxP ? 1 : -1))
          .map((a) => {
            return {
              activo: a.is_active,
              calidad: a.calidad,
              _id: a._id,
              numero: a.idNotasCxP,
              fecha: a.fecha,
              observaciones: a.embarques[0].observaciones,
              contenedor: a.embarques[0].contenedor,
              totalCantidad: a.total_cantidad.toFixed(2),
              numeroEmbarque: a.embarques[0].idEmbarques,
              proveedores: a.proveedores[0].nombre_comercial,
              idProveedores: a.proveedores[0]._id,
              idEmbarques: a.embarques[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setCompras(allCompras);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (calidad == 0 || calidad == a.calidad) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.calidad,
          a.numero,
          a.numeroEmbarque,
          a.fecha,
          a.proveedores,
          a.contenedor,
          a.observaciones,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Control de Calidad`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Ctrl Calidad",
          "Numero",
          "Embarque",
          "Fecha",
          "Proveedor",
          "Contenedor",
          "Observaciones",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
    });
    doc.save(`ControlCalidad.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (calidad == 0 || calidad == a.calidad) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          CtrlCalidad: a.calidad,
          Numero: a.numero,
          Embarque: a.numeroEmbarque,
          Fecha: a.fecha,
          Proveedores: a.proveedores,
          Contenedor: a.contenedor,
          Observaciones: a.observaciones,
          Cantidad: a.totalCantidad,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoControlCalidad";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoControlCalidad",
        sheetFilter: [
          "CtrlCalidad",
          "Numero",
          "Embarque",
          "Fecha",
          "Proveedor",
          "Contenedor",
          "Observaciones",
          "Cantidad",
        ],
        sheetHeader: [
          "CtrlCalidad",
          "Numero",
          "Embarque",
          "Fecha",
          "Proveedor",
          "Contenedor",
          "Observaciones",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (calidad == 0 || calidad == a.calidad) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.calidad,
          a.numero,
          a.numeroEmbarque,
          a.fecha,
          a.proveedores,
          a.contenedor,
          a.observaciones,
          new Intl.NumberFormat("en-US").format(a.totalCantidad),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Listado de Control de Calidad`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Ctrl Calidad",
          "Numero",
          "Embarque",
          "Fecha",
          "Proveedor",
          "Contenedor",
          "Observaciones",
          "Cantidad",
        ],
      ],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Control de Calidad",
          email: mailTo,
          fileName: "ListadoControlCalidad.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Control de Calidad.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  async function PDFOC(
    fecha,
    idProveedores,
    proveedores,
    totalCantidad,
    observaciones,
    contenedor,
    numero,
    idPDFOC
  ) {

    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idPDFOC}`;
    await axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
          let photoPDF = data.data.resources

     axios
      .get(`${URL_ARTICULOS_EMBARQUES}/nota/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosCompra = response.data;
        let arrayTabla = allArticulosCompra
          .sort((a, b) => (a.captura > b.captura ? 1 : -1))
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
              // sup: a.sup,
              // familias: a.familias,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              fob: parseFloat(a.precio),
              impuestos: parseFloat(a.impuestos),
              total_costo:
                parseFloat(a.precio) + parseFloat(a.impuestos),
              revisarFaltante: parseFloat(a.revisarFaltante),
              cantidadRevisarFaltante:
                parseFloat(a.cantidad) / parseFloat(a.revisarFaltante),
              faltante: parseFloat(a.faltante),
              porFaltante: parseFloat(a.revisarFaltante),
              faltanteTotal: parseFloat(a.faltanteTotal),
              porFaltantePedir: a.pedirFaltante,
              descuentoFaltante: a.descuentoFaltante,
              revisarDanado: parseFloat(a.revisarDanado),
              cantidadRevisarDanado:
                parseFloat(a.cantidad) / parseFloat(a.revisarDanado),
              danado: parseFloat(a.danado),
              porDanado: parseFloat(a.revisarDanado),
              danadoTotal: parseFloat(a.danadoTotal),
              porDanadoPedir: a.pedirDanado,
              descuentoDanado: a.descuentoDanado,
              descuentoTotal: a.descuentoTotal,
              observaciones: a.observaciones
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let faltanteTotal = dataFinal.map((c) => parseFloat(c.faltanteTotal));
        let TF = faltanteTotal.reduce((t, total, index) => t + total, 0);

        let danadoTotal = dataFinal.map((c) => parseFloat(c.danadoTotal));
        let TD = danadoTotal.reduce((t, total, index) => t + total, 0);

        let descuentoTotal = dataFinal.map((c) => parseFloat(c.descuentoTotal));
        let TT = descuentoTotal.reduce((t, total, index) => t + total, 0);

        var agrupado = dataFinal
          .map(function (a) {
            return a.articulos;
          })
          .join(", ");

        const data = dataFinal.map((a) => {
          return [
            a.articulos,
            a.cantidad,
            a.faltanteTotal,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.descuentoFaltante),
            new Intl.NumberFormat("en-US").format(a.danadoTotal),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.descuentoDanado),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
              
            }).format(a.total_costo),
            a.observaciones
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });

        const doc = new jsPDF("landscape", "mm", "a4", true);
        var img = new Image();
        
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 45, 15);
        doc.text(`Quality Control Management`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Date ${fecha}`, 20, 25);
        doc.text(`Brand: ${process.env.REACT_APP_NOMBRE_INSTITUCION}`, 20, 30);
        // doc.text(`Supplier: ${dataFinal[0].sup}`, 20, 35);
        doc.text(`Container ${contenedor}`, 20, 40);
        // doc.text(`Style: ${agrupado}`, 20, 45);
        doc.text(
          `Missing Pcz: ${new Intl.NumberFormat("en-US").format(TF)}`,
          20,
          45
        );
        // doc.text(`Family: ${dataFinal[0].familias}`, 20, 50);
        doc.text(
          `Defects Pcz: ${new Intl.NumberFormat("en-US").format(TD)}`,
          20,
          55
        );
        doc.text(
          `Amount: ${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 2,
            
          }).format(TT)}`,
          20,
          60
        );
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Cantidad",
              "Faltante Total",
              "Descuento Faltante",
              "Dañado Total",
              "Descuento Dañado",
              "Costo Total",
              "Observaciones"
            ],
          ],
          body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
          startY: 65,
          showFoot: "lastPage",
        });

        doc.setFontSize(10);
        var lines = doc.splitTextToSize(`Observaciones ${observaciones}`, 200);
        doc.text(10, doc.autoTable.previous.finalY + 10, lines);
        
        doc.text(
          `______________________                                     ______________________ `,
          40,
          185
        );
        doc.text(
          `      Zury Sivan                                                             Supplier `,
          50,
          190
        );
        doc.addPage();

        let alto = 10
        let contador = 1
        let contadorPagina = 1
        let derecha = 10

        photoPDF.map((a)=>{
          if(contador % 5 == 0){
            alto = alto + 60
            derecha = 10
            contador = 1
          }
          if(contadorPagina % 13 == 0){
            doc.addPage()
            alto = 10
            derecha = 10
            contadorPagina = 1
          }
          var imgP = new Image();
          imgP.src = a.secure_url
          doc.addImage(imgP, a.format, derecha, alto, 50, 50);
          derecha = derecha + 60
          contador = contador + 1
          contadorPagina = contadorPagina + 1
        })

        doc.save(`ReporteCalidad-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
      
    })
    .catch((err) => console.log(err));
  }

  const headers = [
    { name: "Ctrl Calidad", field: "calidad", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Embarque", field: "numeroEmbarque", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Contenedor", field: "contenedor", sortable: true },
    { name: "Cantidad", field: "totalCantidad", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.calidad.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.contenedor.toLowerCase().includes(search.toLowerCase()) ||
          comment.numeroEmbarque.toString().includes(search) ||
          comment.totalCantidad.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (calidad) {
      computedComments = computedComments.filter((e) =>
        e.calidad.includes(calidad)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "totalCantidad" &&
      sorting.field != "numeroEmbarque" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field == "numeroEmbarque" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "totalCantidad" ||
        sorting.field == "numeroEmbarque" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    calidad,
    selectedFechaInicio,
    selectedFechaFin,
  ]);

  function EditArticulos(contenedor, numero, idEmbarque) {
    axios
      .get(`${URL_ARTICULOS_EMBARQUES}/nota/${idEmbarque}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosEmbarque = res.data;
        console.table(allArticulosEmbarque)
        let arrayArt = allArticulosEmbarque
          .map((a) => {
            return {
              _id: a._id,
              articulo: a.articulos[0].codigo + " / " + a.articulos[0].nombre,
              piezasPorCaja: a.articulos[0].piezasPorCaja,
              cantidad: a.cantidad,
              fob: parseFloat(a.precio),
              impuestos: parseFloat(a.impuestos),
              total_costo:
                parseFloat(a.precio) + parseFloat(a.impuestos),
              revisarFaltante: parseFloat(a.revisarFaltante),
              cantidadRevisarFaltante:
                parseFloat(a.cantidad) / parseFloat(a.revisarFaltante),
              faltante: parseFloat(a.faltante),
              porFaltante: parseFloat(a.revisarFaltante),
              faltanteTotal: parseFloat(a.faltanteTotal),
              porFaltantePedir: a.pedirFaltante,
              descuentoFaltante: a.descuentoFaltante,
              revisarDanado: parseFloat(a.revisarDanado),
              cantidadRevisarDanado:
                parseFloat(a.cantidad) / parseFloat(a.revisarDanado),
              danado: parseFloat(a.danado),
              porDanado: parseFloat(a.revisarDanado),
              danadoTotal: parseFloat(a.danadoTotal),
              porDanadoPedir: a.pedirDanado,
              descuentoDanado: a.descuentoDanado,
              descuentoTotal: a.descuentoTotal,
              observaciones: a.observaciones
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayArt);

        let cant = dataFinal.map((c) => parseFloat(c.descuentoTotal));
        let TC = cant.reduce((t, total, index) => t + total, 0);
        setDescuentoTotalTotal(TC);

        // setSup(allArticulosEmbarque[0].sup);
        // setFamilias(allArticulosEmbarque[0].familias);

        setArticulosEmbarque(dataFinal);
      })
      .catch((err) => {
        console.log(err);
      });

    setNumeroEmbarque(numero);
    setContenedor(contenedor);
    setIdNotaCXP(idEmbarque)
    setEditCalidad(true);
  }

  const handleChangeInputImp = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
        i.total_costo = parseFloat(event.target.value) + parseFloat(i.fob);
        i.descuentoFaltante =
          (parseFloat(i.faltante) / parseFloat(i.cantidadRevisarFaltante)) *
          parseFloat(i.cantidad) *
          (parseFloat(event.target.value) + parseFloat(i.fob));
        i.descuentoDanado =
          (parseFloat(i.danado) / parseFloat(i.cantidadRevisarDanado)) *
          parseFloat(i.cantidad) *
          (parseFloat(event.target.value) + parseFloat(i.fob));
        i.descuentoTotal =
          (parseFloat(i.faltante) / parseFloat(i.cantidadRevisarFaltante)) *
            parseFloat(i.cantidad) *
            (parseFloat(event.target.value) + parseFloat(i.fob)) +
          (parseFloat(i.danado) / parseFloat(i.cantidadRevisarDanado)) *
            parseFloat(i.cantidad) *
            (parseFloat(event.target.value) + parseFloat(i.fob));
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
    ActualizaTotales();
  };

  const handleChangeInputRevisarFaltante = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
        i.cantidadRevisarFaltante =
          (parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100;
        i.porFaltante =
          (parseFloat(i.faltante) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          100;
        i.porFaltantePedir =
          (parseFloat(i.faltante) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          100;
        i.faltanteTotal =
          (parseFloat(i.faltante) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          parseFloat(i.cantidad);
        i.descuentoFaltante =
          (parseFloat(i.faltante) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          parseFloat(i.cantidad) *
          i.total_costo;
        i.descuentoTotal =
          (parseFloat(i.faltante) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
            parseFloat(i.cantidad) *
            i.total_costo +
          i.descuentoDanado;
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
    ActualizaTotales();
  };

  const handleChangeInputFaltante = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
        i.porFaltante =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarFaltante)) *
          100;
        i.porFaltantePedir =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarFaltante)) *
          100;
        i.faltanteTotal =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarFaltante)) *
          parseFloat(i.cantidad);
        i.descuentoFaltante =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarFaltante)) *
          parseFloat(i.cantidad) *
          i.total_costo;
        i.descuentoTotal =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarFaltante)) *
            parseFloat(i.cantidad) *
            i.total_costo +
          i.descuentoDanado;
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
    ActualizaTotales();
  };

  const handleChangeInputPorFaltantePedir = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
        i.descuentoFaltante =
          (parseFloat(event.target.value) / 100) *
          parseFloat(i.cantidad) *
          i.total_costo;
        i.descuentoTotal =
          (parseFloat(event.target.value) / 100) *
            parseFloat(i.cantidad) *
            i.total_costo +
          i.descuentoDanado;
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
    ActualizaTotales();
  };

  const handleChangeInputObs = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
  };

  const handleChangeInputRevisarDanado = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
        i.cantidadRevisarDanado =
          (parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100;
        i.porDanado =
          (parseFloat(i.danado) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          100;
        i.porDanadoPedir =
          (parseFloat(i.danado) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          100;
        i.danadoTotal =
          (parseFloat(i.danado) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          parseFloat(i.cantidad);
        i.descuentoDanado =
          (parseFloat(i.danado) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
          parseFloat(i.cantidad) *
          i.total_costo;
        i.descuentoTotal =
          (parseFloat(i.danado) /
            ((parseFloat(event.target.value) * parseFloat(i.cantidad)) / 100)) *
            parseFloat(i.cantidad) *
            i.total_costo +
          i.descuentoFaltante;
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
    ActualizaTotales();
  };

  const handleChangeInputDanado = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
        i.porDanado =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarDanado)) *
          100;
        i.porDanadoPedir =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarDanado)) *
          100;
        i.danadoTotal =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarDanado)) *
          parseFloat(i.cantidad);
        i.descuentoDanado =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarDanado)) *
          parseFloat(i.cantidad) *
          i.total_costo;
        i.descuentoTotal =
          (parseFloat(event.target.value) /
            parseFloat(i.cantidadRevisarDanado)) *
            parseFloat(i.cantidad) *
            i.total_costo +
          i.descuentoFaltante;
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
    ActualizaTotales();
  };

  const handleChangeInputPorDanadoPedir = (id, event) => {
    const newArticulosEmbarque = articulosEmbarque.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
        i.descuentoDanado =
          (parseFloat(event.target.value) / 100) *
          parseFloat(i.cantidad) *
          i.total_costo;
        i.descuentoTotal =
          (parseFloat(event.target.value) / 100) *
            parseFloat(i.cantidad) *
            i.total_costo +
          i.descuentoFaltante;
      }
      return i;
    });
    setArticulosEmbarque(newArticulosEmbarque);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let cant = articulosEmbarque.map((c) => parseFloat(c.descuentoTotal));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setDescuentoTotalTotal(TC);
  }

  const saveCompra = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalCompras = articulosEmbarque.length;
    try {
      // if (file == null) {
      articulosEmbarque.map((a) => {
        axios
          .patch(
            `${URL_ARTICULOS_EMBARQUES}/calidad/${a._id}`,
            {
              impuestos: a.impuestos,
              revisarFaltante: a.revisarFaltante,
              faltante: a.faltante,
              faltanteTotal: a.faltanteTotal,
              pedirFaltante: a.porFaltantePedir,
              descuentoFaltante: a.descuentoFaltante,
              revisarDanado: a.revisarDanado,
              danado: a.danado,
              danadoTotal: a.danadoTotal,
              pedirDanado: a.porDanadoPedir,
              descuentoDanado: a.descuentoDanado,
              descuentoTotal: a.descuentoTotal,
              // sup: sup,
              // familias: familias,
              observaciones: a.observaciones
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            totalCompras = totalCompras - 1;
            if (totalCompras == 0) {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Control Calidad",
                  detalle: `${numero_embarque}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
              Swal.fire("Good job!", "Creado con exito", `success`);
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
              // setFile()
              // setPhoto([])
              setModalProgreso(false)
              setValidaBoton(true)
            }
          });
      })
    // } else{
    //   articulosEmbarque.map((a) => {
    //     axios
    //       .patch(
    //         `${URL_ARTICULOS_EMBARQUES}/calidad/${a._id}`,
    //         {
    //           impuestos: a.impuestos,
    //           revisarFaltante: a.revisarFaltante,
    //           faltante: a.faltante,
    //           faltanteTotal: a.faltanteTotal,
    //           pedirFaltante: a.porFaltantePedir,
    //           descuentoFaltante: a.descuentoFaltante,
    //           revisarDanado: a.revisarDanado,
    //           danado: a.danado,
    //           danadoTotal: a.danadoTotal,
    //           pedirDanado: a.porDanadoPedir,
    //           descuentoDanado: a.descuentoDanado,
    //           descuentoTotal: a.descuentoTotal,
    //           // sup: sup,
    //           // familias: familias,
    //         },
    //         {
    //           headers: {
    //             Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //           },
    //         }
    //       )
    //       .then(() => {
    //         totalCompras = totalCompras - 1;
    //         if (totalCompras == 0) {

    //           const formData = new FormData();
    //           formData.append("file", file);
    //           formData.append("notasCXP", idNotaCXP );
      
    //           axios
    //             .post(URL_UPMEDIA, formData, {
    //               headers: {
    //                 Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //               },
    //             })

    //           axios.post(
    //             URL_LOGS,
    //             {
    //               tipo: "Control Calidad",
    //               detalle: `${numero_embarque}`,
    //               user: user.id,
    //             },
    //             {
    //               headers: {
    //                 Authorization: `Bearer: ${localStorage.getItem(
    //                   "app_token"
    //                 )}`,
    //               },
    //             }
    //           );
    //           Swal.fire("Good job!", "Creado con exito", `success`);
    //           // setTimeout(() => {
    //           //   window.location.reload();
    //           // }, 1000);
    //           setFile()
    //           setPhoto([])
    //           setModalProgreso(false)
    //           setValidaBoton(true)
    //         }
    //       });
    //   })

    // }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  // function jalaFoto(
  //   idNota
  // ) {
  //   const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idNota}`;
  //   axios
  //     .get(URL_GET_MEDIA, {
  //       headers: {
  //         Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
  //       },
  //     })
  //     .then((data) => {
  //         setPhoto(data.data.resources);
  //     })
  //     .catch((err) => console.log(err));
  //   setIdNotaCXP(idNota);
  //   toggleFoto();
  // }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menuCalidad ? (
        <div className="card container col-11">
          {!editCalidad ? (
            <div>
              <Row>
                <Col md={8}>
                  <Button
                    size="sm"
                    href="/MenuCompras"
                    className="btn btn-danger"
                    id="botonListado"
                  >
                    Regresar
                  </Button>
                </Col>
                <Col sm={4}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      onClick={toggleMail}
                      color="info"
                    >
                      eMail <i class="fas fa-at"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>

              <h3 align="center">Control de Calidad</h3>
              <div className="row">
                <div className="col-md-8">
                  <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
                <div className="col-md-4 d-flex flex-row-reverse">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Inicio</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaInicio}
                    required
                    onChange={(e) => {
                      setSelectedFechaInicio(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Fecha Fin</Label>
                  <Input
                    bsSize="sm"
                    type="date"
                    value={selectedFechaFin}
                    required
                    onChange={(e) => {
                      setSelectedFechaFin(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Proveedores</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={selectedProveedor}
                    onChange={(e) => {
                      setSelectedProveedor(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {proveedores
                      .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                        return <option value={a._id}>{a.nombre_comercial}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={2}>
                  <Label>Control Calidad</Label>
                  <Input
                    bsSize="sm"
                    type="select"
                    value={calidad}
                    required
                    onChange={(e) => {
                      setCalidad(e.target.value);
                      setCurrentPage(1);
                    }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
                </Col>
              </Row>
              <br />
              <Table
                size="sm"
                striped
                borderless
                className="table-responsive-xl"
              >
                <TableHeader
                  headers={headers}
                  onSorting={(field, order) => setSorting({ field, order })}
                />
                <tbody>
                  {commentsData.map((a) => {
                    if(a.calidad == "No"){
                      return (
                        <tr>
                          <td>{a.calidad}</td>
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.numeroEmbarque}</td>
                          <td>{a.proveedores}</td>
                          <td>{a.contenedor}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.totalCantidad
                            )}
                          </td>
                          <td>
                              {/* <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button> */}
                            {user.calidadImportes && a.calidad == "Si" ? (
                              <>
                            <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                    a.idProveedores,
                                    a.proveedores,
                                    a.totalCantidad,
                                    a.observaciones,
                                    a.contenedor,
                                    a.numero,
                                    a._id
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                              </>
                             
                            ) : undefined}
                            {user.calidadCantidad && a.calidad == "No" ? (
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) =>
                                  EditArticulos(a.contenedor, a.numero, a._id)
                                }
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                            ) : undefined}

                            {user.calidadImportes ? (
                              <Calidad
                                idStatus={a._id}
                                calidad={a.calidad}
                                URL_BAJA={process.env.REACT_APP_URL_NOTAS_CXP}
                              />
                            ) : undefined}
                          </td>
                        </tr>
                      );
                    }else{
                      return (
                        <tr style={{ backgroundColor: "#64e379" }}>
                          <td>{a.calidad}</td>
                          <td>{a.numero}</td>
                          <td>{a.fecha}</td>
                          <td>{a.numeroEmbarque}</td>
                          <td>{a.proveedores}</td>
                          <td>{a.contenedor}</td>
                          <td>
                            {new Intl.NumberFormat("en-US").format(
                              a.totalCantidad
                            )}
                          </td>
                          <td>
                              {/* <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button> */}
                            {user.calidadImportes && a.calidad == "Si" ? (

                                      <>
                                      <Button
                                        size="sm"
                                        className="btn"
                                        color="danger"
                                        onClick={(e) =>
                                          PDFOC(
                                            a.fecha,
                                            a.idProveedores,
                                            a.proveedores,
                                            a.totalCantidad,
                                            a.observaciones,
                                            a.contenedor,
                                            a.numero,
                                            a._id
                                          )
                                        }
                                      >
                                        <i class="far fa-file-pdf"></i>
                                      </Button>
                                      </>

                            ) : undefined}
                            {user.calidadCantidad && a.calidad == "No" ? (
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) =>
                                  EditArticulos(a.contenedor, a.numero, a._id)
                                }
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                            ) : undefined}

                            {user.calidadImportes ? (
                              <Calidad
                                idStatus={a._id}
                                calidad={a.calidad}
                                URL_BAJA={process.env.REACT_APP_URL_NOTAS_CXP}
                              />
                            ) : undefined}
                          </td>
                        </tr>
                      ); 
                    }
                  })}
                </tbody>
              </Table>
              <div className="col-md-6">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page) => setCurrentPage(page)}
                />
              </div>
            </div>
          ) : (
            // Termina Tabla calidad
            <div>
              <h3 align="center">Control de Calidad</h3>
              <Row>
                {/* <Col md={2}>
                  <Label className="mr-sm-2">Proveedor</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    value={sup}
                    required
                    onChange={(event) => {
                      setSup(event.target.value);
                    }}
                  ></Input>
                </Col>

                <Col md={2}>
                  <Label className="mr-sm-2">Familias</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    value={familias}
                    required
                    onChange={(event) => {
                      setFamilias(event.target.value);
                    }}
                  ></Input>
                </Col> */}

                <Col md={2}>
                  <Label className="mr-sm-2">Contenedor</Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    value={contenedor}
                    required
                    disabled
                  ></Input>
                </Col>

                {user.calidadImportes ? (
                  <>
                    <Col sm={6}>
                      <h4 align="center">
                        Descuento Total{" "}
                        <Badge
                          id="Total"
                          color="danger"
                          className="BadgeSize badge-pill"
                        >
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                            
                          }).format(descuentoTotalTotal)}
                        </Badge>
                      </h4>
                    </Col>
                  </>
                ) : undefined}
              </Row>

              <br />
              {articulosEmbarque.map((ac) => (
                <div key={ac._id}>
                  <Row>
                    <Col md={2}>
                      <h6 className="mr-sm-2">Articulo: {ac.articulo}</h6>
                    </Col>
                    <Col md={1}>
                      <h6 className="mr-sm-2">Pzas Caja: {ac.piezasPorCaja}</h6>
                    </Col>
                    <Col md={1}>
                      <h6 className="mr-sm-2">
                        Recibido:{" "}
                        {new Intl.NumberFormat("en-US").format(ac.cantidad)}
                      </h6>
                    </Col>
                    {user.calidadImportes ? (
                      <>
                        <Col md={1}>
                          <h6 className="mr-sm-2">
                            FOB:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                              
                            }).format(ac.fob)}
                          </h6>
                        </Col>
                        <Col md={2}>
                          <Row>
                            <Col md={4}>
                              <h6 className="mr-sm-2">Impuestos:</h6>
                            </Col>
                            <Col md={3}>
                              <Input
                                bsSize="sm"
                                name="impuestos"
                                type="number"
                                min="0"
                                step="any"
                                value={ac.impuestos}
                                required
                                onChange={(event) => {
                                  handleChangeInputImp(ac._id, event);
                                }}
                              ></Input>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={1}>
                          <h6 className="mr-sm-2">
                            Costo Total:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                              
                            }).format(ac.total_costo)}
                          </h6>
                        </Col>
                        <Col md={2}>
                          <h6 className="mr-sm-2">
                            Desc. Total:{" "}
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              maximumFractionDigits: 2,
                              
                            }).format(ac.descuentoTotal)}
                          </h6>
                        </Col>
                      </>
                    ) : undefined}
                  </Row>

                  <Row>
                    <Col md={12}>
                      <h6 className="mr-sm-2">Faltante</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}>
                      <Label className="mr-sm-2">% Rev</Label>
                      <Input
                        bsSize="sm"
                        name="revisarFaltante"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.revisarFaltante}
                        required
                        onChange={(event) => {
                          handleChangeInputRevisarFaltante(ac._id, event);
                        }}
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Cant Rev</Label>
                      <Input
                        bsSize="sm"
                        name="cantidadRevisarFaltante"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.cantidadRevisarFaltante}
                        required
                        disabled
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Faltante</Label>
                      <Input
                        bsSize="sm"
                        name="faltante"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.faltante}
                        required
                        onChange={(event) => {
                          handleChangeInputFaltante(ac._id, event);
                        }}
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">% Faltante</Label>
                      <Input
                        bsSize="sm"
                        name="porFaltante"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.porFaltante}
                        required
                        disabled
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Faltante Total</Label>
                      <Input
                        bsSize="sm"
                        name="faltanteTotal"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.faltanteTotal}
                        required
                        disabled
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">% Pedir</Label>
                      <Input
                        bsSize="sm"
                        name="porFaltantePedir"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.porFaltantePedir}
                        required
                        onChange={(event) => {
                          handleChangeInputPorFaltantePedir(ac._id, event);
                        }}
                      ></Input>
                    </Col>
                    {user.calidadImportes ? (
                      <Col md={1}>
                        <Label className="mr-sm-2">Descuento</Label>
                        <Input
                          bsSize="sm"
                          name="descuentoFaltante"
                          type="number"
                          min="0"
                          step="any"
                          value={ac.descuentoFaltante}
                          required
                          disabled
                        ></Input>
                      </Col>
                    ) : undefined}
                      <Col md={2}>
                      <Label className="mr-sm-2">Observaciones</Label>
                      <Input
                        bsSize="sm"
                        name="observaciones"
                        type="text"
                        value={ac.observaciones}
                        required
                        onChange={(event) => {
                          handleChangeInputObs(ac._id, event);
                        }}
                      ></Input>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md={12}>
                      <h6 className="mr-sm-2">Dañado</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}>
                      <Label className="mr-sm-2">% Rev</Label>
                      <Input
                        bsSize="sm"
                        name="revisarDanado"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.revisarDanado}
                        required
                        onChange={(event) => {
                          handleChangeInputRevisarDanado(ac._id, event);
                        }}
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Cant Rev</Label>
                      <Input
                        bsSize="sm"
                        name="cantidadRevisarDanado"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.cantidadRevisarDanado}
                        required
                        disabled
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Danado</Label>
                      <Input
                        bsSize="sm"
                        name="danado"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.danado}
                        required
                        onChange={(event) => {
                          handleChangeInputDanado(ac._id, event);
                        }}
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">% Danado</Label>
                      <Input
                        bsSize="sm"
                        name="porDanado"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.porDanado}
                        required
                        disabled
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">Danado Total</Label>
                      <Input
                        bsSize="sm"
                        name="danadoTotal"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.danadoTotal}
                        required
                        disabled
                      ></Input>
                    </Col>
                    <Col md={1}>
                      <Label className="mr-sm-2">% Pedir</Label>
                      <Input
                        bsSize="sm"
                        name="porDanadoPedir"
                        type="number"
                        min="0"
                        step="any"
                        value={ac.porDanadoPedir}
                        required
                        onChange={(event) => {
                          handleChangeInputPorDanadoPedir(ac._id, event);
                        }}
                      ></Input>
                    </Col>
                    {user.calidadImportes ? (
                      <Col md={1}>
                        <Label className="mr-sm-2">Descuento</Label>
                        <Input
                          bsSize="sm"
                          name="descuentoDanado"
                          type="number"
                          min="0"
                          step="any"
                          value={ac.descuentoDanado}
                          required
                          disabled
                        ></Input>
                      </Col>
                    ) : undefined}
                  </Row>
                  <br />
                  <br />
                </div>
              ))}
                  {/* <Row>
                  <Col md={6}>
              <Label>
                Fotos
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
                  </Row> */}
                  <br />
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  onClick={saveCompra}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
              <Button
                color="danger"
                onClick={(e) => {
                  setEditCalidad(false);
                }}
              >
                Regresar
              </Button>
            </div>
            //Termina tabla edit
          )}
        </div>
      ) : undefined}
      <br />

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios segundos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
        <ModalHeader toggle={toggleMail}>
          <h4>Enviar Listado Ordenes de Compra</h4>
        </ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Email</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={mailTo}
            required
            onChange={(e) => {
              setMailTo(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button size="sm" color="success" onClick={enviaMail}>
            Enviar
          </Button>
        </ModalFooter>
      </Modal>

      {/* <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Fotos
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo.length > 0 ? (
              photo.map((a)=>{
                return(
                <h6 style={{ textAlign: "center" }}>
                <img
                  src= {a.secure_url}
                  width="250"
                  height="auto"
                   />
              </h6>
              )                
              })
            ) : <h4>No se ha subido una Foto....</h4>}
          </Row>
        </ModalBody>
      </Modal> */}

      {loader}
    </>
  );
}

export default RegistroControlCalidad;
