import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Header from "../Header/Header";
import { AuthContext } from "../../contexts/AuthContext";

function HomeInicio() {
  // const { isAuth } = useContext(AuthContext);

  const history = useHistory();

  // function handleClick() {
  //   history.push("/login");
  // }
  return (
    <>
      <Header />
      <br />
      <img
      src={process.env.PUBLIC_URL + "/LogoInstitucion.png"}
      style={{"marginTop":"10%"}}
      height="70%"
      width="70%"
      alt= 'auto'
      className="imgLogo"
      />
    </>
  );
}

export default HomeInicio;
