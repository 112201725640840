import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function SubCategoriasCreate() {
  const { user } = useContext(AuthContext);
  const URL_SUB_CATEGORIAS = process.env.REACT_APP_URL_SUB_CATEGORIAS;
  const URL_CATEGORIAS = process.env.REACT_APP_URL_CATEGORIAS;
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;

  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState("");
  const [name, setName] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_CATEGORIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCategorias = response.data;
        setCategorias(allCategorias);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const saveSubCategorias = (event) => {
    event.preventDefault();
    setValidaBoton(false);
    axios
      .post(
        URL_SUB_CATEGORIAS,
        {
          name,
          categorias: selectedCategoria
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Crear Categoria",
            detalle: name,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <h3 align="center">Crea una Sub Categoria</h3>
        <Form onSubmit={saveSubCategorias}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Sub Categoria
            </Label>
            <Input
              type="text"
              name="email"
              id="exampleEmail"
              placeholder="Nombre de la SubCategoria"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
              <Label className="mr-sm-2">Categoria</Label>
                <Input
                  // bsSize="sm"
                  type="select"
                  required
                  value={selectedCategoria}
                  onChange={(e) => setSelectedCategoria(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {categorias
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
          </FormGroup>
          <Row className="container">
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <div>
              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
            </div>
            <Button color="danger" href="/MenuGastos">
              Regresar
            </Button>
            </Row>
        </Form>
      </div>
    </>
  );
}

export default SubCategoriasCreate;
