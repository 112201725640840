import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function ListadoNominas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_NOMINAS = process.env.REACT_APP_URL_NOMINAS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_PLANTELES = process.env.REACT_APP_URL_PLANTELES;
  const PLANTELES_GENERAL = process.env.REACT_APP_PLANTELES_GENERAL;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  // const URL_NOMINAS_PAGAR = `${process.env.REACT_APP_URL_NOMINAS}/${idNomina}`;
  const [colaboradores, setColaboradores] = useState([]);
  const [nominas, setNominas] = useState([]);
  const [fecha, setFecha] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [importe, setImporte] = useState();
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorFiltro, setSelectedColaboradorFiltro] =
    useState("");
  const [pagado, setPagado] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState("");
  const [selectedFechaFin, setSelectedFechaFin] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [planteles, setPlanteles] = useState([]);
  const [plantelCol, setPlantelCol] = useState([]);
  const [areaCol, setAreaCol] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);
  const [selectedPlantel, setSelectedPlantel] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);
  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const [activo, setActivo] = useState("");

  // Edit
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [idEdit, setIdEdit] = useState();
  const [fechaEdit, setFechaEdit] = useState();
  const [importeEdit, setImporteEdit] = useState();
  const [selectedEdit, setSelectedEdit] = useState("");
  const [comentariosEdit, setComentariosEdit] = useState("");
  const [selectedColaboradorEdit, setSelectedColaboradorEdit] = useState("");

  const ITEMS_PER_PAGE = 50

  


  useEffect(() => {
    axios
      .get(URL_NOMINAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allNominas = response.data;
        // Array para pagination
        let arrayTabla = allNominas
        .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
        .map((a) => {
          return {
            numero: a.idNomina,
            nombre: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
            plantel: a.planteles[0].name,
            idPlantel: a.planteles[0]._id,
            area: a.areas[0].name,
            idArea: a.areas[0]._id,
            fecha: a.fecha,
            pagado: a.pagado,
            idColaborador: a.colaboradores[0]._id,
            importe: a.importe,
            _id: a._id,
            comentarios: a.comentarios,
            select: false,
    
          };
        }).filter(function (el) {
          return el != null;
        });
    
        let dataFinal = Object.values(arrayTabla);
    
        setComments(dataFinal)
        setNominas(allNominas);

      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColaboradores = response.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PLANTELES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPlanteles = response.data;
        setPlanteles(allPlanteles);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

      setNominas(
        nominas.map(n => {
          return {
            select: false,
            _id: n._id,
          };
        })
      );
  }, []);
  const pagoEspecial = (event) => {
    event.preventDefault();
    axios
      .get(`${URL_COLABORADORES}/${selectedColaborador}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allPlantelCol = response.data.planteles[0]._id;
        let allAreaCol = response.data.areas[0]._id;
        axios
          .post(
            URL_NOMINAS,
            {
              fecha,
              pagado: "No",
              importe: importe,
              colaboradores: selectedColaborador,
              comentarios: comentarios,
              planteles: allPlantelCol,
              areas: allAreaCol,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Creo Nomina",
                detalle: `Fecha: ${fecha} / Comentarios: ${comentarios} / Colaborador: ${selectedColaborador}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
            Swal.fire("Good job!", "Creado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  let total = 0;

  function PDFTabla() {
    total = 0;
    const data = nominas.map((a) => {
      if (
        user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL &&
        (selectedPlantel == 0 || selectedPlantel == a.planteles[0]._id) &&
        (selectedArea == 0 || selectedArea == a.areas[0]._id) &&
        (selectedColaboradorFiltro == 0 ||
          selectedColaboradorFiltro == a.colaboradores[0]._id) &&
        (pagado == 0 || pagado == a.pagado) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        total = total + a.importe;
        return [
          [a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido],
          a.planteles[0].name,
          a.areas[0].name,
          a.fecha,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
          a.pagado,
        ];
      } else if (
        a.colaboradores[0].planteles[0]._id == user.planteles &&
        a.colaboradores[0].areas[0]._id == user.areas &&
        (selectedColaboradorFiltro == 0 ||
          selectedColaboradorFiltro == a.colaboradores[0]._id) &&
        (pagado == 0 || pagado == a.pagado) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        total = total + a.importe;
        return [
          [a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido],
          a.planteles[0].name,
          a.areas[0].name,
          a.fecha,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
          a.pagado,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    
    doc.text(`Listado Nominas`, 20, 25);
    doc.autoTable({
      head: [["Nombre", "Plantel", "Area", "Fecha", "Importe", "Pagado"]],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Nominas.pdf`);
  }
  function excel() {
    const dataExcel = nominas.map((a) => {
      if (
        user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL &&
        (selectedPlantel == 0 || selectedPlantel == a.planteles[0]._id) &&
        (selectedArea == 0 || selectedArea == a.areas[0]._id) &&
        (selectedColaboradorFiltro == 0 ||
          selectedColaboradorFiltro == a.colaboradores[0]._id) &&
        (pagado == 0 || pagado == a.pagado) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return {
          Nombre: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
          Plantel: a.planteles[0].name,
          Area: a.areas[0].name,
          Fecha: a.fecha,
          Importe: a.importe,
          Pagado: a.pagado,
        };
      } else if (
        a.colaboradores[0].planteles[0]._id == user.planteles &&
        a.colaboradores[0].areas[0]._id == user.areas &&
        (selectedColaboradorFiltro == 0 ||
          selectedColaboradorFiltro == a.colaboradores[0]._id) &&
        (pagado == 0 || pagado == a.pagado) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        return {
          Nombre: a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido,
          Plantel: a.colaboradores[0].planteles[0].name,
          Area: a.colaboradores[0].areas[0].name,
          Fecha: a.fecha,
          Importe: a.importe,
          Pagado: a.pagado,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoNominas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoNominas",
        sheetFilter: [
          "Nombre",
          "Plantel",
          "Area",
          "Fecha",
          "Importe",
          "Pagado",
        ],
        sheetHeader: [
          "Nombre",
          "Plantel",
          "Area",
          "Fecha",
          "Importe",
          "Pagado",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    total = 0;
    const data = nominas.map((a) => {
      if (
        user.planteles == PLANTELES_GENERAL &&
        user.areas == AREAS_GENERAL &&
        (selectedPlantel == 0 || selectedPlantel == a.planteles[0]._id) &&
        (selectedArea == 0 || selectedArea == a.areas[0]._id) &&
        (selectedColaboradorFiltro == 0 ||
          selectedColaboradorFiltro == a.colaboradores[0]._id) &&
        (pagado == 0 || pagado == a.pagado) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        total = total + a.importe;
        return [
          [a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido],
          a.planteles[0].name,
          a.areas[0].name,
          a.fecha,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
          a.pagado,
        ];
      } else if (
        a.colaboradores[0].planteles[0]._id == user.planteles &&
        a.colaboradores[0].areas[0]._id == user.areas &&
        (selectedColaboradorFiltro == 0 ||
          selectedColaboradorFiltro == a.colaboradores[0]._id) &&
        (pagado == 0 || pagado == a.pagado) &&
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha)
      ) {
        total = total + a.importe;
        return [
          [a.colaboradores[0].nombre + " " + a.colaboradores[0].apellido],
          a.planteles[0].name,
          a.areas[0].name,
          a.fecha,
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.importe),
          a.pagado,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();
    
    doc.text(`Listado Nominas`, 20, 25);
    doc.autoTable({
      head: [["Nombre", "Plantel", "Area", "Fecha", "Importe", "Pagado"]],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 30,
      foot: [
        [
          "",
          "",
          "",
          "Total",
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Nominas",
          email: mailTo,
          fileName: "Listado-Nominas.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de nominas.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }
  let totalTabla = 0;

  function PDFRecibo(idNomina, fecha, name, importe) {
    let idNominaRecibo = idNomina
    let fechaRecibo = fecha
    let nameRecibo = name
    let importeRecibo = importe
    const doc = new jsPDF({compressPdf: true});
    var img = new Image();

    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
                 // Horizontal, Vertical, Ancho, Alto 
    doc.addImage(img2, "png", 95, 15, 23, 35);
                                  // Horizontal, Vertical
    doc.setFontSize(16)
    doc.text(`${process.env.REACT_APP_NOMBRE_INSTITUCION}`,65,65);
    doc.text(`__________________`,80,100);
    doc.text(`Firma`,100,110);
    doc.autoTable({
      head: [["No Recibo","Fecha", "Empleado", "Concepto", "Importe"]],
      body: [[idNominaRecibo, fechaRecibo, nameRecibo, "Recibo de Nomina", new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(importeRecibo)]],
      startY: 70,
    });
    doc.save(`Recibo-${idNominaRecibo}.pdf`);
  }

  function pagar(idNomina) {
    // let idNominaPago = idNomina
    const URL_NOMINAS_PAGAR = `${process.env.REACT_APP_URL_NOMINAS}/${idNomina}`
    Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Pagar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_NOMINAS_PAGAR,
            {
              pagado: "Si",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se registro el Pago",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }
  
  function pagarVarias(){
    //Falta poner logica de n.select == true
    let totalPagar = comments.length;
    if (totalPagar >=1 ){
      Swal.fire({
        title: "Estas seguro?",
        text: "No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Pagar!",
      }).then((result) => {
        if (result.isConfirmed) {
            toggleProgreso();
        let totalNominas = comments.length;
        comments.map((n) =>{
            if (n.select == true){
      const URL_NOMINAS_PAGAR = `${process.env.REACT_APP_URL_NOMINAS}/${n._id}`
              axios
              .patch(
                URL_NOMINAS_PAGAR,
                {
                  pagado: "Si",
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
              .then(() => {
                totalNominas = totalNominas -1
                if (totalNominas == 0) {
                  axios.post(
                    URL_LOGS,
                    {
                      tipo: "Pagar Nomina General",
                      detalle: `Fecha: ${fecha}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  );
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                }
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
          } else {
            totalNominas = totalNominas - 1;
          }
              }); 
            }
          })
  } else{
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  }
}

  function jalaEdit(idEdit, fecha, colaborador, importe, comentarios){
    setIdEdit(idEdit)
    setFechaEdit(fecha)
    setSelectedColaboradorEdit(colaborador)
    setImporteEdit(importe)
    setComentariosEdit(comentarios)
    toggleEdit()
    }

    const editNomina = (event) => {
      const URL_NOMINAS_EDIT = `${process.env.REACT_APP_URL_NOMINAS}/${idEdit}`;
      event.preventDefault();
        axios
          .all([
            axios.patch(
              URL_NOMINAS_EDIT,
              {
                colaboradores: selectedColaboradorEdit,
                // planteles: selectedColaboradorEdit.planteles[0]._id,
                // areas: selectedColaboradorEdit.areas[0]._id,
                fecha: fechaEdit,
                importe: importeEdit,
                comentarios: comentariosEdit,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            ),
          ])
        .then(() => {
          axios
          .post(
            URL_LOGS,
            {
              tipo:'Edit Nomina',
              detalle: `Fecha: ${fecha} / Proveedor: ${selectedColaboradorEdit}`,
              user: user.id
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
         .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    };

  const headers = [
    { name: "Numero", field: "numero", sortable: false },
    { name: "Nombre", field: "nombre", sortable: true },
    // { name: "Plantel", field: "plantel", sortable: true },
    // { name: "Area", field: "area", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Importe", field: "importe", sortable: false },
    { name: "Pagado", field: "pagado", sortable: true },
    { name: "Pagar", field: " ", sortable: false },
    { name: "Acciones", field: "acciones", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments

    if (search) {
        computedComments = computedComments.filter(
            comment =>
                comment.nombre.toLowerCase().includes(search.toLowerCase()) ||
                // comment.plantel.toLowerCase().includes(search.toLowerCase()) ||
                // comment.area.toLowerCase().includes(search.toLowerCase())||
                comment.pagado.toLowerCase().includes(search.toLowerCase())
        );
    }

    if(user.planteles != PLANTELES_GENERAL){
      computedComments = computedComments.filter((e)=>
      e.idPlantel.includes(user.planteles)
      ) 
    } 

    if(user.areas != AREAS_GENERAL){
      computedComments = computedComments.filter((e)=>
      e.idArea.includes(user.areas)
      ) 
    } 

    if(selectedFechaInicio){
      computedComments = computedComments.filter((e)=>
      e.fecha >= selectedFechaInicio
      ) 
    }
  
    if(selectedFechaFin){
      computedComments = computedComments.filter((e)=>
      e.fecha <= selectedFechaFin
      ) 
    }

    // if(selectedPlantel){
    //   computedComments = computedComments.filter((e)=>
    //   e.idPlantel.includes(selectedPlantel)
    //   ) 
    // } 

    // if(selectedArea){
    //   computedComments = computedComments.filter((e)=>
    //   e.idArea.includes(selectedArea)
    //   ) 
    // }

    if(selectedColaboradorFiltro){
      computedComments = computedComments.filter((e)=>
      e.idColaborador.includes(selectedColaboradorFiltro)
      ) 
    }

    if(pagado){
      computedComments = computedComments.filter((e)=>
      e.pagado.includes(pagado)
      ) 
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting, selectedPlantel, selectedArea, selectedColaboradorFiltro, pagado, selectedFechaInicio, selectedFechaFin]);
  return (
    <>
      <Header />
      <br />
      {user.nominas_listado ? (
        <div className="card container">
          <br />
          <Row>
            <Col md={8}>
              {user.nominas_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/NominasCreate"
                >
                  Crear Nomina
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Crear Nomina
                </Button>
              )}
              {user.nominas_pagos_esp ? (
                <Button
                  size="sm"
                  onClick={toggle}
                  className="btn btn-success"
                  id="botonListado"
                >
                  Pagos Especiales
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Pagos Especiales
                </Button>
              )}
              <Modal size="sm" isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Pagos Especiales</ModalHeader>
                <ModalBody>
                  <Label className="mr-sm-2">Empleado</Label>
                  <Input
                    className="col-sm-12"
                    type="select"
                    value={selectedColaborador}
                    required
                    onChange={(e) => {
                      setSelectedColaborador(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona un Empleado</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        if (
                          user.planteles == PLANTELES_GENERAL &&
                          user.areas == AREAS_GENERAL
                        ) {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        } else if (
                          user.planteles == a.planteles[0]._id &&
                          user.areas == a.areas[0]._id
                        ) {
                          return (
                            <option value={a._id}>
                              {a.nombre} {a.apellido}
                            </option>
                          );
                        }
                      })}
                  </Input>
                  <Label className="mr-sm-2">Importe</Label>
                  <Input
                    className="col-sm-12"
                    type="number"
                    min="0"
                    step="any"
                    value={importe}
                    required
                    onChange={(e) => {
                      setImporte(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Fecha</Label>
                  <Input
                    className="col-sm-12"
                    type="date"
                    value={fecha}
                    required
                    onChange={(e) => {
                      setFecha(e.target.value);
                    }}
                  />
                  <Label className="mr-sm-2">Comentarios</Label>
                  <Input
                    className="col-sm-12"
                    type="text"
                    value={comentarios}
                    required
                    onChange={(e) => {
                      setComentarios(e.target.value);
                    }}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button size="sm" color="success" onClick={pagoEspecial}>
                    Pagar
                  </Button>
                </ModalFooter>
              </Modal>

              <SweetAlert
                show={text}
                title="Creado con Exito"
                onConfirm={() => {
                  setText(false);
                }}
              />
              <Button
                size="sm"
                href="/MenuNominas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>

            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="info"
                  onClick={toggleMail}
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado de Nominas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <h3 align="center">Nominas</h3>
          <div className="row">
            <div className="col-md-6">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-6 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={3}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColaboradorFiltro}
                  required
                  onChange={(e) => setSelectedColaboradorFiltro(e.target.value)}
                >
                  <option value="0">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}{" "}
                        </option>
                      );
                    })}
                </Input>
              </td>
              {/* {user.planteles == PLANTELES_GENERAL ? (
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedPlantel}
                      onChange={(e) => {
                        setSelectedPlantel(e.target.value)
                        setCurrentPage(1)
                      }}
                    >
                      <option value="0">Selecciona</option>
                      {planteles
                        .sort((a, b) => a.name - b.name)
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </td>
                ) : (
                  <td></td>
                )}
                {user.areas == AREAS_GENERAL ? (
                  <td style={{ paddingTop: "0px" }}>
                    <Input
                      bsSize="sm"
                      type="select"
                      value={selectedArea}
                      onChange={(e) => {
                        setSelectedArea(e.target.value)
                        setCurrentPage(1)
                      }}
                    >
                      <option value="">Selecciona</option>
                      {areas
                        .sort((a, b) => a.name - b.name)
                        .map((a) => {
                          return <option value={a._id}>{a.name}</option>;
                        })}
                    </Input>
                  </td>
                ) : (
                  <td></td>
                )} */}
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={pagado}
                  onChange={(e) => {
                    setPagado(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                {user.nominas_pagar ? (
                  <div>
                    <Button color="#0F3A5C" size="sm" onClick={pagarVarias}>
                      {" "}
                      Pagar Varias
                    </Button>
                    <br />
                    <Input
                      id="espacioH1"
                      type="checkbox"
                      onChange={(e) => {
                        let checked = e.target.checked;
                        setComments(
                          commentsData.map((n) => {
                            if (n.pagado == "No") {
                              n.select = checked;
                            }
                            return n;
                          })
                        );
                      }}
                    />
                  </div>
                ) : undefined}
              </td>
            </tr>
            <tbody>
              {commentsData.map((n) => {
                {
                  totalTabla = totalTabla + n.importe;
                  return (
                    <tr>
                      <td>{"# " + n.numero}</td>
                      <td>{n.nombre}</td>
                      {/* <td>{n.plantel}</td>
                            <td>{n.area}</td> */}
                      <td>{n.fecha}</td>
                      <td align="center">
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                          
                        }).format(n.importe)}
                      </td>
                      <td align="center">
                        {n.pagado == "No" ? (
                          <span>
                            {user.nominas_pagar ? (
                              <Button
                                color="primary"
                                size="sm"
                                id="Pagar"
                                onClick={(e) => pagar(n._id)}
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                size="sm"
                                id="Pagar"
                                disabled
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                            )}
                          </span>
                        ) : (
                          "Si"
                        )}
                      </td>
                      <td align="center" key={n._id}>
                        {n.pagado == "No" && user.nominas_pagar == true ? (
                          <Input
                            type="checkbox"
                            onChange={(e) => {
                              let checked = e.target.checked;
                              setComments(
                                comments.map((data) => {
                                  if (n._id == data._id) {
                                    data.select = checked;
                                  }
                                  return data;
                                })
                              );
                            }}
                            checked={n.select}
                          />
                        ) : undefined}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          className="btn"
                          color="danger"
                          onClick={(e) =>
                            PDFRecibo(n.numero, n.fecha, n.nombre, n.importe)
                          }
                        >
                          <i class="far fa-file-pdf"></i>
                        </Button>
                        {n.pagado == "No" ? (
                          <span>
                            {user.nominas_create ? (
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) =>
                                  jalaEdit(
                                    n._id,
                                    n.fecha,
                                    n.idColaborador,
                                    n.importe,
                                    n.comentarios
                                  )
                                }
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                            ) : (
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                            )}
                          </span>
                        ) : undefined}
                      </td>
                    </tr>
                  );
                }
              })}
              <tr>
                <td></td>
                {/* <td></td>
                <td></td> */}
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 2,
                    
                  }).format(totalTabla)}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
          <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
            <ModalHeader toggle={toggleEdit}>Editar Gasto</ModalHeader>

            <ModalBody>
              <Label className="mr-sm-2">Empleado</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedColaboradorEdit}
                required
                onChange={(e) => {
                  setSelectedColaboradorEdit(e.target.value);
                }}
                disabled
              >
                <option value="0">Selecciona un Empleado</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    if (
                      user.planteles == PLANTELES_GENERAL &&
                      user.areas == AREAS_GENERAL
                    ) {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    } else if (
                      user.planteles == a.planteles[0]._id &&
                      user.areas == a.areas[0]._id
                    ) {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    }
                  })}
              </Input>
              <Label className="mr-sm-2">Importe</Label>
              <Input
                className="col-sm-12"
                type="number"
                min="0"
                step="any"
                value={importeEdit}
                required
                onChange={(e) => {
                  setImporteEdit(e.target.value);
                }}
              />
              <Label className="mr-sm-2">Fecha</Label>
              <Input
                className="col-sm-12"
                type="date"
                value={fechaEdit}
                required
                onChange={(e) => {
                  setFechaEdit(e.target.value);
                }}
              />
              <Label className="mr-sm-2">Comentarios</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={comentariosEdit}
                required
                onChange={(e) => {
                  setComentariosEdit(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={editNomina}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
      {loader}
      
    </>
  );
}

export default ListadoNominas;

