import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Badge
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ControlCalidadCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CONTROL_CALIDAD = process.env.REACT_APP_URL_CONTROL_CALIDAD;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

 
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState(hoy);
  const [descripcion, setDescripcion] = useState("NA");
  const [familia, setFamilia] = useState("");
  const [total, setTotal] = useState(0);
  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");

  const [articulosEmb, setArticulosEmb] = useState([]);
 
  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idArticulosEmabrque: "",
      danado: 0,
      faltante: 0,
      descuentoTotal:0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
    .get(`${URL_ARTICULOS_EMBARQUES}ControlCalidadCreate`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allArticulosEmb = res.data;
     
      setArticulosEmb(allArticulosEmb);
    })
    .catch((err) => {
      console.log(err);
    });
    axios
    .get(`${URL_PROVEEDORES}Tipo/China`, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allProveedores = res.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  
  }, []);

  const saveControlCalidad = async (event) => {
    event.preventDefault();
    if (fecha != "" && descripcion != "" && familia !="" && selectedProveedor !="")  {
      setValidaBoton(false);
      toggleProgreso();

      let totalArticulos =inputFields.length
      try {
        await axios
          .post(
            URL_CONTROL_CALIDAD,
            {
              is_active: "Si",
              fecha,
              familia,
              descripcion,
              proveedores: selectedProveedor,
              total
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            inputFields.map((a) => {
                axios
                  .patch(
                    `${URL_ARTICULOS_EMBARQUES}/calidad/${a.idArticulosEmabrque}`,
                    {
                      controlCalidad: data.data._id,
                      calidad:"Si"
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalArticulos = totalArticulos - 1;
                    if (totalArticulos == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Control Calidad",
                            detalle: `Proveedor ${selectedProveedor} / CC ${data.data._id}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", "success");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        })
                        .catch((error) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Something went wrong!",
                            footer: `${error.response.data}`,
                          });
                          console.log(error);
                          setValidaBoton(true);
                        });
                    }
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                    setValidaBoton(true);
                  });
            });


          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `Faltan Datos`,
      });
    }
  };

  function BuscaArticulo(id, event) {
      articulosEmb.map((a) => {
        if (a._id == event.target.value) {
          let idArtEmb = a._id;
          let danado = a.danadoTotal;
          let faltante = a.faltanteTotal;
          let descuentoTotal = a.descuentoTotal
          handleChangeInput(id, idArtEmb, danado, faltante, descuentoTotal);
        }
      });
  }

  const handleChangeInput = (id, idArtEmb, danado, faltante, descuentoTotal) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idArticulosEmabrque = idArtEmb;
        i.danado = danado
        i.faltante = faltante
        i.descuentoTotal = descuentoTotal
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  const handleAddFields = () => {

    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idArticulosEmabrque: "",
        danado: 0,
        faltante: 0,
        descuentoTotal:0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
   
  };

  function ActualizaTotales() {
    let cant = inputFields.map((c) => parseFloat(c.descuentoTotal));
    let TC = cant.reduce((t, total, index) => t + total, 0);
    setTotal(TC);
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card container col-10">
          <h3 align="center">Nuevo Control de Calidad</h3>
          <Form onSubmit={saveControlCalidad}>
            <Row>
              
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Proveedor</Label>
                    <Input
                      name="proveedor"
                      type="select"
                      value={selectedProveedor}
                      required
                      onChange={(event) => {
                        setSelectedProveedor(event.target.value);
                      }}
                    >
                      <option value="">Selecciona un Proveedor</option>
                      {proveedores
                        .sort((a, b) =>
                        a.nombre_comercial > b.nombre_comercial ? 1 : -1
                      )
                      .map((a) => {
                          return (
                            <option value={a._id}>{a.nombre_comercial}</option>
                          );
                      })}
                    </Input>
                  </Col>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Familia
                </Label>
                <Input
                  type="text"
                  placeholder="Familia"
                  value={familia}
                  required
                  onChange={(e) => {
                    setFamilia(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Descripcion
                </Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
            </Row>
           

            {/* Tabla Articulos */}

            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Modelo</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Dañado</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Faltante</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Desc. Total $</Label>
              </Col>
              <Col md={2}>
              <h4 align="right">
                Total{"  "}
                <Badge
                  id="Total"
                  color="danger"
                  className="BadgeSize badge-pill"
                >
                  {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total)}
                </Badge>
              </h4>
            </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={3}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.idArticulosEmabrque}
                      required
                      onChange={(event) =>
                        BuscaArticulo(inputField.id, event)
                      }
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosEmb
                      .sort((a, b) => (a.articulos[0].nombre > b.articulos[0].nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a._id}>
                               {a.articulos[0].nombre} / {a.embarques[0].contenedor}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>


                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="danado"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.danado}
                    disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="faltante"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.faltante}
                    disabled
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="descuentoTotal"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.descuentoTotal}
                      disabled
                    ></Input>
                  </Col>

                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
          
                </Row>
              </div>
            ))}
          
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoControlCalidad"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
         <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ControlCalidadCreate;
