import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";

import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Baja from "../../Baja";

function TablaGrupos(props) {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_GRUPOS_EDIT = `${process.env.REACT_APP_URL_GRUPOS}/${props._id}`;
  const [grupos, setGrupos] = useState("");
  const [name, setName] = useState(props.name);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [text, setText] = useState(false);

  useEffect(() => {
    axios
      .get(URL_GRUPOS_EDIT, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGrupos = response.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function editGrupo(event) {
    event.preventDefault();
    axios
      .patch(
        URL_GRUPOS_EDIT,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Grupos Clientes',
            detalle: `${name}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggle();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  return (
    <>
      <td>{props.name}</td>
      <td>
        <Button color="info" id="Editar" size="sm" onClick={toggle}>
          <i class="far fa-edit"></i>{" "}
        </Button>
        <Baja
          idStatus={props._id}
          is_active={props.is_active}
          URL_BAJA={process.env.REACT_APP_URL_GRUPOS}
        />
      </td>
      <Modal size="sm" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Editar Grupo</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editGrupo}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default TablaGrupos;
