import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import {
  ButtonGroup,
  Button,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Spinner
} from "reactstrap";
import Header from "../../layout/Header/Header";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { encode } from "base64-arraybuffer";

function ListadoAI() {
  const { user } = useContext(AuthContext);
  const URL_CHAT_GPT = process.env.REACT_APP_URL_CHAT_GPT;

  const [text, setText] = useState("crea una coleccion de 30 diferentes chamarras sport calientes para dama utiliza las tendencia para la temporada otoño invierno 2024");
  const [response, setResponse] = useState();
  const [file, setFile] = useState();

  
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  function getResponse() {
    setModalProgreso(true)
setResponse()
        axios.post(`${URL_CHAT_GPT}image`,
          {
            text
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
            let resp = response.data.image.data[0].b64_json;
            setResponse(resp);
            setModalProgreso(false)
        })
        .catch((err) => {
            console.log(err);
        });
}



  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
          {/* <Col md={6}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>  */}
            <Col md={5}>
              <Label for="text">Chamarra Caballero</Label>
              {/* <Label for="text">Texto</Label>
              <Input
                type="textarea"
                name="text"
                id="text"
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              /> */}
            </Col>
          </Row>
          <Col md={2}>
            <Button
              size="sm"
              className="btn"
              color="danger"
              onClick={(e) => {
                getResponse();
              }}
            >
              AI
            </Button>
          </Col>

          <Col md={12}>
            {/* <p> */}
            {response && (
                <img src={`data:image/png;base64,${response}`} alt="Generated" />
            )}
              {/* </p> */}
          </Col>

          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default ListadoAI;
