import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import { makeStyles } from "@material-ui/core/styles";
import qrcode from "qrcode-js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function DevolucionesTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS_VENTAS_TIENDAS = process.env.REACT_APP_URL_ARTICULOS_VENTAS_TIENDAS;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_DEVOLUCIONES_TIENDAS = process.env.REACT_APP_URL_DEVOLUCIONES_TIENDAS;
  const URL_ARTICULOS_DEVOLUCIONES_TIENDAS = process.env.REACT_APP_URL_ARTICULOS_DEVOLUCIONES_TIENDAS;
  const URL_VENTAS_TIENDAS = process.env.REACT_APP_URL_VENTAS_TIENDAS;



  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [clientes, setClientes] = useState([]);
 
  const [value, setValue] = useState("");

  const [notas, setNotas] = useState([]);
  const [selectedNota, setSelectedNota] = useState("");
  const [qrNota, setQrNota] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [cliente, setCliente] = useState("");
  const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedClienteName, setSelectedClienteName] = useState("");
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [articulosNota, setArticulosNota] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedAreaName, setSelectedAreaName] = useState("");
  
  const [idsArticulos, setIdsArticulos] = useState([]);

  const [fecha, setFecha] = useState(hoy);

  const [apagado, setApagado] = useState(false);
  const [apagado2, setApagado2] = useState(false);
 
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [impuestos, setImpuestos] = useState(0);
  const [fiscal, setFiscal] = useState("No");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idNota: "",
      cantNota: 0,
      articulos: "",
      articulosNombre: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      total: 0,
      costoUnitario: 0
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);


  useMemo(() => {
    setSelectedArea(user.areas);

  }, [user]);

  useEffect(() => {
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  const saveVenta = async (event) => {
    event.preventDefault();

      toggleProgreso();
      let totalPedido = inputFields.length;

      try {
        await axios
          .post(
            URL_DEVOLUCIONES_TIENDAS,
            {
              fecha,
              clientes: selectedCliente,
              colaboradores: selectedColaborador,
              total_general,
              observaciones,
              total_cantidad,
              areas: selectedArea,
              subTotal,
              iva,
              impuestos,
              fiscal,
              ventasTiendas: selectedNota
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((data) => {
            PDFOC(data.data._id, data.data.idDevolucionTienda);

            inputFields.map((a) => {
              if (a.articulos != "" && a.cantidad != 0) {
                axios
                  .post(
                    URL_ARTICULOS_DEVOLUCIONES_TIENDAS,
                    {
                      devolucionesTiendas: data.data._id,
                      articulos: a.articulos,
                      cantidad: parseFloat(a.cantidad),
                      precio: parseFloat(a.precio),
                      total: parseFloat(a.total),
                      areas: selectedArea,
                      costoUnitario: a.costoUnitario
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    totalPedido = totalPedido - 1;
                    if (totalPedido == 0) {
                      axios
                        .post(
                          URL_LOGS,
                          {
                            tipo: "Crear Devolucion de Tienda",
                            detalle: `${value._id} ${total_general}`,
                            user: user.id,
                          },
                          {
                            headers: {
                              Authorization: `Bearer: ${localStorage.getItem(
                                "app_token"
                              )}`,
                            },
                          }
                        )
                        .then(() => {
                          Swal.fire("Good job!", "Creado con exito", `success`);
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        });
                    }
                  });
              } else {
                totalPedido = totalPedido - 1;
                if (totalPedido == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Crear Devolucion de Tienda",
                        detalle: `${value._id} ${total_general}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", `success`);
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);

                    });
                }
              }
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response}`,
            });
            console.log(error);
            setValidaBoton(true);
          });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response}`,
        });
        console.log(error);
      }
  }

  async function PDFOC(idPDFOC, idDevolucionTienda) {

    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);

    let cajas = inputFields.map((c) => parseFloat(c.cajas));
    let TC = cajas.reduce((t, total, index) => t + total, 0);

    let LA = (inputFields.length)*1.5
    let largoTotal = LA + 4 + 8

    let totalParcial = 0;

    let subTotalTabla = 0
    let total_general_tabla = 0
    let iva_tabla = 0 
    let impuesto_tabla = impuestos

   
        const data = inputFields.map((a) => {
          totalParcial = a.cantidad * a.precio;
          subTotalTabla = subTotalTabla + totalParcial
          iva_tabla = iva_tabla + (subTotalTabla * impuesto_tabla/100)
          return [
            a.articulosNombre,
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        total_general_tabla = subTotalTabla + iva_tabla
        const doc = new jsPDF("p", "cm", [8,largoTotal], true);
        var base64 = qrcode.toDataURL(idPDFOC);
        var img3 = new Image();
        img3.src = base64;
        doc.addImage(img3, "png", 5,1,2,2);

        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        
        doc.setFontSize(6);
        doc.text(`CREMBO FASHION S.A. DE C.V.`, .3, 1);
        doc.text(`Nezahualcoyotl #145, Local 201, Col. Centro`, .3, 1.3);
        doc.text(`Colonia: Centro CDMX`, .3, 1.6);
        doc.text(`Delegacion: Cuauhtemoc, CP: 06020`, .3, 1.9);

        doc.text(`Fecha: ${fecha}`, .3, 2.2);
        doc.text(`Devolucion # ${idDevolucionTienda} / Almacen: ${selectedAreaName}`, .3, 2.5);
        doc.text(`Piezas: ${new Intl.NumberFormat("en-US").format(TK)} / Cajas ${TC.toFixed(2)}`, .3, 2.8);
        doc.text(`Cliente: ${selectedClienteName}`, .3, 3.1);
        doc.setFontSize(10);
        doc.text(`---DEVOLUCION---`, 2.5, 3.8);
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Cant",
              "Precio",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
          styles: {
            fontSize: 8,
        },
          startY: 4,
          theme:"plain",
          margin:{left:0.3, right:0.7},
          foot: [
            [
              "",
              { content: 'SubTotal', colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(subTotalTabla),
            ],
            [
              "",
              { content: `IVA ${impuestos}%`, colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(iva_tabla),
            ],
            // [
            //   "",
            //   { content: "Comision TDC", colSpan: 2, styles: { halign: 'left' } },
            //   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(comisionTDC),
            // ],
            // [
            //   "",
            //   { content: "Comision Flete", colSpan: 2, styles: { halign: 'left' } },
            //   new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(comisionFlete),
            // ],
            [
              "",
              { content: "Total", colSpan: 2, styles: { halign: 'left' } },
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(total_general_tabla),
            ],
          ],
          showFoot: "lastPage",
        });

        doc.addImage(img2, "png", 1, doc.autoTable.previous.finalY-2, 1.8, 1);
        
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
        doc.save(`Devolucion-${idDevolucionTienda}.pdf`);
  }


  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idNota: "",
        cantNota: 0,
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        costoUnitario: 0
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

   ActualizaTotales()
  };


  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        let cantTemp =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        if (i.cantNota >= cantTemp || event.target.value == "") {
          i.cajas = event.target.value;
          i.cantidad =
            parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede la venta!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        if (i.cantNota >= event.target.value) {
          i.cantidad = event.target.value;
          i.cajas =
            parseFloat(event.target.value) / parseFloat(i.piezasPorCaja);
          let totalParcial = i.cantidad * i.precio;
          i.total = totalParcial;
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "La cantidad excede la venta!",
            showConfirmButton: false,
          });
          i.cajas = 0;
          i.cantidad = 0;
        }
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, event) {
    if (
      inputFields.filter((e) => e.articulos == event.target.value).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulosNota.map((a) => {
        if (a.articulos[0]._id == event.target.value) {
          let idArticulo = a.articulos[0]._id;
          let piezasPorCaja = a.articulos[0].piezasPorCaja;
          let precio = a.precio;
          let idNota = a._id;
          let cantNota = a.cantidad;
          let articulosNombre = a.articulos[0].codigo + " / " + a.articulos[0].nombre;
          let costoUnitario = a.costoUnitario
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            idNota,
            cantNota,
            articulosNombre,
            costoUnitario
          );
        }
      });
    }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    idNota,
    cantNota,
    articulosNombre,
    costoUnitario
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        i.idNota = idNota;
        i.cantNota = cantNota;
        i.articulosNombre = articulosNombre;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.costoUnitario = costoUnitario;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (impuestos != 0) {
      setIva((impuestos * TG) / 100);
    } else {
      setIva(0);
    }
    let totTemp = (impuestos * TG) / 100 + TG 
  
    setTotalGeneral(totTemp);
  
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(selectedCliente) {
    setValue(selectedCliente);
    setSelectedCliente(selectedCliente._id)
  
    setSelectedClienteName(selectedCliente.nombre_comercial)
    setSelectedAreaName(selectedCliente.areas[0].name)
    setApagado2(true)

    setQrNota("")
    setSelectedNota("")
    setArticulosNota([]);
    setIdsArticulos([]);
    setTotalGeneral(0)
    setSubTotal(0)
    setIva(0)
    setTotalCantidad(0)
    setImpuestos(0)

    setInputFields([
      {
        id: uuidv4(),
        idNota: "",
        cantNota: 0,
        articulos: "",
        articulosNombre: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        total: 0,
        costoUnitario: 0
      },
    ]);
    
    axios
      .get(`${URL_VENTAS_TIENDAS}ClienteDevolucion/${selectedCliente._id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allNotas = res.data;
        setNotas(allNotas);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaNotas(e) {
    setSelectedNota(e);

    notas.map((a)=>{
      if(a._id == e){
        setImpuestos(a.impuestos)
        setFiscal(a.fiscal)
        setSelectedColaborador(a.colaboradores[0]._id)
      }
    })

    axios
      .get(`${URL_ARTICULOS_VENTAS_TIENDAS}/ventasTiendas/${e}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosNota = res.data;
        setArticulosNota(allArticulosNota);
        let result = allArticulosNota.map((a) => a.articulos[0]._id);
        setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaQrNota(event) {
    if (event.target.value.length >= 24) {
      setQrNota(event.target.value)
      setApagado(true)
      axios
      .get(`${URL_VENTAS_TIENDAS}/${event.target.value}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulosNota = res.data;
        setSelectedNota(allArticulosNota._id);
        setImpuestos(allArticulosNota.impuestos)
        setFiscal(allArticulosNota.fiscal)
        setSelectedCliente(allArticulosNota.clientes[0]._id)
        setSelectedClienteName(allArticulosNota.clientes[0].nombre_comercial)
        setSelectedAreaName(allArticulosNota.clientes[0].areas[0].name)
        setSelectedColaborador(allArticulosNota.colaboradores[0]._id)

        axios
        .get(`${URL_ARTICULOS_VENTAS_TIENDAS}/ventasTiendas/${allArticulosNota._id}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allArticulosNota = res.data;
          setArticulosNota(allArticulosNota);
          let result = allArticulosNota.map((a) => a.articulos[0]._id);
          setIdsArticulos(result);
        })
        .catch((err) => {
          console.log(err);
        });


      })
      .catch((err) => {
        console.log(err);
      });

    } else {
      setQrNota(event.target.value);
    }
  }

 

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (idsArticulos.includes(event.target.value)) {
        articulosNota.map((a) => {
          if (a.articulos[0]._id == event.target.value) {
            let idArticulo = a.articulos[0]._id;
            let piezasPorCaja = a.articulos[0].piezasPorCaja;
            let precio = a.precio;
            let idNota = a._id;
            let cantNota = a.cantidad;
            let articulosNombre = a.articulos[0].codigo + " / " + a.articulos[0].nombre;
            let costoUnitario = a.costoUnitario
            handleChangeInputArticulo(
              id,
              idArticulo,
              piezasPorCaja,
              precio,
              idNota,
              cantNota,
              articulosNombre,
              costoUnitario
            );
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo no aparece en la nota",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.devolucionesTiendas ? (
        <div className="card container col-12">
          <h3 align="center">Nueva Devolucion</h3>
          <Form onSubmit={saveVenta}>
            <Row>
              <Col md={3}>
                <Label className="mr-sm-2">Cliente</Label>
                {apagado ?(
                <Autocomplete
                disabled
                size="small"
                value={value}
                onChange={(event, selectedCliente) => {
                  jalaCliente(selectedCliente);
                }}
                options={options.sort(
                  (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                )}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.junta}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecciona"
                    variant="outlined"
                  />
                )}
                renderOption={(option) => (
                  <React.Fragment>
                  {option.codigo} {option.nombre_comercial}
                </React.Fragment>
                )}
              />
                ):(
                  <Autocomplete
                  size="small"
                  value={value}
                  onChange={(event, selectedCliente) => {
                    jalaCliente(selectedCliente);
                  }}
                  options={options.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecciona"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>
                    {option.codigo} {option.nombre_comercial}
                  </React.Fragment>
                  )}
                />
                )}

              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Notas</Label>
                {apagado?(
                    <Input
                    type="select"
                    required
                    disabled
                  >
                    <option value="">Selecciona una nota</option>
                    {notas
                      .sort((a, b) => (a.idVentaTienda < b.idVentaTienda ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={a._id}>
                            {a.prefijo} {a.consecutivo}
                          </option>
                        );
                      })}
                  </Input>
                ):(
                  <Input
                  type="select"
                  required
                  onChange={(event) => {
                    jalaNotas(event.target.value);
                  }}
                >
                  <option value="">Selecciona una nota</option>
                  {notas
                    .sort((a, b) => (a.idVentaTienda < b.idVentaTienda ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.prefijo} {a.consecutivo}
                        </option>
                      );
                    })}
                </Input>
                )}

                  </Col>
                  <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  QR Nota
                </Label>
                {apagado2?(
                  <Input
                  type="text"
                  placeholder="QR Nota"
                  value={qrNota}
                  required
                  disabled
                />
                ):(
                <Input
                  type="text"
                  placeholder="QR Nota"
                  value={qrNota}
                  required
                  onChange={(event) => {
                    jalaQrNota(event);
                  }}
                />

                )}
              </Col>

              <Col md={1}>
                <Label>Impuestos %</Label>
                <Input
                  type="number"
min="0"
step="any"
                  value={impuestos}
                  disabled
                />
              </Col>
              <Col md={1}>
                <Label>Facturar</Label>
                <Input
                  type="text"
                  value={fiscal}
                  disabled
                >
                </Input>
              </Col>
             
            </Row>
            <Row>
              <Col md={2}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>

              <Col md={1}>
                <Label>Sub Total</Label>
                <Input
                  type="number"
min="0"
step="any"
                  placeholder="Sub Total"
                  value={subTotal.toFixed(2)}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>IVA</Label>
                <Input
                  type="number"
min="0"
step="any"
                  placeholder="IVA"
                  value={iva.toFixed(2)}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>Total</Label>
                <Input
                  type="number"
min="0"
step="any"
                  placeholder="Total"
                  value={total_general}
                  required
                  disabled
                />
              </Col>

              <Col md={1}>
                <Label>Piezas</Label>
                <Input
                  type="number"
min="0"
step="any"
                  placeholder="Piezas"
                  value={total_cantidad}
                  required
                  disabled
                />
              </Col>
            
            </Row>

            {/* Tabla Articulos */}

            <Row>
              <Col md={2}>
                <Label className="mr-sm-2">Codigo QR</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Articulos</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Venta</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cajas</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Piezas Por Caja</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Precio</Label>
              </Col>
              <Col md={1}>
                <Label className="mr-sm-2">Total</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="articulos"
                      type="select"
                      value={inputField.articulos}
                      required
                      onChange={(event) => {
                        BuscaArticulo(inputField.id, event);
                      }}
                    >
                      <option value="">Selecciona un Articulo</option>
                      {articulosNota
                        .sort((a, b) => (a.articulos[0].nombre > b.articulos[0].nombre ? 1 : -1))
                        .map((a) => {
                          return (
                            <option value={a.articulos[0]._id}>
                              {a.articulos[0].nombre}
                            </option>
                          );
                        })}
                    </Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantNota"
                      type="number"
min="0"
step="any"
                      value={inputField.cantNota}
                      disabled
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="piezasPorCaja"
                      type="number"
min="0"
step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
min="0"
step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </Col>
                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="precio"
                      type="number"
min="0"
step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                     disabled
                    />
                  </Col>

                  <Col md={1}>
                    <Input
                      bsSize="sm"
                      name="total"
                      type="number"
min="0"
step="any"
                      placeholder="Total"
                      value={inputField.total}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoDevolucionesTiendas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
                  <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

       
        </div>
      ) : undefined}
    </>
  );
}

export default DevolucionesTiendasCreate;
