import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"

function AnalisisArticulosPedidos() {
  const { user } = useContext(AuthContext);
  const URL_INVENTARIOS = `${process.env.REACT_APP_URL_INVENTARIOS}`;
  const URL_SEGUIMIENTOS = `${process.env.REACT_APP_URL_SEGUIMIENTOS}`;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulo, setSelectedArticulo] = useState("");




  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");



    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50


  useEffect(() => {
       axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
  
  }, []);

  function BuscaArticulo(event) {
    setSelectedArticulo(event.target.value)
    setComments([])


  // Jala Movimientos
  axios
  .get(`${URL_ARTICULOS_PEDIDO}Articulo/${event.target.value}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allMovs = response.data
    let arrayTabla = allMovs.map((a)=>{
      return{
       idPedido: a.pedidos[0].idPedido,
       cliente: a.pedidos[0].clientes[0].nombre_comercial,
       is_active: a.is_active,
       autorizadoApartado: a.autorizadoApartado,
       autorizadoSurtido: a.autorizadoSurtido,
       cantidad: a.cantidad,
       surtido: a.surtido,
       pendiente_surtir: a.pendiente_surtir,
      }
    })
    .filter(function (el) {
      return el != null;
    });
    let data = Object.values(arrayTabla);

    setComments(data);
  })
  .catch((err) => {
    console.log(err);
  })
  }

  function PDFTabla() {
    const data = comments.map((a) => {
        return [
          a.idPedido,
          a.cliente,
          a.is_active,
          a.autorizadoApartado,
          a.autorizadoSurtido,
          a.cantidad,
          a.surtido,
          a.pendiente_surtir
        ];
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape", "mm", "a4", true);
    var img = new Image();
    
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 140, 15, 25, 25);
    doc.text(`Analisis Pedidos`, 20, 30);
    doc.autoTable({
      head: [["Pedido", "Cliente", "Activo", "Apartado", "Aut Surtir", "Cantidad", "Surtido", "Pend Surtir"]],
      body: dataPDFLimpia,
 headStyles: {
            fillColor: ["#723C85"],
            lineWidth: 0
          },
          footStyles: {
            fillColor: ["#723C85"],
          },
          theme:"grid",
      startY: 45,
    });
    doc.save(`Analisis-Pedidos.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
        return {
          Pedido: a.idPedido,
          Cliente: a.cliente,
          Activo: a.is_active,
          AutorizadoApartado: a.autorizadoApartado,
          AutorizadoSurtido: a.autorizadoSurtido,
          Cantidad: a.cantidad,
          Surtido: a.surtido,
          PendienteSurtir: a.pendiente_surtir
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "AnalisisPedidos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "AnalisisPedidos",
        sheetFilter: [
          "Pedido",
          "Cliente",
          "Activo",
          "AutorizadoApartado",
          "AutorizadoSurtido",
          "Cantidad",
          "Surtido",
          "PendienteSurtir",
        ],
        sheetHeader: [
          "Pedido",
          "Cliente",
          "Activo",
          "AutorizadoApartado",
          "AutorizadoSurtido",
          "Cantidad",
          "Surtido",
          "PendienteSurtir",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }



  const headers = [
    { name: "Pedido", field: "idPedido", sortable: false },
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Apartado", field: "autorizadoApartado", sortable: true },
    { name: "Aut Surtir", field: "autorizadoSurtido", sortable: true },
    { name: "Cantidad", field: "cantidad", sortable: false },
    { name: "Surtido", field: "surtido", sortable: false },
    { name: "Pend Surtir", field: "pendiente_surtir", sortable: false },
];



  const commentsData = useMemo(() => {
    let computedComments = comments
  


    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
        const reversed = sorting.order === "asc" ? 1 : -1;
        computedComments = computedComments.sort(
            (a, b) =>
                reversed * a[sorting.field].localeCompare(b[sorting.field])
        );
    }

    //Current Page slice
    return computedComments.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
}, [comments, currentPage, search, sorting]);



  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_ventas ? (
        <div className="card col-12">
          <Row>
            <Col md={10}>
              <Button
                size="sm"
                href="/MenuVentas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={2}>
                  <ButtonGroup id="logoutBoton">
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      onClick={PDFTabla}
                    >
                      PDF <i class="far fa-file-pdf"></i>
                    </Button>
                   
                    <Button
                      size="sm"
                      className="btn"
                      color="primary"
                      onClick={excel}
                    >
                      Excel <i class="far fa-file-excel"></i>
                    </Button>
                  </ButtonGroup>
                </Col>
          </Row>
         
      
          <h3 align="center">Analisis Articulos Pedidos</h3>
          <div className="row">
          <div className="col-md-2">
            <Label>Codigo QR</Label>
          </div>
          <div className="col-md-2">
            <Label>Codigo</Label>
          </div>
          <div className="col-md-2">
            <Label>Articulo</Label>
          </div>
          </div>
          <div className="row">
          <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="text"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                          </Input>
                          </div>
                        <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="select"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.codigo} </option>;
                              })}
                          </Input>
                          </div>

                          <div className="col-md-2">
                        <Input
                            bsSize="sm"
                            type="select"
                            value={selectedArticulo}
                            onChange={(e) => {
                              BuscaArticulo(e)
                              setCurrentPage(1)
                            }}
                          >
                            <option value="">Selecciona</option>
                            {articulos
                              .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                              .map((a) => {
                                return <option value={a._id}>{a.nombre} </option>;
                              })}
                          </Input>
                          </div>
                         
                        {/* <div className="col-md-6 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div> */}
                        
                    </div>
                    <br />





                    {/* Tabla Movimientos */}
                     <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />

          <Table size="sm" striped borderless className="table-responsive-xl">
                <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                      
            <tbody>
              {commentsData.map((a) => {
                  return (
                    <tr>
                      <td>{a.idPedido}</td>
                      <td>{a.cliente}</td>
                      <td>{a.is_active}</td>
                      <td>{a.autorizadoApartado}</td>
                      <td>{a.autorizadoSurtido}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.cantidad)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.surtido)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.pendiente_surtir)}</td>
                    </tr>
                  )
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}


      {loader}
    </>
  );
}

export default AnalisisArticulosPedidos;
