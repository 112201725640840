import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import axios from "axios";
import Header from "../../layout/Header/Header"
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { setIndex } from "@amcharts/amcharts4/.internal/core/utils/Array";

function Configuraciones() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_CONFIGURACIONES = process.env.REACT_APP_URL_CONFIGURACIONES;

  const [emailCorteDiario, setEmailCorteDiario] = useState("");
  const [emailCorteDiario2, setEmailCorteDiario2] = useState("");
  const [emailCorteDiario3, setEmailCorteDiario3] = useState("");
  
  const [id, setId] = useState("");
  const [text, setText] = useState(false);
  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_CONFIGURACIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allConfig = response.data;
       
        setEmailCorteDiario(response.data[0].emailCorteDiario[0])
        setEmailCorteDiario2(response.data[0].emailCorteDiario[1])
        setEmailCorteDiario3(response.data[0].emailCorteDiario[2])

        setId(response.data[0]._id)
      })
      .catch((err) => {
        console.log(err);
      });
    }, []);


  const saveConfiguraciones = (event) => {
    event.preventDefault();
    setValidaBoton(false)

    let facturas = new Array(emailCorteDiario, emailCorteDiario2, emailCorteDiario3)  .filter(function (el) {
      return el != "" && el !=null;
    });
    axios
      .patch(
        `${URL_CONFIGURACIONES}/${id}`,
        {
          emailCorteDiario:facturas,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Actualiza Configuracion',
            detalle: ` ${emailCorteDiario}`,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
     <Header />
      <br />
      <br />
      <div className="card container">
        <h3 align="center">Configuracion General</h3>
        <Form onSubmit={saveConfiguraciones}>
          
             <Label>
              Email Corte Diario
            </Label>
            <Row>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={emailCorteDiario}
              value={emailCorteDiario}
              onChange={(e) => {
                setEmailCorteDiario(e.target.value);
              }}
            /> 
            </Col>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={emailCorteDiario2}
              value={emailCorteDiario2}
              onChange={(e) => {
                setEmailCorteDiario2(e.target.value);
              }}
            />                   
            </Col>
            <Col md={4}>
            <Input
              type="text"
              name="email"
              placeholder={emailCorteDiario3}
              value={emailCorteDiario3}
              onChange={(e) => {
                setEmailCorteDiario3(e.target.value);
              }}
            />                   
            </Col>
            </Row>

          <br />
          <Row>
          {validaBoton ? (
        <Button type="submit" className="btn btn-success">
        Guardar
      </Button>
      ):(
      <Button type="submit" className="btn btn-success" disabled>
        Guardar
      </Button>)}
          </Row>
        </Form>
      </div>
    </>
  );
}

export default Configuraciones;
