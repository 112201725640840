import React, { useContext, useState } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import { Button, Form, FormGroup, Label, Input, Row } from "reactstrap";
import axios from "axios";
// import SweetAlert from "sweetalert2-react";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function GruposCreate() {
  const { user } = useContext(AuthContext);
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const [name, setName] = useState("");

  const clear = () => {
    setName("");
  };

  const saveGrupos = (event) => {
    event.preventDefault();
    axios
      .post(
        URL_GRUPOS,
        {
          name,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        clear();
        Swal.fire("Good job!", "Creado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);

      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  };

  return (
    <>
    {user.menu_clientes ?(
      <div className="container">
        <h3 align="center">Crea un nuevo Grupo</h3>
        <Form inline onSubmit={saveGrupos}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleEmail" className="mr-sm-2">
              Grupo
            </Label>
            <Input
              type="text"
              name="email"
              id="exampleEmail"
              placeholder="Nombre"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </FormGroup>
          <br />
          <Row>
            <Button type="submit" className="btn btn-success">
              Crear Grupo
            </Button>
            <Button
              href="/MenuVentas"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Row>
        </Form>
      </div>
    ):undefined}
    </>
  );
}

export default GruposCreate;
