import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Table,
  Input,
  Col,
  Row
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import axios from "axios";
import moment from 'moment';


function VentaAcumulado() {
  const { user } = useContext(AuthContext);
  const URL_LOCKER = process.env.REACT_APP_URL_LOCKER;


// Crear una nueva instancia de la fecha de hoy
let hoy = new Date();

let diaDeLaSemana = hoy.getDay(); // Obtiene el día de la semana (0 es domingo, 1 es lunes, etc.)

// Si hoy es domingo (0), queremos retroceder 6 días para obtener el lunes anterior
let diferenciaDias = diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1;

let lunesDeEstaSemana = new Date(hoy);
lunesDeEstaSemana.setDate(hoy.getDate() - diferenciaDias);


let endDate   = moment(hoy).format("YYYY-MM-DD"); 
let startDate   = moment(lunesDeEstaSemana).format("YYYY-MM-DD");

let anoActual = startDate.slice(0, 4)


  const [TotalDineroAno, setTotalDineroAno] = useState(0);
  const [TotalPiezasAno, setTotalPiezasAno] = useState(0);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);

  const [info, setInfo] = useState([]);


  
  useEffect(() => {


      // Acumulado
      axios
      .get(`${URL_LOCKER}VentasAcumuladoAnual`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;


        let agrupadoAno = allInfo.reduce(function (groups, item) {
          const val = item["Ano"];
          groups[val] = groups[val] || {
            Ano: item.Ano,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Ano = item.Ano;
          return groups;
        }, []);

        let otraAno = Object.values(agrupadoAno)

        setInfo(otraAno);






      })
      .catch((err) => {
        console.log(err);
      });

   
 
        
  }, []);


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <>
        <div className="card container col-sm-12">
        <div className="card container col-sm-4">
          <h3 align="center">Venta Acumulada</h3>

          <Table>
            
          <thead>
                 
                  <tr>
                    <th></th>
                    <th>Piezas</th>
                    <th>Importe</th>
                </tr>
                 </thead>
                 <tbody>
                  {info.map((info) => (
                    <tr>
                      <th>{info.Ano}</th>
                      <td>{new Intl.NumberFormat("en-US").format(info.TotalPiezas)}</td>
                      <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(info.TotalDinero)}</td>
                    </tr>
                  ))}
                
                 </tbody>
                </Table>
          
        </div>
   </div>
        </>
      ) : undefined}
      
    </>
  );
}

export default VentaAcumulado;
