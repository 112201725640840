import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function DashboardTiendas() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [graficasArea, setGraficasArea] = useState([]);
  const [clientes, setClientes] = useState([]); 
  const [selectedCliente, setSelectedCliente] = useState("");
  const [vendedores, setVendedores] = useState([]); 
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [areas, setAreas] = useState([]); 
  const [selectedArea, setSelectedArea] = useState("");
 
  const [info, setInfo] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [totalVenta, setTotalVenta] = useState(0);
  const [totalAbonos, setTotalAbonos] = useState(0);



  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }
  
  useEffect(() => {
    axios
      .get(`${URL_DASHBOARDS}Tiendas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGraficas = response.data;
        setInfo(allGraficas);

        let TTot = allGraficas.map((c) => parseFloat(c.Ventas));
        let TT = TTot.reduce((t, total, index) => t + total, 0);
        setTotalVenta(TT);
    
        let TAbo = allGraficas.map((c) => parseFloat(c.Abonos));
        let TA = TAbo.reduce((t, total, index) => t + total, 0);
        setTotalAbonos(TA);

        let unicosCli = allGraficas.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idCliente === value.idCliente)
        );
        setClientes(unicosCli);

        let unicosAre = allGraficas.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArea === value.idArea)
        );
        setAreas(unicosAre);

        let unicosVend = allGraficas.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idVendedor === value.idVendedor)
        );
        setVendedores(unicosVend);

        setCargando(false);
        Toast.close();
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  function BuscaInfo(){
    setInfo([])
    setSelectedCliente("")
    setSelectedArea("")
    setSelectedVendedor("")
    setCargando(true)
    axios
      .get(`${URL_DASHBOARDS}Tiendas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allGraficas = response.data;
        setInfo(allGraficas);

        let TTot = allGraficas.map((c) => parseFloat(c.Ventas));
        let TT = TTot.reduce((t, total, index) => t + total, 0);
        setTotalVenta(TT);
    
        let TAbo = allGraficas.map((c) => parseFloat(c.Abonos));
        let TA = TAbo.reduce((t, total, index) => t + total, 0);
        setTotalAbonos(TA);

        let unicosCli = allGraficas.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idCliente === value.idCliente)
        );
        setClientes(unicosCli);

        let unicosVend = allGraficas.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idVendedor === value.idVendedor)
        );
        setVendedores(unicosVend);

        let unicosAre = allGraficas.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.idArea === value.idArea)
        );
        setAreas(unicosAre);

        setCargando(false);
        Toast.close();
      })
      .catch((err) => {
        console.log(err);
      });

  }

  useMemo(()=>{
    let temp = info
    if (selectedCliente) {
      temp = temp.filter((e) =>
        e.idCliente.includes(selectedCliente)
      );
    }
    if (selectedArea) {
      temp = temp.filter((e) =>
        e.idArea.includes(selectedArea)
      );
    }

    if (selectedVendedor) {
      temp = temp.filter((e) =>
        e.idVendedor.includes(selectedVendedor)
      );
    }

    let agrupadoArea = temp.reduce(function (groups, item) {
      const val = item["idArea"] 
      groups[val] = groups[val] || {
        idArea: item.idArea,
        Ventas: 0,
        Abonos: 0,

      };
      groups[val].Ventas += item.Ventas;
      groups[val].Abonos += item.Abonos;
      groups[val].Area = item.Area;
      groups[val].idArea = item.idArea;
      return groups;
    }, []);

    let otraArea = Object.values(agrupadoArea).sort((a, b) =>
    a.Ventas < b.Ventas ? 1 : -1
  );

    setGraficasArea(otraArea)


    let TTot = temp.map((c) => parseFloat(c.Ventas));
    let TT = TTot.reduce((t, total, index) => t + total, 0);
    setTotalVenta(TT);

    let TAbo = temp.map((c) => parseFloat(c.Abonos));
    let TA = TAbo.reduce((t, total, index) => t + total, 0);
    setTotalAbonos(TA);



  },[info, selectedCliente, selectedVendedor, selectedArea])

  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panY: false,
        layout: root.verticalLayout,
      })
    );

    let data = graficasArea;

    // Create Y-axis
    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min:0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create X-Axis
    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "Area",
      })
    );
    xAxis.data.setAll(data);


    // Create series

    let seriesVen = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Ventas",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "Ventas",
        categoryXField: "Area",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{Area} Ventas / ${valueY}",
        }),
      })
    );
    seriesVen.data.setAll(data);

    let seriesAbo = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Abonos",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "Abonos",
        categoryXField: "Area",
        tooltip: am5.Tooltip.new(root, {
          labelText: "{Area} Abonos / ${valueY}",
        }),
      })
    );
    seriesAbo.data.setAll(data);




    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    let exporting = am5plugins_exporting.Exporting.new(root, {
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
    });


    return () => {
      root.dispose();
    };
  }, [graficasArea]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_reportes ? ( */}
      <div className="card container col-12">
        <Row>
        <Col md={8}>
              <Button
                size="sm"
                href="/Dashboards"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              
            </Col>
        </Row>
        <h3 align="center">Dashboard Tiendas</h3>
        <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
        <Col md={2}>
              <Label className="mr-sm-2">Clientes</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.Cliente > b.Cliente ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idCliente}>{a.Cliente}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Vendedores</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedVendedor}
                required
                onChange={(e) => {
                  setSelectedVendedor(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {vendedores
                  .sort((a, b) => (a.Vendedor > b.Vendedor ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idVendedor}>{a.Vendedor}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label className="mr-sm-2">Almacen</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedArea}
                required
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {areas
                  .sort((a, b) => (a.Area > b.Area ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idArea}>{a.Area}</option>;
                  })}
              </Input>
            </Col>
        </Row>

      <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
      >

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              TOTAL VENTA
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(totalVenta)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

        
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              TOTAL ABONOS
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(totalAbonos)}
              </CardText>
            </CardBody>
          </Card>
        </Col>

       
      </div>

  
      
      <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
      
      </div>

      {/*  ) : undefined} */}
    </>
  );
}
export default DashboardTiendas;
