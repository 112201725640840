import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../../layout/Header/Header";
import {
  Table,
  Input,
  Col,
  Row,
  Button,
  ButtonGroup
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import axios from "axios";
import moment from 'moment';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";


function Locker() {
  const { user } = useContext(AuthContext);
  const URL_LOCKER = process.env.REACT_APP_URL_LOCKER;
  const URL_ARTICULOS_OC_PEND_EMBARCAR = process.env.REACT_APP_URL_ARTICULOS_OC_PEND_EMBARCAR;
  const URL_ARTICULOS_EMBARQUES = process.env.REACT_APP_URL_ARTICULOS_EMBARQUES;
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;

// Crear una nueva instancia de la fecha de hoy
let hoy = new Date();

let diaDeLaSemana = hoy.getDay(); // Obtiene el día de la semana (0 es domingo, 1 es lunes, etc.)

// Si hoy es domingo (0), queremos retroceder 6 días para obtener el lunes anterior
let diferenciaDias = diaDeLaSemana === 0 ? 6 : diaDeLaSemana - 1;

let lunesDeEstaSemana = new Date(hoy);
lunesDeEstaSemana.setDate(hoy.getDate() - diferenciaDias);


let endDate   = moment(hoy).format("YYYY-MM-DD"); 
let startDate   = moment(lunesDeEstaSemana).format("YYYY-MM-DD");
let semana   = moment(lunesDeEstaSemana).format("DD-MMM-YYYY");

let anoActual = startDate.slice(0, 4)


  const [infoAcumuladoVendedores, setInfoAcumuladoVendedores] = useState([]);
  const [infoAcumuladoProveedor, setInfoAcumuladoProveedor] = useState([]);
  const [TotalDineroAno, setTotalDineroAno] = useState(0);
  const [TotalDineroComparativa, setTotalDineroComparativa] = useState(0);
  const [TotalPiezasComparativa, setTotalPiezasComparativa] = useState(0);
  const [TotalPiezasAno, setTotalPiezasAno] = useState(0);

  const [infoSemanaVendedores, setInfoSemanaVendedores] = useState([]);
  const [infoSemanaProveedor, setInfoSemanaProveedor] = useState([]);
  const [TotalDineroSemana, setTotalDineroSemana] = useState(0);
  const [TotalPiezasSemana, setTotalPiezasSemana] = useState(0);

  const [inventario, setInventario] = useState([]);
  const [inventarioTotalPiezas, setInventarioTotalPiezas] = useState(0);
  const [inventarioTotalDinero, setInventarioTotalDinero] = useState(0);

  const [pendEmbarcarTotal, setPendEmbarcarTotal] = useState(0);
  const [pendRecibirTotal, setPendRecibirTotal] = useState(0);

  const [totalEnTiempoGeneral, setTotalEnTiempoGeneral] = useState(0);
  const [totalSaldoGeneral, setTotalSaldoGeneral] = useState(0);
  const [totalTreintaGeneral, setTotalTreintaGeneral] = useState(0);
  const [totalSesentaGeneral, setTotalSesentaGeneral] = useState(0);
  const [totalNoventaGeneral, setTotalNoventaGeneral] = useState(0);
  const [totalCientoVeinteGeneral, setTotalCientoVeinteGeneral] = useState(0);
  const [totalCientoOchentaGeneral, setTotalCientoOchentaGeneral] = useState(0);
  const [totalMasGeneral, setTotalMasGeneral] = useState(0);

  const [cobranzaVendedores, setCobranzaVendedores] = useState([]);
  const [cobranzaVendedoresTotal, setCobranzaVendedoresTotal] = useState([]);

  const [abonosVendedores, setAbonosVendedores] = useState([]);
  const [abonosVendedoresTotal, setAbonosVendedoresTotal] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(endDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);


  
  useEffect(() => {

    axios
    .get(`${URL_LOCKER}VentasSemanaProveedor/${selectedFechaInicio}/${selectedFechaFin}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allInfo = response.data;
      setInfoSemanaProveedor(allInfo)

    })
    .catch((err) => {
      console.log(err);
    });


    axios
      .get(`${URL_LOCKER}VentasSemana/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;

        let TEfe = allInfo.map((c) => parseFloat(c.TotalDinero));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);
        setTotalDineroSemana(TE)

        let TPZ = allInfo.map((c) => parseFloat(c.TotalPiezas));
        let TP = TPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezasSemana(TP)

        let agrupadoVendedor = allInfo.reduce(function (groups, item) {
          const val = item["idVendedor"];
          groups[val] = groups[val] || {
            idVendedor: item.idVendedor,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Vendedor = item.Vendedor;
          groups[val].idVendedor = item.idVendedor;
          return groups;
        }, []);

        let otraVendedor = Object.values(agrupadoVendedor)

        setInfoSemanaVendedores(otraVendedor);


      })
      .catch((err) => {
        console.log(err);
      });

      // Acumulado
      axios
      .get(`${URL_LOCKER}VentasAcumulado/${selectedFechaInicio}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;

        let agrupadoVendedor = allInfo.reduce(function (groups, item) {
          const val = item["idVendedor"];
          groups[val] = groups[val] || {
            idVendedor: item.idVendedor,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Vendedor = item.Vendedor;
          groups[val].idVendedor = item.idVendedor;
          return groups;
        }, []);

        let otraVendedor = Object.values(agrupadoVendedor)

        setInfoAcumuladoVendedores(otraVendedor);

        let agrupadoProveedor = allInfo.reduce(function (groups, item) {
          const val = item["idProveedor"];
          groups[val] = groups[val] || {
            idProveedor: item.idProveedor,
            TotalDinero: 0,
            TotalPiezas: 0,
            devolucionesDinero: 0,
            devolucionesPiezas: 0,
          };
          groups[val].TotalDinero += item.TotalDinero;
          groups[val].TotalPiezas += item.TotalPiezas;
          groups[val].DevolucionesDinero += item.DevolucionesDinero;
          groups[val].DevolucionesPiezas += item.DevolucionesPiezas;
          groups[val].Proveedor = item.Proveedor;
          groups[val].idProveedor = item.idProveedor;
          return groups;
        }, []);

        let otraProveedor = Object.values(agrupadoProveedor)

        setInfoAcumuladoProveedor(otraProveedor);


        let TEfe = allInfo.map((c) => parseFloat(c.TotalDinero));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);
        setTotalDineroAno(TE)

        let TPZ = allInfo.map((c) => parseFloat(c.TotalPiezas));
        let TP = TPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezasAno(TP)

      })
      .catch((err) => {
        console.log(err);
      });

      // Comparativa
      axios
      .get(`${URL_LOCKER}VentasAcumuladoComparativa/${selectedFechaInicio}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allInfo = response.data;

        let TEfe = allInfo.map((c) => parseFloat(c.TotalDinero));
        let TE = TEfe.reduce((t, total, index) => t + total, 0);
        setTotalDineroComparativa(TE)

        let TPZ = allInfo.map((c) => parseFloat(c.TotalPiezas));
        let TP = TPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezasComparativa(TP)

      })
      .catch((err) => {
        console.log(err);
      });

       // Inventario
       axios
       .get(`${URL_LOCKER}Inventario`, {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       })
       .then((response) => {
         let allInfo = response.data;
         setInventario(allInfo);

         let TEfe = allInfo.map((c) => parseFloat(c.TotalDinero));
         let TE = TEfe.reduce((t, total, index) => t + total, 0);
         setInventarioTotalDinero(TE)
 
         let TPZ = allInfo.map((c) => parseFloat(c.TotalPiezas));
         let TP = TPZ.reduce((t, total, index) => t + total, 0);
         setInventarioTotalPiezas(TP)

        })
        .catch((err) => {
          console.log(err);
        });


        axios
        .get(`${URL_LOCKER}PendEmbarcar`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allPendEmbarcar = response.data;
  

          let TPZ = allPendEmbarcar.map((c) => parseFloat(c.pendiente_embarcar));
          let TP = TPZ.reduce((t, total, index) => t + total, 0);
          setPendEmbarcarTotal(TP)
        })
        .catch((err) => {
          console.log(err);
        });
  
        axios
        .get(`${URL_LOCKER}PendRecibir`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allPendRecibir = response.data;

          let TPZ = allPendRecibir.map((c) => parseFloat(c.pendiente_recibir));
          let TP = TPZ.reduce((t, total, index) => t + total, 0);
          setPendRecibirTotal(TP)
        })
        .catch((err) => {
          console.log(err);
        });

        axios
        .get(`${URL_DASHBOARDS}AntiguedadSaldosVencido`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allVentas = response.data;
          // Array para pagination
          let arrayTabla = allVentas
          .sort((a, b) => (a.nombreCliente > b.nombreCliente ? 1 : -1))
            .map((a) => {
              return {
                saldo: a.saldo,
                enTiempo: a.enTiempo,
                treinta: a.treinta,
                sesenta: a.sesenta,
                noventa: a.noventa,
                cientoveinte: a.cientoveinte,
                cientoochenta: a.cientoochenta,
                otro: a.otro,
              }
                
            })
            .filter(function (el) {
              return el != null;
            });
  
          let data = Object.values(arrayTabla);
  
          
    let ven = data.map((c) => parseFloat(c.enTiempo));
      let Tven = ven.reduce((t, importe, index) => t + importe, 0);
      setTotalEnTiempoGeneral(Tven);

      let t = data.map((c) => parseFloat(c.treinta));
      let Tt = t.reduce((t, importe, index) => t + importe, 0);
      setTotalTreintaGeneral(Tt);

      let s = data.map((c) => parseFloat(c.sesenta));
      let Ts = s.reduce((t, importe, index) => t + importe, 0);
      setTotalSesentaGeneral(Ts);

      let n = data.map((c) => parseFloat(c.noventa));
      let Tn = n.reduce((t, importe, index) => t + importe, 0);
      setTotalNoventaGeneral(Tn);

      let v = data.map((c) => parseFloat(c.cientoveinte));
      let Tv = v.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoVeinteGeneral(Tv);

      let o = data.map((c) => parseFloat(c.cientoochenta));
      let To = o.reduce((t, importe, index) => t + importe, 0);
      setTotalCientoOchentaGeneral(To);

      let m = data.map((c) => parseFloat(c.otro));
      let Tm = m.reduce((t, importe, index) => t + importe, 0);
      setTotalMasGeneral(Tm);
  
      setTotalSaldoGeneral(Tven + Tt + Ts + Tn + Tv + To + Tm);
          //
        })
        .catch((err) => {
          console.log(err);
        });

               // CobranzaVendedor
       axios
       .get(`${URL_LOCKER}CobranzaVendedor`, {
         headers: {
           Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
         },
       })
       .then((response) => {
         let allInfo = response.data;
         setCobranzaVendedores(allInfo);

         let TEfe = allInfo.map((c) => parseFloat(c.Saldo));
         let TE = TEfe.reduce((t, total, index) => t + total, 0);
         setCobranzaVendedoresTotal(TE)
 

        })
        .catch((err) => {
          console.log(err);
        });

        // Abonos Vendedor Semana
        axios
        .get(`${URL_LOCKER}AbonosVendedorSemana/${selectedFechaInicio}/${selectedFechaFin}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allInfo = response.data;
          setAbonosVendedores(allInfo);
 
          let TEfe = allInfo.map((c) => parseFloat(c.Importe));
          let TE = TEfe.reduce((t, total, index) => t + total, 0);
          setAbonosVendedoresTotal(TE)
  
 
         })
         .catch((err) => {
           console.log(err);
         });
        
  }, []);


  function PDFTabla() {
    const resumenData = [
      
      [`Comparativa ${anoActual-1}`,
        new Intl.NumberFormat("en-US").format(TotalPiezasComparativa),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroComparativa)],
      [`Acumulado ${anoActual}`,
        new Intl.NumberFormat("en-US").format(TotalPiezasAno),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroAno)],
        [`Semana del ${semana}`,
          new Intl.NumberFormat("en-US").format(TotalPiezasSemana),
          new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroSemana)],
    ];

    const columnStylesResumen = [
      { fontSize: 9,textColor: ["#000000"],halign: 'left'},
      { fontSize: 9,textColor: ["#000000"],halign: 'right'},
      { fontSize: 9,textColor: ["#000000"],halign: 'right'},
  ];

    const dataAcumuladoVendedores = infoAcumuladoVendedores.map((a) => {
      return [
        a.Vendedor,
        new Intl.NumberFormat("en-US").format(a.TotalPiezas),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.TotalDinero),
      ];
    });

    const dataAcumuladoVendedoresPDF = Object.values(dataAcumuladoVendedores);

    const dataAcumuladoVendedoresPDFLimpia = dataAcumuladoVendedoresPDF.filter(function (el) {
      return el != null;
    });

    const dataSemanaVendedores = infoSemanaVendedores.map((a) => {
      return [
        a.Vendedor,
        new Intl.NumberFormat("en-US").format(a.TotalPiezas),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.TotalDinero),
      ];
    });

    const dataSemanaVendedoresPDF = Object.values(dataSemanaVendedores);

    const dataSemanaVendedoresPDFLimpia = dataSemanaVendedoresPDF.filter(function (el) {
      return el != null;
    });

    const dataAcumuladoProveedores = infoAcumuladoProveedor.map((a) => {
      return [
        a.Proveedor,
        new Intl.NumberFormat("en-US").format(a.TotalPiezas),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.TotalDinero),
      ];
    });

    const dataAcumuladoProveedoresPDF = Object.values(dataAcumuladoProveedores);

    const dataAcumuladoProveedoresPDFLimpia = dataAcumuladoProveedoresPDF.filter(function (el) {
      return el != null;
    });

    const dataSemanaProveedores = infoSemanaProveedor.map((a) => {
      return [
        a.Proveedor,
        new Intl.NumberFormat("en-US").format(a.TotalPiezas),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.TotalDinero),
      ];
    });

    const dataSemanaProveedoresPDF = Object.values(dataSemanaProveedores);

    const dataSemanaProveedoresPDFLimpia = dataSemanaProveedoresPDF.filter(function (el) {
      return el != null;
    });

    const dataCobranzaVendedores = cobranzaVendedores.map((a) => {
      return [
        a.Vendedor,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.Saldo),
      ];
    });

    const dataCobranzaVendedoresPDF = Object.values(dataCobranzaVendedores);

    const dataCobranzaVendedoresPDFLimpia = dataCobranzaVendedoresPDF.filter(function (el) {
      return el != null;
    });


    const dataAbonosVendedores = abonosVendedores.map((a) => {
      return [
        a.Vendedor,
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.Importe),
      ];
    });

    const dataAbonosVendedoresPDF = Object.values(dataAbonosVendedores);

    const dataAbonosVendedoresPDFLimpia = dataAbonosVendedoresPDF.filter(function (el) {
      return el != null;
    });


    const dataInventario = inventario.map((a) => {
      return [
        a.Area,
        new Intl.NumberFormat("en-US").format(a.TotalPiezas),
        new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 2,
        }).format(a.TotalDinero),
      ];
    });

    const dataInventarioPDF = Object.values(dataInventario);

    const dataInventarioPDFLimpia = dataInventarioPDF.filter(function (el) {
      return el != null;
    });





    const doc = new jsPDF("portrait", "mm", "a4", true);
    var img = new Image();
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 45, 15);
    doc.text(`Locker`, 20, 10);
    doc.setFontSize(12)
    // Resumen General
    doc.autoTable({
      head: [
        [
          "Resumen General",
          "Piezas",
          "Importe",
        ]
      ],
      body: resumenData,
      startY: 15,
      headStyles: {
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      columnStyles:columnStylesResumen,
      theme:"grid",
    });

    // Resumen Ventas Vendedor

    doc.text(`Ventas Acumulado Vendedor`, 20, doc.autoTable.previous.finalY + 10);

    doc.autoTable({
      head: [
        [
          "Vendedor",
          "Piezas",
          "Importe",
        ],
      ],
      body: dataAcumuladoVendedoresPDFLimpia,
      headStyles: {
        fillColor: ["#4d2363"],
        lineWidth: 0,
        halign: 'center',
        valign: 'middle',
        fontSize: 8
      },
      footStyles: {
        fillColor: ["#4d2363"],
        fontSize: 8
      },
      columnStyles:columnStylesResumen,
      theme: "grid",
      startY: doc.autoTable.previous.finalY + 12,
    });

// Resumen Ventas Vendedor Semana

doc.text(`Ventas Acumulado Vendedor Semana ${semana}`, 20, doc.autoTable.previous.finalY + 10);

doc.autoTable({
  head: [
    [
      "Vendedor",
      "Piezas",
      "Importe",
    ],
  ],
  body: dataSemanaVendedoresPDFLimpia,
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: 'center',
    valign: 'middle',
    fontSize: 8
  },
  footStyles: {
    fillColor: ["#4d2363"],
  },
  columnStyles:columnStylesResumen,
  theme: "grid",
  startY: doc.autoTable.previous.finalY + 12,
});

// Resumen Ventas Acumulado Proveedor

doc.text(`Ventas Acumulado Proveedor`, 20, doc.autoTable.previous.finalY + 10);

doc.autoTable({
  head: [
    [
      "Proveedor",
      "Piezas",
      "Importe",
    ],
  ],
  body: dataAcumuladoProveedoresPDFLimpia,
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: 'center',
    valign: 'middle',
    fontSize: 8
  },
  footStyles: {
    fillColor: ["#4d2363"],
  },
  columnStyles:columnStylesResumen,
  theme: "grid",
  startY: doc.autoTable.previous.finalY + 12,
});

// Resumen Ventas Semana Proveedor

doc.text(`Ventas Proveedor Semana ${semana}`, 20, doc.autoTable.previous.finalY + 10);

doc.autoTable({
  head: [
    [
      "Proveedor",
      "Piezas",
      "Importe",
    ],
  ],
  body: dataSemanaProveedoresPDFLimpia,
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: 'center',
    valign: 'middle',
    fontSize: 8
  },
  footStyles: {
    fillColor: ["#4d2363"],
  },
  columnStyles:columnStylesResumen,
  theme: "grid",
  startY: doc.autoTable.previous.finalY + 12,
});


// Antiguedad Saldos

doc.text(`Antiguedad Saldos`, 20, doc.autoTable.previous.finalY + 10);

doc.autoTable({
  head: [
    [
      "Total General",
      "En Tiempo",
      "Vencido 30",
      "Vencido 60",
      "Vencido 90",
      "Vencido 120",
      "Vencido 180",
      "Mas de 180",
    ],
  ],
  body: [
    [
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral),
        new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral),
    ],
    [
      new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSaldoGeneral / totalSaldoGeneral),
new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral / totalSaldoGeneral),
new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalTreintaGeneral / totalSaldoGeneral),
new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSesentaGeneral / totalSaldoGeneral),
new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalNoventaGeneral / totalSaldoGeneral),
new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral / totalSaldoGeneral),
new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral / totalSaldoGeneral),
new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalMasGeneral / totalSaldoGeneral),
    ]
  ],
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: "center",
    valign: "middle",
    fontSize: 8,
  },
  footStyles: {
    fillColor: ["#4d2363"],
  },
  columnStyles: [
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
    { fontSize: 8, textColor: ["#000000"], halign: "right" },
  ],
  theme: "grid",
  startY: doc.autoTable.previous.finalY + 12,
});

// Cobranza Pendiente Vendedor

doc.text(`Cobranza Pendiente Vendedor`, 20, doc.autoTable.previous.finalY + 10);

doc.autoTable({
  head: [
    [
      "Vendedor",
      "Saldo",
    ],
  ],
  body: dataCobranzaVendedoresPDFLimpia,
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: 'center',
    valign: 'middle',
    fontSize: 8
  },
  footStyles: {
    fillColor: ["#4d2363"],
    halign: 'right',
  },
  columnStyles:columnStylesResumen,
  theme: "grid",
  startY: doc.autoTable.previous.finalY + 12,
  foot: [
    [
      "Total",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(cobranzaVendedoresTotal),
    ]
  ],
  showFoot: "lastPage",
  theme:"grid",
});


// Cobranza Por Vendedor

doc.text(`Cobranza Por Vendedor Semana ${semana}`, 20, doc.autoTable.previous.finalY + 10);

doc.autoTable({
  head: [
    [
      "Vendedor",
      "Importe",
    ],
  ],
  body: dataAbonosVendedoresPDFLimpia,
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: 'center',
    valign: 'middle',
    fontSize: 8
  },
  footStyles: {
    fillColor: ["#4d2363"],
    halign: 'right',
  },
  columnStyles:columnStylesResumen,
  theme: "grid",
  startY: doc.autoTable.previous.finalY + 12,
  foot: [
    [
      "Total",
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(abonosVendedoresTotal),
    ]
  ],
  showFoot: "lastPage",
  theme:"grid",
});



// Resumen Inventario

doc.text(`Resumen Inventario`, 20, doc.autoTable.previous.finalY + 10);

doc.autoTable({
  head: [
    [
      "Almacen",
      "Piezas",
      "Importe",
    ],
  ],
  body: dataInventarioPDFLimpia,
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: 'center',
    valign: 'middle',
    fontSize: 8
  },
  footStyles: {
    fillColor: ["#4d2363"],
    halign: 'right',
  },
  columnStyles:columnStylesResumen,
  theme: "grid",
  startY: doc.autoTable.previous.finalY + 12,
  foot: [
    [
      "Total",
      new Intl.NumberFormat("en-US").format(inventarioTotalPiezas),
      new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(inventarioTotalDinero),
    ]
  ],
  showFoot: "lastPage",
  theme:"grid",
});

 // Pendiente Embarcar y Recibir
 doc.autoTable({
  head: [
    [
      "Pendiente Embarcar",
      "Pendiente Recibir",
    ]
  ],
  body: [
    [
      new Intl.NumberFormat("en-US").format(pendEmbarcarTotal),
      new Intl.NumberFormat("en-US").format(pendRecibirTotal)
    ]
  ],
  startY: doc.autoTable.previous.finalY + 12,
  headStyles: {
    fillColor: ["#4d2363"],
    lineWidth: 0,
    halign: 'center',
    valign: 'middle',
    fontSize: 8
  },
  columnStyles:columnStylesResumen,
  theme:"grid",
});



    doc.save(`Locker.pdf`);
  }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_reportes ? (
        <>
        <div className="card container col-sm-12">
        <Row>
        <Col sm={12}>
            <ButtonGroup id="logoutBoton">
              <Button
                size="sm"
                className="btn"
                color="danger"
                onClick={PDFTabla}
              >
                PDF <i class="far fa-file-pdf"></i>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <br />
          <h3 align="center">Locker</h3>

          <div className="card container col-sm-6">
        <h4>Resumen General</h4>

          <Table>
            
          <thead>
                 
                  <tr>
                    <th></th>
                    <th>Piezas</th>
                    <th>Importe</th>
                </tr>
                 </thead>
                 <tbody>
                 <tr>
                    <th>Comparativa {anoActual-1}</th>
                    <td>{new Intl.NumberFormat("en-US").format(TotalPiezasComparativa)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroComparativa)}</td>
                  </tr>

                  <tr>
                    <th>Acumulado {anoActual}</th>
                    <td>{new Intl.NumberFormat("en-US").format(TotalPiezasAno)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroAno)}</td>
                  </tr>

                  <tr>
                    <th>Semana del {semana}</th>
                    <td>{new Intl.NumberFormat("en-US").format(TotalPiezasSemana)}</td>
                    <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TotalDineroSemana)}</td>
                  </tr>
                 </tbody>
                </Table>
          
        </div>
        <hr />
        <h4>Resumen Ventas por Vendedor</h4>
        <Row>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Ventas Acumulado {anoActual}</th>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoAcumuladoVendedores.map((a) => (
                   <tr key={a.idVendedor}>
                     <td>{a.Vendedor}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Ventas Semana {semana}</th>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoSemanaVendedores.map((a) => (
                   <tr key={a.idVendedor}>
                     <td>{a.Vendedor}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        </Row>
        <hr />
        <h4>Resumen Ventas por Proveedor</h4>
        <Row>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Ventas Acumulado {anoActual}</th>
                  </tr>
                  <tr>
                    <th>Proveedor</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoAcumuladoProveedor.map((a) => (
                   <tr key={a.idProveedor}>
                     <td>{a.Proveedor}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        <div className="card container col-sm-5">
        <Table>
                 <thead>
                  <tr>
                    <th>Ventas Semana {semana}</th>
                  </tr>
                  <tr>
                    <th>Proveedor</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {infoSemanaProveedor.map((a) => (
                   <tr key={a.idProveedor}>
                     <td>{a.Proveedor}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>
        </Row>
<hr />
        <h4>Resumen de Cobranza</h4>
        <Row>
        <div className="card container col-sm-12">
        <Table>
                 <thead>
                 <tr>
                    <th>Antiguedad de Saldos</th>
                  </tr>
                  <tr>
                    <th>Total General</th>
                    <th>En Tiempo</th>
                    <th>Vencido 30</th>
                    <th>Vencido 60</th>
                    <th>Vencido 90</th>
                    <th>Vencido 120</th>
                    <th>Vencido 180</th>
                    <th>Mas de 180</th>
                  </tr>
                  </thead>
                 <tbody>
                 <tr>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral)}</td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalTreintaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalSesentaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalNoventaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2 }).format(totalMasGeneral)} </td>
              </tr>

              <tr>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSaldoGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalEnTiempoGeneral / totalSaldoGeneral)}</td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalTreintaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalSesentaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalNoventaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoVeinteGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalCientoOchentaGeneral / totalSaldoGeneral)} </td>
                <td className="negrita"> {new Intl.NumberFormat("en-US", {style: "percent", maximumFractionDigits: 2 }).format(totalMasGeneral / totalSaldoGeneral)} </td>
              </tr>
                 </tbody>
                 
                 </Table>
          
        </div>
        </Row>
        <Row>

        <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Cobranza Pendiente por Vendedor</th>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {cobranzaVendedores.map((a) => (
                   <tr key={a.idVendedor}>
                     <td>{a.Vendedor}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.Saldo)}</td>
                   </tr>
                 ))}
                 <tr className="negrita">
                  <td>Total</td>
                  <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(cobranzaVendedoresTotal)}</td>

                 </tr>
                 </tbody>
                 
                </Table>
          
        </div>


        <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Cobranza por Vendedor Semana {semana}</th>
                  </tr>
                  <tr>
                    <th>Vendedor</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {abonosVendedores.map((a) => (
                   <tr key={a.idVendedor}>
                     <td>{a.Vendedor}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.Importe)}</td>
                   </tr>
                 ))}
                 <tr className="negrita">
                  <td>Total</td>
                  <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(abonosVendedoresTotal)}</td>

                 </tr>
                 </tbody>
                 
                </Table>
          
        </div>
        </Row>
<hr />
        <h4>Resumen de Inventario</h4>
        <Row>
        <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Inventario</th>
                  </tr>
                  <tr>
                    <th>Almacen</th>
                    <th>Piezas</th>
                    <th>Importe</th>
                  </tr>
                 </thead>
                 <tbody>
                 {inventario.map((a) => (
                   <tr key={a.idArea}>
                     <td>{a.Area}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.TotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.TotalDinero)}</td>
                   </tr>
                 ))}

                    <tr className="negrita">
                     <td>Total</td>
                     <td>{new Intl.NumberFormat("en-US").format(inventarioTotalPiezas)}</td>
                     <td>{new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(inventarioTotalDinero)}</td>
                   </tr>
                 </tbody>
                 
                </Table>
          
        </div>

        <div className="card container col-sm-4">
          <Table>
                 <thead>
                 <tr>
                  <th>Pendiente Embarcar</th>
                  <th>Pendiente Recibir</th>
                 </tr>
                  </thead>
                 <tbody>
                 <tr>
                   <td>{new Intl.NumberFormat("en-US").format(pendEmbarcarTotal)}</td>
                   <td>{new Intl.NumberFormat("en-US").format(pendRecibirTotal)}</td>
                 </tr>
                 </tbody>
          </Table>
          </div>

        {/* <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Pendiente Embarcar</th>
                  </tr>
                  <tr>
                    <th>Articulo</th>
                    <th>Piezas</th>
                  </tr>
                 </thead>
                 <tbody>
                 {pendEmbarcar.map((a) => (
                   <tr >
                     <td>{a.codigo} {a.nombre}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.pendiente_embarcar)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div>

        <div className="card container col-sm-4">
        <Table>
                 <thead>
                  <tr>
                    <th>Pendiente Recibir</th>
                  </tr>
                  <tr>
                    <th>Articulo</th>
                    <th>Piezas</th>
                  </tr>
                 </thead>
                 <tbody>
                 {pendRecibir.map((a) => (
                   <tr >
                     <td>{a.codigo}</td>
                     <td>{new Intl.NumberFormat("en-US").format(a.pendiente_recibir)}</td>
                   </tr>
                 ))}
                 </tbody>
                 
                </Table>
          
        </div> */}
        </Row>
   </div>
        </>
      ) : undefined}
      
    </>
  );
}

export default Locker;
