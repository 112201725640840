import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import { AuthContext } from "../../contexts/AuthContext";
import axios from "axios";
import Header from "../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Form,
  Card,
  CardHeader,
  CardText,
  CardBody,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import * as am5percent from "@amcharts/amcharts5/percent";

function DashboardGastos() {
  const { user } = useContext(AuthContext);
  const URL_DASHBOARDS = process.env.REACT_APP_URL_DASHBOARDS;
  const BANCO_EFECTIVO = process.env.REACT_APP_BANCO_EFECTIVO;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 90);



  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");


  const [info, setInfo] = useState([]);
  const [graficasCategorias, setGraficasCategorias] = useState([]);
  const [graficasSubCategorias, setGraficasSubCategorias] = useState([]);
  const [graficasMeses, setGraficasMeses] = useState([]);
  const [valorGastado, setValorGastado] = useState(0);
  const [taref, setTaref] = useState(0);

  const [categorias, setCategorias] = useState([]);
  const [subCategorias, setSubCategorias] = useState([]);
  const [solicitantes, setSolicitantes] = useState([]);
  const [meses, setMeses] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState("");
  const [selectedSubCategoria, setSelectedSubCategoria] = useState("");
  const [selectedSolicitante, setSelectedSolicitante] = useState("");
  const [selectedMes, setSelectedMes] = useState("");

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [cargando, setCargando] = useState(true);

  const Toast = Swal.mixin({
    toast: true,
    position: "center",
    showConfirmButton: false,
    // timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  if (cargando) {
    Toast.fire({
      icon: "success",
      title: "Danos unos segundos....",
    });
  }

useEffect(()=>{
  axios
  .get(`${URL_DASHBOARDS}Gastos/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;

      setInfo(allDisponible)

      let unicosCol = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idCategoria === value.idCategoria)
      );
      setCategorias(unicosCol);

      let unicosSubcat = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idSubCategoria === value.idSubCategoria)
      );
      setSubCategorias(unicosSubcat);
      
      let unicosSoli = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idSolicitante === value.idSolicitante)
      );
      setSolicitantes(unicosSoli);

      let unicosMes = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Mes === value.Mes)
      );
      setMeses(unicosMes);
    

      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });

},[])


function BuscaInfo(){
  setInfo([])
  setSelectedCategoria("")
  setSelectedSubCategoria("")
  setSelectedMes("")
  setCargando(true)
  axios
  .get(`${URL_DASHBOARDS}Gastos/${selectedFechaInicio}/${selectedFechaFin}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allDisponible = response.data;

      setInfo(allDisponible)

      let unicosCol = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idCategoria === value.idCategoria)
      );
      setCategorias(unicosCol);


      let unicosSubcat = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idSubCategoria === value.idSubCategoria)
      );
      setSubCategorias(unicosSubcat);
      
      let unicosSoli = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.idSolicitante === value.idSolicitante)
      );
      setSolicitantes(unicosSoli);

      let unicosMes = allDisponible.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.Mes === value.Mes)
      );
      setMeses(unicosMes);
      
      setCargando(false);
      Toast.close();
    })
    .catch((err) => {
      console.log(err);
    });
}

  useMemo(() => {
      let temp = info

          if (selectedMes) {
            temp = temp.filter((e) =>
              e.Mes.includes(selectedMes)
            );
          }
          if (selectedCategoria) {
            temp = temp.filter((e) =>
              e.idCategoria.includes(selectedCategoria)
            );
          }
          
          if (selectedSubCategoria) {
            temp = temp.filter((e) =>
              e.idSubCategoria.includes(selectedSubCategoria)
            );
          }
          
          if (selectedSolicitante) {
            temp = temp.filter((e) =>
              e.idSolicitante.includes(selectedSolicitante)
            );
          }
    

        let agrupadoCategoria = temp.reduce(function (groups, item) {
          const val = item["idCategoria"];
          groups[val] = groups[val] || {
            idCategoria: item.idCategoria,
            total: 0,
          };
          groups[val].total += item.total;
          groups[val].categoria = item.categoria;
          groups[val].idCategoria = item.idCategoria;
          return groups;
        }, []);

        let otraCategoria = Object.values(agrupadoCategoria).sort((a, b) =>
          a.total < b.total ? 1 : -1
        );

        setGraficasCategorias(otraCategoria);

 
        let agrupadoSubCategoria = temp.reduce(function (groups, item) {
          const val = item["idSubCategoria"];
          groups[val] = groups[val] || {
            idSubCategoria: item.idSubCategoria,
            total: 0,
          };
          groups[val].total += item.total;
          groups[val].subCategoria = item.subCategoria;
          groups[val].idSubCategoria = item.idSubCategoria;
          return groups;
        }, []);

        let otraSubCategoria = Object.values(agrupadoSubCategoria).sort((a, b) =>
          a.total < b.total ? 1 : -1
        );

        setGraficasSubCategorias(otraSubCategoria);


        let agrupadoMes = temp.reduce(function (groups, item) {
          const val = item["Mes"];
          groups[val] = groups[val] || {
            Mes: item.Mes,
            total: 0,
          };
          groups[val].total += item.total;
          groups[val].Mes = item.Mes;
          return groups;
        }, []);

        let otraMes = Object.values(agrupadoMes)
        // .sort((a, b) =>
        //   a.total < b.total ? 1 : -1
        // );

        setGraficasMeses(otraMes);


        let TVal = temp.map((c) => parseFloat(c.total));
        let TV = TVal.reduce((t, total, index) => t + total, 0);
        setValorGastado(TV);

        let tar = temp.filter((e) =>e.idBanco == BANCO_EFECTIVO);
        let TarVal = tar.map((c) => parseFloat(c.total));
        let TarV = TarVal.reduce((t, total, index) => t + total, 0);
        setTaref(TarV)


  }, [info, selectedMes, selectedCategoria, selectedSubCategoria, selectedSolicitante]);


  useLayoutEffect(() => {
    
    // Grafica Categorias
    let root = am5.Root.new("chartdiv");

    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        endAngle: 270,
        layout: root.verticalLayout,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{categoria} {value}",
        }),
      })
    );

    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "total",
        categoryField: "categoria",
        endAngle: 270,
      })
    );

    series.states.create("hidden", {
      endAngle: -90,
    });

    chart.children.unshift(
      am5.Label.new(root, {
        text: "Categorias",
        fontSize: 25,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      })
    );

    series.labels.template.set("forceHidden", true);
    series.ticks.template.setAll({
      visible: false,
      stroke: am5.color("rgb(252, 252, 252)"),
    });
    series.data.setAll(graficasCategorias);
    series.appear(1000, 100);

    let legend = chart.children.push(
      am5.Legend.new(root, {
        nameField: "categoria",
      })
    );

    legend.data.setAll(series.dataItems);

    
     // Grafica SubCategoria
     let rootSubCategoria = am5.Root.new("chartdivSubCategoria");

     rootSubCategoria.setThemes([am5themes_Animated.new(rootSubCategoria)]);
 
     let chartSubCategoria = rootSubCategoria.container.children.push(
       am5percent.PieChart.new(rootSubCategoria, {
         endAngle: 270,
         layout: rootSubCategoria.verticalLayout,
         tooltip: am5.Tooltip.new(rootSubCategoria, {
           labelText: "{subCategoria} {value}",
         }),
       })
     );
 
     let seriesSubCategoria = chartSubCategoria.series.push(
       am5percent.PieSeries.new(rootSubCategoria, {
         valueField: "total",
         categoryField: "subCategoria",
         endAngle: 270,
       })
     );
 
     seriesSubCategoria.states.create("hidden", {
       endAngle: -90,
     });
 
     chartSubCategoria.children.unshift(
       am5.Label.new(rootSubCategoria, {
         text: "Sub Categorias",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );
 
     seriesSubCategoria.labels.template.set("forceHidden", true);
     seriesSubCategoria.ticks.template.setAll({
       visible: false,
       stroke: am5.color("rgb(252, 252, 252)"),
     });
     seriesSubCategoria.data.setAll(graficasSubCategorias);
     seriesSubCategoria.appear(1000, 100);
 
     let legendSubCategoria = chartSubCategoria.children.push(
       am5.Legend.new(rootSubCategoria, {
         nameField: "subCategoria",
       })
     );
 
    //  legendSubCategoria.data.setAll(seriesSubCategoria.dataItems);

     // Venta Mensual

     let rootMes = am5.Root.new("chartdivMes");

     rootMes.setThemes([am5themes_Animated.new(rootMes)]);
 
     let chartMes = rootMes.container.children.push(
       am5xy.XYChart.new(rootMes, {
         panY: false,
         layout: rootMes.verticalLayout,
       })
     );
 
     let dataMes = graficasMeses;
 
     // Create Y-axis
     let yAxisMes = chartMes.yAxes.push(
       am5xy.ValueAxis.new(rootMes, {
        min:0,
         renderer: am5xy.AxisRendererY.new(rootMes, {}),
       })
     );
 
     // Create X-Axis
     let xAxisMes = chartMes.xAxes.push(
       am5xy.CategoryAxis.new(rootMes, {
         renderer: am5xy.AxisRendererX.new(rootMes, {}),
         categoryField: "Mes",
       })
     );
     xAxisMes.data.setAll(dataMes);
 
     // Create series
     let seriesMes = chartMes.series.push(
       am5xy.ColumnSeries.new(rootMes, {
         name: "Mes",
         xAxis: xAxisMes,
         yAxis: yAxisMes,
         valueYField: "total",
         categoryXField: "Mes",
         tooltip: am5.Tooltip.new(rootMes, {
           labelText: "{Mes} ${valueY}",
         }),
       })
     );
     seriesMes.data.setAll(dataMes);
 
     // Add cursor
     chartMes.set("cursor", am5xy.XYCursor.new(rootMes, {}));
 
     let exportingMes = am5plugins_exporting.Exporting.new(rootMes, {
       menu: am5plugins_exporting.ExportingMenu.new(rootMes, {}),
     });
 
     chartMes.children.unshift(
       am5.Label.new(rootMes, {
         text: "Mensual",
         fontSize: 25,
         fontWeight: "500",
         textAlign: "center",
         x: am5.percent(50),
         centerX: am5.percent(50),
         paddingTop: 0,
         paddingBottom: 0,
       })
     );


    return () => {
      root.dispose();
      rootMes.dispose();
      rootSubCategoria.dispose();
    };
  }, [graficasCategorias, graficasMeses, graficasSubCategorias]);

  return (
    <>
      <Header />
      <br />
      <br />
      {/* {user.menu_total ? ( */}
      <div className="card container col-12">
        <Row>
          <Col md={8}>
            <Button
              size="sm"
              href="/Dashboards"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <h3 align="center">Dashboard Gastos</h3>
        <Row>
        <Col md={2}>
            <Label className="mr-sm-2">Fecha Inicio</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaInicio}
              required
              onChange={(e) => {
                setSelectedFechaInicio(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <Label className="mr-sm-2">Fecha Fin</Label>
            <Input
              bsSize="sm"
              type="date"
              value={selectedFechaFin}
              required
              onChange={(e) => {
                setSelectedFechaFin(e.target.value)
              }}
            />
          </Col>
          <Col md={2}>
            <h4></h4>
            <Button
              size="sm"
              className="btn"
              id="botonListado"
              onClick={BuscaInfo}
              color="info"
            >
              Actualizar
            </Button>
          </Col>
        </Row>
        <br />
          <Row>
          

          <Col md={2}>
              <Label className="mr-sm-2">Meses</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedMes}
                required
                onChange={(e) => {
                  setSelectedMes(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {meses
                  // .sort((a, b) => (a.Mes > b.Mes ? 1 : -1))
                  .map((a) => {
                    return <option value={a.Mes}>{a.Mes}</option>;
                  })}
              </Input>
            </Col>

                <Col md={2}>
              <Label className="mr-sm-2">Categorias</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedCategoria}
                required
                onChange={(e) => {
                  setSelectedCategoria(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {categorias
                  .sort((a, b) => (a.categoria > b.categoria ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idCategoria}>{a.categoria}</option>;
                  })}
              </Input>
            </Col>
              <Col md={2}>
              <Label className="mr-sm-2">Sub Categorias</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedSubCategoria}
                required
                onChange={(e) => {
                  setSelectedSubCategoria(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {subCategorias
                  .sort((a, b) => (a.subCategoria > b.subCategoria ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idSubCategoria}>{a.subCategoria}</option>;
                  })}
              </Input>
            </Col>
              <Col md={2}>
              <Label className="mr-sm-2">Solicitante</Label>
              <Input
                
                bsSize="sm"
                type="select"
                value={selectedSolicitante}
                required
                onChange={(e) => {
                  setSelectedSolicitante(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                {solicitantes
                  .sort((a, b) => (a.solicitante > b.solicitante ? 1 : -1))
                  .map((a) => {
                    return <option value={a.idSolicitante}>{a.solicitante}</option>;
                  })}
              </Input>
            </Col>
        </Row> 

        <div
        className="container"
        style={{ display: "flex", justifyContent: "center" }}
        a
      >

        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              GASTO TOTAL
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(valorGastado)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <CardHeader tag="h3" align="center">
              EFECTIVO
            </CardHeader>
            <CardBody>
              <CardText tag="h4" align="center">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(taref)}
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </div>

      
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div id="chartdiv" style={{ width: "40%", height: "500px" }}></div>
        
         <div
            id="chartdivSubCategoria"
            style={{ width: "40%", height: "500px" }}
          ></div>

        </div>

        <br />
        <br />
        <div
          // className="container"
          style={{ display: "flex", justifyContent: "center" }}
        >

          <div
            id="chartdivMes"
            style={{ width: "90%", height: "500px" }}
          ></div>
        </div>


      </div>


      {/* ) : undefined}  */}
    </>
  );
}
export default DashboardGastos;
