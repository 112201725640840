import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesCatalogos() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_admin ?(
      <div className="container">
        <br />
        <br />
        <Row >

          <Col md={4} align="center">
            {user.menu_departamentos ?(
            <Button href="/Departamentos" className="botonesMenu" color="success">
            <i class="fas fa-clipboard-list fa-7x"></i>
              <br />
              <br />
              Departamentos
            </Button>
            ):<Button disabled className="botonesMenu" color="success">
            <i class="fas fa-clipboard-list fa-7x"></i>
              <br />
              <br />
              Departamentos
            </Button> }
          </Col>
        </Row>
        <br />
        <Row>
       
        </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesCatalogos;
